import { TextField } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Autocomplete as MUIAutocomplete } from '@material-ui/lab';
import * as _ from 'lodash';
import React, { useEffect, useState } from 'react';

import useAPI from '../../hooks/useAPI';

const Autocomplete = ({
  axios, field, form, fullWidth, label, renderOption, required, style, ...props
}) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const loading = open && options.length === 0;

  const api = useAPI(axios(), false);

  useEffect(() => {
    if (api.response && !api.loading) setOptions(api.response.data);
  }, [api.response, api.loading]);

  useEffect(() => {
    if (loading) api.fetch();
  }, [loading]);

  const splitName = field.name ? field.name.split('.') : [];

  const getter = (object) => {
    let result = object;
    for (const property of splitName) {
      if (_.isEmpty(result)) return null;
      result = result[property];
    }
    return result;
  };

  return (
    <MUIAutocomplete
      {...props}
      {...field}
      disableClearable
      getOptionLabel={(option) => option.name}
      onBlur={() => form.setTouched({ [field.name]: true })}
      onChange={(event, value) => form.setFieldValue(field.name, value)}
      onClose={() => {
        setOpen(false);
      }}
      onOpen={() => {
        setOpen(true);
      }}
      open={open}
      options={options}
      renderInput={(inputProps) => (
        <TextField
          {...inputProps}
          InputProps={{
            ...inputProps.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20}/> : null}
                {inputProps.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
          error={field.name
            ? getter(form.touched)
              ? !!getter(form.errors)
              : null
            : null}
          fullWidth={fullWidth}
          helperText={field.name
            ? getter(form.touched)
              ? getter(form.errors)
              : props.helperText
            : props.helperText
          }
          label={required ? `${label}*` : label}
          required={false}
          style={!fullWidth ? { width: '12rem', ...style } : style}
        />
      )}
      renderOption={renderOption}
    />
  );
};

export default Autocomplete;
