import {
  CardBody, Checkbox, Col, Grid, Radio, RadioGroup, TextField,
} from 'capitalroadkit';
import { Field } from 'formik';
import React from 'react';
import * as Yup from 'yup';

import { withNameSpace } from '../../../../functions';
import FixedInterestNoteForm, { FixedInterestNoteFormInitialValues } from '../../FixedInterestNoteForm';

export const Type = Object.freeze({
  Rollover: 'ROLLOVER',
  Redeem: 'REDEEM',
  'Partial Rollover': 'PARTIAL',
});

const fields = {
  TYPE: 'type',
  ROLLOVER_OF_PRINCIPAL: 'rolloverOfPrincipal',
  ROLLOVER_FULL_PRINCIPAL: 'rolloverFullPrincipal',
  PRINCIPAL_AMOUNT_TO_ROLLOVER: 'principalAmountToRollover',
  ROLLOVER_OF_INTEREST: 'rolloverOfInterest',
  NOTE_DEFINITION: 'noteDefinition',
};

const initialValues = {
  [fields.TYPE]: Type.Rollover,
  [fields.ROLLOVER_OF_PRINCIPAL]: false,
  [fields.ROLLOVER_FULL_PRINCIPAL]: true,
  [fields.PRINCIPAL_AMOUNT_TO_ROLLOVER]: 0,
  [fields.ROLLOVER_OF_INTEREST]: false,
  [fields.NOTE_DEFINITION]: FixedInterestNoteFormInitialValues,
};

const schema = Yup.object({
});

const MaturityInstructionForm = ({ form, nameSpace }) => (
  <>
    <Col sm={12}>
      <Field component={RadioGroup} name={withNameSpace(nameSpace, fields.TYPE)} row>
        {Object.entries(Type)
          .map((type) => (
            <Radio
              key={type[1]}
              value={type[1]}
            >
              {type[0]}
            </Radio>
          ))
        }
      </Field>
    </Col>
    {form.values[fields.TYPE] === Type['Partial Rollover']
    && <>
      <Col sm={12}>
        <Field
          component={Checkbox}
          label={'Rollover Principal'}
          name={withNameSpace(nameSpace, fields.ROLLOVER_OF_PRINCIPAL)}
        />
      </Col>
      {form.values[fields.ROLLOVER_OF_PRINCIPAL]
        && <>
          <Col sm={12}>
            <Field
              component={Checkbox}
              label={'Rollover Full Principal'}
              name={withNameSpace(nameSpace, fields.ROLLOVER_FULL_PRINCIPAL)}
            />
          </Col>
          {!form.values[fields.ROLLOVER_FULL_PRINCIPAL]
          && <Col sm={12}>
            <Field
              component={TextField}
              label={'Principal Amount to Rollover'}
              name={withNameSpace(nameSpace, fields.PRINCIPAL_AMOUNT_TO_ROLLOVER)}
              style={{ width: '15rem' }}
            />
          </Col>
          }
        </>
      }
      <Col sm={12}>
        <Field
          component={Checkbox}
          label={'Rollover Interest'}
          name={withNameSpace(nameSpace, fields.ROLLOVER_OF_INTEREST)}
        />
      </Col>
    </>
    }
    {form.values[fields.TYPE] !== Type.Redeem
    && <Col sm={12}>
      <CardBody nested>
        <Grid nested>
          <Col sm={12}>
            <h6>New Note Term</h6>
          </Col>
          <FixedInterestNoteForm
            form={form}
            hidden={['principalAtInception']}
            nameSpace={fields.NOTE_DEFINITION}
            readOnly={['principalAtInception']}
          />
        </Grid>
      </CardBody>
    </Col>
    }
  </>
);

export default MaturityInstructionForm;
export {
  initialValues as MaturityInstructionFormInitialValues,
  schema as MaturityInstructionFormSchema,
};
