import {
  Button, CardBody, CardTitle, Col, Grid, TextField, Card, useTheme,
} from 'capitalroadkit';
import {
  Field, Form, FormikProvider, useFormik,
} from 'formik';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';

import { updateProductRateTableAxios } from '../../../api/ProductRateTableResource';
import { createProductRateTableAxios } from '../../../api/ProductResource';
import { now, toAPIDate, toDateString } from '../../../formatters';
import useAPI from '../../../hooks/useAPI';
import RateTable from '../NewProduct/RateTable';
import { RateFormFields, RateFormSchema } from '../RateForm';

const CreateRates = ({
  product, selected, updateRateTable,
}) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const RateFormInitialValues = {
    [RateFormFields.EFFECTIVE_DATE]: toDateString(selected?.effectiveDate) || toDateString(now()),
    [RateFormFields.DURATION]: '',
    [RateFormFields.INTEREST_RATE]: '',
  };

  useEffect(() => {
    if (selected?.effectiveDate && selected.effectiveDate !== rateForm.values.effectiveDate) {
      rateForm.values.effectiveDate = toDateString(selected.effectiveDate);
    }
    if (selected && !selected.effectiveDate) {
      rateForm.values.effectiveDate = toDateString(now());
    }
  }, [selected]);

  // PRODUCT RATES
  const [productRates, setProductRates] = useState(selected?.rates.length > 0 ? selected.rates : []);

  useEffect(() => {
    if (selected?.uuid === '1') {
      setProductRates([]);
      rateForm.values.effectiveDate = '';
    } else setProductRates(selected.rates);
  }, [selected]);

  // ERROR VIOLATIONS
  const [errorViolations, setErrorViolations] = useState(null);

  // CREATE PRODUCT RATE TABLE
  const createProductRateTableAPI = useAPI(createProductRateTableAxios(product?.uuid), false);

  // UPDATE PRODUCT RATE TABLE (can only update before effective date)
  const updateProductRateTableAPI = useAPI(updateProductRateTableAxios(selected?.uuid), false);

  const rateForm = useFormik({
    initialValues: RateFormInitialValues,
    onSubmit: (values) => {
      const rateTable = {
        rates: productRates,
        effectiveDate: toAPIDate(values.effectiveDate),
      };
      createProductRateTableAPI.fetch({}, rateTable);
    },
    validationSchema: RateFormSchema,
  });

  const updateSelectedRateTable = () => {
    const rateTable = {
      uuid: selected.uuid,
      rates: productRates,
      effectiveDate: toAPIDate(rateForm.values.effectiveDate),
    };
    setErrorViolations(null);
    updateProductRateTableAPI.fetch({}, rateTable);
  };

  useEffect(() => {
    if (updateProductRateTableAPI.response) {
      setErrorViolations(null);
      enqueueSnackbar('Rate table updated');
      updateRateTable(updateProductRateTableAPI.response.data);
    } else if (createProductRateTableAPI.response) {
      setErrorViolations(null);
      enqueueSnackbar('New rate table created');
      updateRateTable(createProductRateTableAPI.response.data);
    }
  }, [updateProductRateTableAPI.response, createProductRateTableAPI.response]);

  useEffect(() => {
    if (updateProductRateTableAPI.error) {
      if (updateProductRateTableAPI.error.response.data.errorViolations?.length !== 0) {
        setErrorViolations(updateProductRateTableAPI.error.response.data.errorViolations);
      } else {
        setErrorViolations([{
          name: updateProductRateTableAPI.error.response.data.errorMessage,
          value: updateProductRateTableAPI.error.response.data.errorDescription,
        }]);
      }
    } else if (createProductRateTableAPI.error) {
      if (createProductRateTableAPI.error.response.data.errorViolations?.length !== 0) {
        setErrorViolations(createProductRateTableAPI.error.response.data.errorViolations);
      } else {
        setErrorViolations([{
          name: createProductRateTableAPI.error.response.data.errorMessage,
          value: createProductRateTableAPI.error.response.data.errorDescription,
        }]);
      }
    }
  }, [updateProductRateTableAPI.error, createProductRateTableAPI.error]);

  const validateRate = (rate) => {
    let valid = true;
    if (isNaN(rate.duration) || rate.duration <= 0 || rate.duration % 1 !== 0) valid = false;
    if (isNaN(rate.interestRate) || rate.interestRate <= 0) valid = false;
    return valid;
  };

  const addRate = () => {
    const rates = [...productRates];
    let existing = false;
    let i = 0;
    const rate = { duration: rateForm.values.duration, interestRate: rateForm.values.interestRate };
    if (!validateRate(rate)) return;
    // Update existing duration rate
    for (const existingRate of rates) {
      if (existingRate.duration === rateForm.values.duration) {
        existing = true;
        rates.splice(i, 1, rate);
      }
      i += 1;
    }
    // New rate duration
    if (!existing) {
      rates.push(rate);
    }
    setProductRates(rates);
  };

  const removeRate = (row) => {
    const rates = [...productRates];
    rates.splice(row.index, 1);
    setProductRates(rates);
  };

  return (
    <FormikProvider value={rateForm}>
      <Form>
        <Grid nested>
          <Col lg={4} sm={12}>
            <CardBody nested style={{ height: '100%' }}>
              <Grid nested>
                <Col>
                  <CardTitle>Rate table</CardTitle>
                </Col>
                <Col sm={12}>
                  <Field component={TextField} fullWidth label={'Effective date'} mask={'99/99/9999'} name={RateFormFields.EFFECTIVE_DATE}
                         readOnly={selected?.uuid && selected.uuid === product.currentRateTable?.uuid}/>
                </Col>
              </Grid>
            </CardBody>
          </Col>
          <Col lg={8} sm={12}>
            <CardBody nested style={{ height: '100%' }}>
              <Grid nested>
                <Col>
                  <CardTitle>Add rate</CardTitle>
                </Col>
                <Col lg={5} sm={12}>
                  <Field component={TextField} fullWidth label={'Duration (months)'} name={RateFormFields.DURATION}
                         readOnly={selected?.uuid && selected.uuid === product.currentRateTable?.uuid}/>
                </Col>
                <Col lg={4} sm={12}>
                  <Field component={TextField} fullWidth label={'Interest rate (%)'} name={RateFormFields.INTEREST_RATE}
                         readOnly={selected?.uuid && selected.uuid === product.currentRateTable?.uuid}/>
                </Col>
                <Col lg={3} sm={12}>
                  <Button
                    color={'primary'}
                    disabled={selected?.uuid && selected.uuid === product.currentRateTable?.uuid} name={'add-rate'}
                    onClick={() => addRate()}>
                    Add rate
                  </Button>
                </Col>
              </Grid>
            </CardBody>
          </Col>
          <Col sm={12}>
            <Card>
              <RateTable data={productRates}
                remove={selected?.uuid && selected.uuid !== product.currentRateTable?.uuid
                  ? removeRate
                  : null}/>
            </Card>
          </Col>
          {errorViolations && errorViolations.map((error) => (
            <Col style={{ textAlign: 'right' }}>
              <p style={{ color: theme.palette.danger.main, marginTop: '.5rem' }}>
                {error.name}: {error.value}
              </p>
            </Col>
          ))}
          <Col sm={12} style={{ textAlign: 'right' }}>
            {selected?.uuid && selected.uuid !== '1'
              ? <Button
                color={'success'}
                loading={createProductRateTableAPI.loading || updateProductRateTableAPI.loading}
                name={'update-rate-table'}
                onClick={() => updateSelectedRateTable()}
              >
                Save
              </Button>
              : <Button
                color={'primary'}
                loading={createProductRateTableAPI.loading || updateProductRateTableAPI.loading}
                name={'submit-rate-table'}
                style={{ marginLeft: '.5rem' }}
                type={'submit'}
              >
                Create
              </Button>
            }
          </Col>
        </Grid>
      </Form>
    </FormikProvider>
  );
};

export default CreateRates;
