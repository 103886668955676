import {
  Button,
  Card, CardBody, CardHeader, CardTitle, Col, Grid, Page, PageHeader,
} from 'capitalroadkit';
import { Form, FormikProvider, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';

import MaturityInstructionForm, { MaturityInstructionFormInitialValues, Type } from './MaturityInstructionForm';

import { getNoteByUuidAxios, updateMaturityInstructionAxios, deleteMaturityInstructionAxios } from '../../../../api/NoteResource';
import { getPartyNotesAxios } from '../../../../api/PartyResource';
import Error from '../../../../components/Error/Error';
import Loading from '../../../../components/Loading/Loading';
import useAPI from '../../../../hooks/useAPI';
import NotesTable from '../../NotesTable';
import NoteOverview from '../NoteOverview';

const MaturityInstruction = ({ user }) => {
  const history = useHistory();
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    getNoteAPI.fetch();
  }, [params.uuid]);

  const getNoteAPI = useAPI(getNoteByUuidAxios(params.uuid), false);

  useEffect(() => {
    if (getNoteAPI.response && !getNoteAPI.loading) {
      getPartyNotesAPI.fetch();
    }
  }, [getNoteAPI.response, getNoteAPI.loading]);

  const getPartyNotesAPI = useAPI(getPartyNotesAxios(getNoteAPI.response?.data.party.uuid), false);

  const updateMaturityInstructionAPI = useAPI(
    updateMaturityInstructionAxios(getNoteAPI.response?.data.uuid),
    false,
  );

  const deleteMaturityInstructionAPI = useAPI(
    deleteMaturityInstructionAxios(params.uuid),
    false,
  );


  useEffect(() => {
    if ((updateMaturityInstructionAPI.response && !updateMaturityInstructionAPI.loading)
        || (deleteMaturityInstructionAPI.response && !deleteMaturityInstructionAPI.loading)) {
      enqueueSnackbar('Maturity instruction updated');
      history.push(`/secure/notes/${getNoteAPI.response.data.uuid}`);
    }
  }, [updateMaturityInstructionAPI.response, updateMaturityInstructionAPI.loading,
    deleteMaturityInstructionAPI.response, deleteMaturityInstructionAPI.loading]);

  const maturityInstructionForm = useFormik({
    initialValues: MaturityInstructionFormInitialValues,
    onSubmit: (values) => {
      const apiValues = {};

      switch (values.type) {
        case Type.Rollover:
          apiValues.rolloverOfPrincipal = true;
          apiValues.rolloverOfInterest = true;
          apiValues.principalAmountToRollover = null;
          apiValues.noteDefinition = {};
          apiValues.noteDefinition.product = { uuid: values.noteDefinition.product };
          apiValues.noteDefinition.duration = values.noteDefinition.duration;
          apiValues.noteDefinition.interestRate = values.noteDefinition.interestRate;
          break;

        case Type.Redeem:
          apiValues.rolloverOfPrincipal = false;
          apiValues.rolloverOfInterest = false;
          break;

        case Type['Partial Rollover']:
          apiValues.rolloverOfPrincipal = values.rolloverOfPrincipal;
          apiValues.rolloverOfInterest = values.rolloverOfInterest;
          if (values.rolloverFullPrincipal) {
            apiValues.principalAmountToRollover = null;
          } else apiValues.principalAmountToRollover = { value: values.principalAmountToRollover, currency: 'AUD' };
          apiValues.noteDefinition = {};
          apiValues.noteDefinition.product = { uuid: values.noteDefinition.product };
          apiValues.noteDefinition.duration = values.noteDefinition.duration;
          apiValues.noteDefinition.interestRate = values.noteDefinition.interestRate;
          break;

        default:
          break;
      }

      updateMaturityInstructionAPI.fetch({}, apiValues);
    },
  });

  if (getNoteAPI.loading) return <Loading size={'3x'}/>;
  if (getNoteAPI.response) {
    return (
      <Page id={'maturity-instruction'}>
        <Grid>
          <PageHeader
            heading={getNoteAPI.response.data.noteReferenceId}
            subHeading={'Maturity Instruction'}
          >
            {getNoteAPI.response?.data.status === 'ACTIVE'
            && <Col sm={12} style={{ textAlign: 'right' }}>
              <Link to={{ pathname: `/secure/notes/${getNoteAPI.response.data.uuid}` }}>
                <Button color={'primary'}>Details</Button>
              </Link>
              <Link style={{ marginLeft: '.5rem' }} to={{ pathname: `/secure/notes/${getNoteAPI.response.data.uuid}/payments` }}>
                <Button color={'primary'}>Payments</Button>
              </Link>
              <Link style={{ marginLeft: '.5rem' }} to={{ pathname: `/secure/notes/${getNoteAPI.response.data.uuid}/instructions`, query: { type: Type.Rollover } }}>
                <Button color={'primary'}>Rollover</Button>
              </Link>
              <Link style={{ marginLeft: '.5rem' }} to={{ pathname: `/secure/notes/${getNoteAPI.response.data.uuid}/instructions`, query: { type: Type.Redeem } }}>
                <Button color={'primary'}>Redeem</Button>
              </Link>
            </Col>
            }
          </PageHeader>
          {getPartyNotesAPI.response
          && <Col sm={12}>
            <Card>
              <NotesTable
                data={getPartyNotesAPI.response.data.filter((note) => note.status === 'ACTIVE')}
                party
                rowClick={(row) => history.push(`/secure/notes/${row.values.uuid}/maturity-instruction`)}
                selected={getNoteAPI.response.data.uuid}
              />
            </Card>
          </Col>
          }
          <Col lg={8} md={12} sm={12}>
            <Card>
              <CardHeader><CardTitle>Maturity Instruction</CardTitle></CardHeader>
              <CardBody>
                <FormikProvider value={maturityInstructionForm}>
                  <Form>
                    <Grid nested>
                      <MaturityInstructionForm form={maturityInstructionForm}/>
                      <Col sm={12} style={{ textAlign: 'right' }}>
                        <Button color={'primary'}
                            loading={deleteMaturityInstructionAPI.loading}
                            style={{ marginRight: '.5rem' }} o
                            onClick={() => deleteMaturityInstructionAPI.fetch()}
                        >
                          Set to default instruction
                        </Button>
                        {deleteMaturityInstructionAPI.error
                        && <Error error={deleteMaturityInstructionAPI.error.response.data}/>
                        }
                        <Button
                          color={'primary'}
                          loading={updateMaturityInstructionAPI.loading}
                          type={'submit'}
                        >
                          Update
                        </Button>
                        {updateMaturityInstructionAPI.error
                        && <Error error={updateMaturityInstructionAPI.error.response.data}/>
                        }
                      </Col>
                    </Grid>
                  </Form>
                </FormikProvider>
              </CardBody>
            </Card>
          </Col>
          <Col lg={4} md={12} sm={12}>
            <Card>
              <CardHeader><CardTitle>Note</CardTitle></CardHeader>
              <CardBody>
                <NoteOverview getNote={getNoteAPI.fetch} note={getNoteAPI.response.data} small/>
              </CardBody>
            </Card>
          </Col>
        </Grid>
      </Page>
    );
  }
  return null;
};

export default MaturityInstruction;
