import {Col} from 'capitalroadkit';
import React from 'react';
import {parseEnum} from "../../formatters";

export const PartySummary = ({ party, role }) => (
  party
    ? <span>
    {(party.name || party.firstName) && <Col sm={12} style={{ fontWeight: 'bold' }}>
      {party.type !== 'INDIVIDUAL' ? <p>{party.name}</p> : <p>{`${party.firstName} ${party.middleName} ${party.lastName}`}</p>}
    </Col>}
    {party.dateOfBirth && <Col sm={12}>
      <p><small>Date of birth: {party.dateOfBirth}</small></p>
      <p><small>Gender: {party.gender}</small></p>
    </Col>}
    {party.contactDetails && <Col sm={12}>
      <p>{role === 'ACCOUNT' ? 'Account ' : ''}Contact Details</p>
      <p><small>Email: {party.contactDetails.emailAddress.address}</small></p>
      <p><small>Mobile: +{party.contactDetails.mobile.countryCode} {party.contactDetails.mobile.number}</small></p>
      <p><small>Address: {`${party.contactDetails.address.line1},
         ${party.contactDetails.address.city},
         ${party.contactDetails.address.country},
         ${party.contactDetails.address.state},
         ${party.contactDetails.address.postcode}`}</small></p>
    </Col>}
    {party.bankAccount && <Col sm={12}>
        <p>{role === 'ACCOUNT' ? 'Account ' : ''}Bank Details</p>
        <p><small>Account Name: {party.bankAccount.accountName}</small></p>
        <p><small>BSB: {party.bankAccount.bsbNumber}</small></p>
        <p><small>Account No.: {party.bankAccount.accountNumber}</small></p>
    </Col>}
      {(party.taxInformationDetails && party.taxInformationDetails?.australianTaxResident) && <Col sm={12}>
        <p>Tax Details</p>
        {party.taxInformationDetails.australianTaxInformation.taxFileNumber && <p><small>TFN: {party.taxInformationDetails.australianTaxInformation.taxFileNumber}</small></p>}
        {party.taxInformationDetails.australianTaxInformation.tfnExemptionCode && <p><small>Exemption: {party.taxInformationDetails.australianTaxInformation.tfnExemptionCode}</small></p>}
      </Col>}
      {(party.identityDocuments) && <Col sm={12}>
        <p>Identity documents captured</p>
        {party.identityDocuments[0] && party.identityDocuments.map((id) => <p><small>{parseEnum(id.type)}</small></p>)}
      </Col>}
  </span>
    : <></>
);
