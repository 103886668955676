import {
  Card, CardBody, Col, Grid, Page, PageHeader, Button,
} from 'capitalroadkit';
import { Form, FormikProvider, useFormik } from 'formik';
import React from 'react';

import PartySearchForm, { PartySearchFormInitialValues, PartySearchFormSchema } from './PartySearchForm';
import PartyTable from './PartyTable';

import { searchPartiesAxios } from '../../api/PartyResource';
import Loading from '../../components/Loading/Loading';
import useAPI from '../../hooks/useAPI';

const Clients = ({ user }) => {
  const partySearchForm = useFormik({
    initialValues: PartySearchFormInitialValues,
    onSubmit: (values) => partySearchAPI.fetch({ qs: values.filter ? values.filter : '', offset: 0, limit: 10 }),
    validationSchema: PartySearchFormSchema,
  });

  const partySearchAPI = useAPI(searchPartiesAxios(), true, { qs: '', offset: 0, limit: 10 });

  return (
    <Page id={'clients'}>
      <Grid>
        <PageHeader
          heading={'Clients'}
          subHeading={user.name}
        />
        <Col sm={12}>
          <Card>
            <CardBody>
              <FormikProvider value={partySearchForm}>
                <Form>
                  <Grid nested>
                    <PartySearchForm/>
                    <Col sm={4} style={{ alignItems: 'end', display: 'flex' }}>
                      <Button color={'primary'} type={'submit'}>Search</Button>
                    </Col>
                  </Grid>
                </Form>
              </FormikProvider>
            </CardBody>
          </Card>
        </Col>
        {partySearchAPI.loading && <Loading size={'3x'}/>}
        {partySearchAPI.response
        && <Col sm={12}>
          <Card>
            <PartyTable
              api={{
                limit: partySearchAPI.response.data.limit,
                offset: partySearchAPI.response.data.offset,
                totalNumberOfRecords: partySearchAPI.response.data.totalNumberOfRecords,
                fetch: partySearchAPI.fetch,
                params: partySearchAPI.lastParams,
              }}
              data={partySearchAPI.response.data.list}
              />
          </Card>
        </Col>}
      </Grid>
    </Page>
  );
};

export default Clients;
