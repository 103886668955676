import { useCallback, useEffect } from 'react';

import useAPI from './useAPI';
import useSessionStorage from './useSessionStorage';

const sha256 = require('crypto-js/sha256');

const useAPIWithSessionStorage = (api, immediate = true, initialParams, refreshData) => {
  const apiCall = useAPI(api, false, initialParams);

  const [sessionResponse, setSessionResponse] = useSessionStorage(sha256(api.toString()).toString(), null);

  const fetch = useCallback((params = {}, data = null, headers = {}) => {
    if (!sessionResponse && !refreshData) {
      apiCall.fetch(params, data, headers);
    }
  }, [api]);

  useEffect(() => {
    if (!apiCall.loading && apiCall.response) setSessionResponse(apiCall.response);
  }, [apiCall.loading, apiCall.response]);

  useEffect(() => {
    if (immediate) fetch(initialParams);
  }, [immediate]);

  return {
    ...apiCall, response: sessionResponse,
  };
};

export default useAPIWithSessionStorage;
