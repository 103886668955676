import { Button, useTheme } from 'capitalroadkit';
import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { logoutAxios } from '../../api/AuthResource';
import useAPI from '../../hooks/useAPI';
import useCookies from '../../hooks/useCookies';

const NetworkError = () => {
  const history = useHistory();
  const theme = useTheme();

  const [, setAuthToken] = useCookies('authToken', null);

  const logoutAPI = useAPI(logoutAxios(), false);

  useEffect(() => {
    if (!logoutAPI.loading && logoutAPI.response) {
      setAuthToken(null);
      history.push('/login');
    }
  }, [logoutAPI.loading, logoutAPI.response]);

  return (
    <>
      <h1
        style={{
          color: theme.palette.primary.main,
          textShadow: 'rgba(251, 164, 0, .3) 5px 1px, rgba(251, 164, 0, .2) 10px 3px',
          fontSize: '5.25rem',
        }}
      >
        Error
      </h1>
      <h4
        style={{
          color: theme.palette.danger.main,
          fontSize: '1.125rem',
          fontWeight: '700',
          textTransform: 'uppercase',
        }}
      >
        Connection problem
      </h4>
      <p style={{ color: theme.palette.text.muted }}>
        There was a problem contacting our service.
        Please check your internet connection and try again.
      </p>
      <div style={{ marginTop: '1.5rem' }}>
        <Link to={'/login'}>
          <Button color={'primary'}>Login</Button>
        </Link>
      </div>
    </>
  );
};

export default NetworkError;
