import { Col, TextField } from 'capitalroadkit';
import { FastField } from 'formik';
import React from 'react';
import * as Yup from 'yup';

import { withNameSpace } from '../../../functions';
import AddressForm, { AddressFormInitialValues, AddressFormSchema } from '../../NewParty/SubForm/AddressForm';
import PhoneForm, { PhoneFormInitialValues, PhoneFormSchema } from '../../NewParty/SubForm/PhoneForm';

const fields = {
  EMAIL_ADDRESS: 'emailAddress',
  WEB_ADDRESS: 'webAddress',
  MOBILE: 'mobile',
  LANDLINE: 'landLine',
  ADDRESS: 'address',
};

const initialValues = (contactDetails) => ({
  [fields.EMAIL_ADDRESS]: {
    address: contactDetails?.emailAddress.address || '',
  },
  [fields.WEB_ADDRESS]: {
    url: contactDetails?.webAddress.url || '',
  },
  [fields.MOBILE]: PhoneFormInitialValues(true, contactDetails?.mobile),
  [fields.LANDLINE]: PhoneFormInitialValues(false, contactDetails?.landLine),
  [fields.ADDRESS]: AddressFormInitialValues(contactDetails?.address),
});

const schema = Yup.object({
  [fields.EMAIL_ADDRESS]: Yup.object().shape({
    address: Yup.string()
      .email('Email must be a valid email address')
      .required('Email is required'),
  }),
  [fields.WEB_ADDRESS]: Yup.object().shape({
    address: Yup.string()
      .matches(/[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/gi, { message: 'Must be a valid url' }),
  }),
  [fields.MOBILE]: PhoneFormSchema(true),
  [fields.LANDLINE]: PhoneFormSchema(false),
  [fields.ADDRESS]: AddressFormSchema,
});

const ProductContactDetailsForm = ({ nameSpace, readOnly }) => (
  <>
    <Col lg={6} sm={12}>
      <FastField component={TextField} fullWidth key={readOnly}
                 label={'Email'} name={withNameSpace(nameSpace, 'emailAddress.address')} readOnly={readOnly} required/>
    </Col>
    <Col lg={6} sm={12}>
      <FastField component={TextField} fullWidth key={readOnly} label={'Website'}
                 name={withNameSpace(nameSpace, 'webAddress.url')} readOnly={readOnly}/>
    </Col>
    <Col lg={6} sm={12}>
      <PhoneForm nameSpace={withNameSpace(nameSpace, fields.MOBILE)} readOnly={readOnly} required
                 title={'Mobile'}/>
    </Col>
    <Col lg={6} sm={12}>
      <PhoneForm nameSpace={withNameSpace(nameSpace, fields.LANDLINE)} readOnly={readOnly}
                 title={'Landline'}/>
    </Col>
    <Col sm={12}>
      <h5>Address</h5>
    </Col>
    <Col sm={12}>
      <AddressForm nameSpace={withNameSpace(nameSpace, fields.ADDRESS)} readOnly={readOnly}/>
    </Col>
  </>
);

export default ProductContactDetailsForm;
export {
  initialValues as ProductContactDetailsFormInitialValues,
  schema as ProductContactDetailsFormSchema,
  fields as ProductContactDetailsFormFields,
};
