import { useTheme } from 'capitalroadkit';
import React from 'react';

import useStyles from './TableInput.styles';

const TableInput = ({ disabled, field, placeholder }) => {
  const theme = useTheme();
  const classes = useStyles({ disabled, theme });

  return (
    <input
      {...field}
      className={classes.tableInput}
      disabled={disabled}
      placeholder={placeholder}
    />
  );
};

export default TableInput;
