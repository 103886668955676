import {
  Card, CardActions, CardHeader, CardTitle, Col, Grid, Page, Button, PageHeader,
} from 'capitalroadkit';
import React from 'react';
import { useHistory } from 'react-router';

import ProductsTable from './ProductsTable';

import { listProductsAxios } from '../../api/ProductResource';
import useAPI from '../../hooks/useAPI';

const Products = ({ user }) => {
  const history = useHistory();
  const listProductsAPI = useAPI(listProductsAxios());

  const isProductManager = () => {
    for (const role of user.businessRoles) {
      if (role === 'PRODUCT_MANAGER') {
        return true;
      }
    }
    return false;
  };

  return (
    <Page id={'products'}>
      <Grid>
        <PageHeader
          heading={'Products'}
          subHeading={user.name}
        />
        {listProductsAPI.response
        && <Col sm={12}>
          <Card>
            <CardHeader>
              <CardTitle>Products</CardTitle>
              {isProductManager()
              && <CardActions>
                <Button color={'primary'} onClick={() => history.push('/secure/products/new')} variant={'text'}>
                  New product
                </Button>
              </CardActions>}
            </CardHeader>
            <ProductsTable
              data={listProductsAPI.response.data}
              productManager={isProductManager()}/>
          </Card>
        </Col>
        }
      </Grid>
    </Page>
  );
};

export default Products;
