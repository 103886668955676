import moment from 'moment';
import * as Yup from 'yup';

const fields = {
  EFFECTIVE_DATE: 'effectiveDate',
  DURATION: 'duration',
  INTEREST_RATE: 'interestRate',
};

const schema = Yup.object({
  [fields.EFFECTIVE_DATE]: Yup.date()
    .typeError('Date must be a valid date')
    .transform((value, originalValue) => {
      value = moment(originalValue, 'DD/MM/YYYY');
      return value.isValid() ? value.toDate() : null;
    })
    .required('Effective date is required'),
  [fields.DURATION]: Yup.number().integer(),
  [fields.INTEREST_RATE]: Yup.number().min(0).max(100),
});

export {
  schema as RateFormSchema,
  fields as RateFormFields,
};
