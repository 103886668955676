import {Col, Grid, TextField} from 'capitalroadkit';
import {FastField} from 'formik';
import React from 'react';
import * as Yup from 'yup';

import {withNameSpace} from '../../../functions';

const fields = {
  LINE_1: 'line1',
  LINE_2: 'line2',
  CITY: 'city',
  STATE: 'state',
  POSTCODE: 'postcode',
  COUNTRY: 'country',
};

const initialValues = (address) => ({
  [fields.LINE_1]: address?.line1 || '',
  [fields.LINE_2]: address?.line2 || '',
  [fields.CITY]: address?.city || '',
  [fields.STATE]: address?.state || '',
  [fields.POSTCODE]: address?.postcode || '',
  [fields.COUNTRY]: address?.country || '',
});

const schema = Yup.object({
  [fields.LINE_1]: Yup.string()
    .required('Line 1 is required'),
  [fields.LINE_2]: Yup.string(),
  [fields.CITY]: Yup.string()
    .required('City is required'),
  [fields.COUNTRY]: Yup.string()
    .required('Country is required'),
  [fields.STATE]: Yup.mixed()
    .required('State is required'),
  [fields.POSTCODE]: Yup.number()
    .typeError('Postcode must be a number')
    .required('Postcode is required'),
});

const AddressForm = ({ nameSpace, readOnly }) => (
    <Grid nested>
      <Col lg={6} sm={12}>
        <FastField component={TextField} fullWidth key={readOnly} label={'Line 1'}
                   name={withNameSpace(nameSpace, fields.LINE_1)}
                   readOnly={readOnly} required/>
      </Col>
      <Col lg={6} sm={12}>
        <FastField component={TextField} fullWidth key={readOnly} label={'Line 2'}
                   name={withNameSpace(nameSpace, fields.LINE_2)}
                   readOnly={readOnly}/>
      </Col>
      <Col lg={6} sm={12}>
        <FastField component={TextField} fullWidth key={readOnly} label={'City'}
                   name={withNameSpace(nameSpace, fields.CITY)}
                   readOnly={readOnly} required/>
      </Col>
      <Col lg={6} sm={12}>
        <FastField component={TextField} fullWidth key={readOnly} label={'Country'}
                   name={withNameSpace(nameSpace, fields.COUNTRY)}
                   readOnly={readOnly} required/>
      </Col>
      <Col lg={6} sm={12}>
        <FastField component={TextField} fullWidth key={readOnly} label={'State'}
                   name={withNameSpace(nameSpace, fields.STATE)}
                   readOnly={readOnly} required/>
      </Col>
      <Col lg={3} sm={12}>
        <FastField component={TextField} fullWidth key={readOnly} label={'Postcode'}
                   name={withNameSpace(nameSpace, fields.POSTCODE)}
                   readOnly={readOnly} required/>
      </Col>
    </Grid>
);

export default AddressForm;
export { initialValues as AddressFormInitialValues, schema as AddressFormSchema };
