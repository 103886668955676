import { Table } from 'capitalroadkit';
import React, { useMemo } from 'react';

const ReportsTable = ({
  data, setSelected,
}) => {
  const columns = useMemo(() => [
    {
      id: 'rateTables',
      Header: '',
      Footer: '',
      columns: [
        {
          Header: 'axios',
          accessor: 'axios',
        },
        {
          Header: 'Report',
          accessor: 'name',
        },
      ],
    },
  ], []);

  return (
    <Table
      columns={columns}
      data={data}
      hiddenColumns={['axios']}
      rowClick={(row) => setSelected(row.original)}
      sortBy={[{ id: 'effectiveDate', desc: false }]}
    />
  );
};

export default ReportsTable;
