import {
  Button, Card, CardBody, CardHeader, CardTitle, Col, Grid, Page, PageHeader, useTheme,
} from 'capitalroadkit';
import { Form, FormikProvider, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';

import NotePaymentForm, { NotePaymentFormInitialValues, NotePaymentFormSchema, Type } from './NotePaymentForm';

import { getNoteByUuidAxios, processInterestPaymentAxios, processPrincipalPaymentAxios } from '../../../../api/NoteResource';
import { getPartyNotesAxios } from '../../../../api/PartyResource';
import Error from '../../../../components/Error/Error';
import Loading from '../../../../components/Loading/Loading';
import { toAPIDate } from '../../../../formatters';
import useAPI from '../../../../hooks/useAPI';
import NotesTable from '../../NotesTable';
import NoteOverview from '../NoteOverview';

const Payments = () => {
  const history = useHistory();
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    getNoteAPI.fetch();
  }, [params.uuid]);

  const getNoteAPI = useAPI(getNoteByUuidAxios(params.uuid), false);

  useEffect(() => {
    if (getNoteAPI.response && !getNoteAPI.loading) {
      getPartyNotesAPI.fetch();
    }
  }, [getNoteAPI.response, getNoteAPI.loading]);

  const getPartyNotesAPI = useAPI(getPartyNotesAxios(getNoteAPI.response?.data.party.uuid), false);

  const processInterestPaymentAPI = useAPI(
    processInterestPaymentAxios(getNoteAPI.response?.data.uuid),
    false,
  );
  const processPrincipalPaymentAPI = useAPI(
    processPrincipalPaymentAxios(getNoteAPI.response?.data.uuid),
    false,
  );

  useEffect(() => {
    const response = processInterestPaymentAPI.response || processPrincipalPaymentAPI.response;
    const loading = processInterestPaymentAPI.loading || processPrincipalPaymentAPI.loading;

    if (!loading && response) {
      enqueueSnackbar('Payment sent for processing');
      history.push(`/secure/notes/${getNoteAPI.response.data.uuid}`);
    }
  }, [processInterestPaymentAPI.response, processInterestPaymentAPI.loading,
    processPrincipalPaymentAPI.response, processPrincipalPaymentAPI.loading]);

  const notePaymentForm = useFormik({
    initialValues: NotePaymentFormInitialValues,
    onSubmit: (values) => {
      const apiValues = {
        date: toAPIDate(values.date),
        settlementDate: toAPIDate(values.settlementDate),
        amount: { currency: 'AUD', value: values.amount },
      };

      if (values.type === Type.Interest) processInterestPaymentAPI.fetch({}, apiValues);
      if (values.type === Type.Principal) processPrincipalPaymentAPI.fetch({}, apiValues);
    },
    validationSchema: NotePaymentFormSchema,
  });

  if (getNoteAPI.loading) return <Loading size={'3x'}/>;
  if (getNoteAPI.response) {
    return (
      <Page id={'payments'}>
        <Grid>
          <PageHeader
            heading={getNoteAPI.response.data.noteReferenceId}
            subHeading={'Payments'}
          >
            {getNoteAPI.response?.data.status === 'ACTIVE'
            && <Col sm={12} style={{ textAlign: 'right' }}>
              <Link to={{ pathname: `/secure/notes/${getNoteAPI.response.data.uuid}` }}>
                <Button color={'primary'}>Details</Button>
              </Link>
              <Link style={{ marginLeft: '.5rem' }} to={{ pathname: `/secure/notes/${getNoteAPI.response.data.uuid}/maturity-instruction` }}>
                <Button color={'primary'}>Update Maturity Instruction</Button>
              </Link>
              <Link style={{ marginLeft: '.5rem' }} to={{ pathname: `/secure/notes/${getNoteAPI.response.data.uuid}/instructions`, query: { type: Type.Rollover } }}>
                <Button color={'primary'}>Rollover</Button>
              </Link>
              <Link style={{ marginLeft: '.5rem' }} to={{ pathname: `/secure/notes/${getNoteAPI.response.data.uuid}/instructions`, query: { type: Type.Redeem } }}>
                <Button color={'primary'}>Redeem</Button>
              </Link>
            </Col>
            }
          </PageHeader>
          {getPartyNotesAPI.response
          && <Col sm={12}>
            <Card>
              <NotesTable
                data={getPartyNotesAPI.response.data.filter((note) => note.status === 'ACTIVE')}
                party
                rowClick={(row) => history.push(`/secure/notes/${row.values.uuid}/payments`)}
                selected={getNoteAPI.response.data.uuid}
              />
            </Card>
          </Col>
          }
          <Col lg={8} md={12} sm={12}>
            <Card>
              <CardHeader><CardTitle>Payment</CardTitle></CardHeader>
              <CardBody>
                <FormikProvider value={notePaymentForm}>
                  <Form>
                    <Grid nested>
                      <NotePaymentForm/>
                      <Col sm={12} style={{ textAlign: 'right' }}>
                        <Button
                          color={'success'}
                          loading={
                            processInterestPaymentAPI.loading
                          || processPrincipalPaymentAPI.loading
                          }
                          type={'submit'}
                        >
                          Submit
                        </Button>
                        {(notePaymentForm.values.type === Type.Interest
                          && processInterestPaymentAPI.error)
                        && <Error error={processInterestPaymentAPI.error.response.data}/>
                        }
                        {(notePaymentForm.values.type === Type.Principal
                          && processPrincipalPaymentAPI.error)
                        && <Error error={processInterestPaymentAPI.error.response.data}/>
                        }
                      </Col>
                    </Grid>
                  </Form>
                </FormikProvider>
              </CardBody>
            </Card>
          </Col>
          <Col lg={4} md={12} sm={12}>
            <Card height={'auto'}>
              <CardHeader><CardTitle>Note</CardTitle></CardHeader>
              <CardBody>
                <NoteOverview getNote={getNoteAPI.fetch} note={getNoteAPI.response.data} small/>
              </CardBody>
            </Card>
          </Col>
        </Grid>
      </Page>
    );
  }
  return null;
};

export default Payments;
