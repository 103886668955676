import axios from 'axios';

import { host } from './utilities';

export const generateCurrentInvestmentsReportAxios = () => (config) => axios.get(`${host()}/note/report/current-investments-report`, config);

export const generateMaturedNotesReportAxios = () => (config) => axios.get(`${host()}/note/report/matured-notes-report`, config);

export const generateNotesInterestPaymentsFlagReportAxios = () => (config) => axios.get(`${host()}/note/report/interest-payments-resumed-report`, config);

export const generateNotesPrincipalPaymentsFlagReportAxios = () => (config) => axios.get(`${host()}/note/report/principal-payments-resumed-report`, config);

export const generateNotesStatementEmailFlagReportAxios = () => (config) => axios.get(`${host()}/note/report/statement-email-suspended-report`, config);