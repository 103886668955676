import {
  Button,
  Card,
  CardBody,
  CardHeader, CardTitle,
  Col, Grid, Page, PageHeader,
} from 'capitalroadkit';
import { Form, FormikProvider, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';

import DepositForm, { DepositFormInitialValues } from './DepositForm';

import { getNoteByUuidAxios, processOpeningBalanaceAxios } from '../../../../api/NoteResource';
import { getPartyNotesAxios } from '../../../../api/PartyResource';
import Loading from '../../../../components/Loading/Loading';
import { toAPIDate } from '../../../../formatters';
import useAPI from '../../../../hooks/useAPI';
import NotesTable from '../../NotesTable';
import NoteOverview from '../NoteOverview';

const Deposits = () => {
  const history = useHistory();
  const { uuid } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    getNoteAPI.fetch();
  }, [uuid]);

  const getNoteAPI = useAPI(getNoteByUuidAxios(uuid), false);

  useEffect(() => {
    if (getNoteAPI.response && !getNoteAPI.loading) {
      getPartyNotesAPI.fetch();
    }
  }, [getNoteAPI.response, getNoteAPI.loading]);

  const getPartyNotesAPI = useAPI(getPartyNotesAxios(getNoteAPI.response?.data.party.uuid), false);

  const processOpeningBalanceAPI = useAPI(
    processOpeningBalanaceAxios(getNoteAPI.response?.data.uuid),
    false,
  );

  useEffect(() => {
    if (!processOpeningBalanceAPI.loading && processOpeningBalanceAPI.response) {
      enqueueSnackbar('Deposit sent for processing');
      history.push(`/secure/notes/${getNoteAPI.response.data.uuid}`);
    }
  }, [processOpeningBalanceAPI.response, processOpeningBalanceAPI.loading]);

  const depositForm = useFormik({
    initialValues: DepositFormInitialValues,
    onSubmit: (values) => {
      const apiValues = {};
      apiValues.amount = { currency: 'AUD', value: values.amount };
      apiValues.date = toAPIDate(values.date);
      apiValues.settlementDate = toAPIDate(values.settlementDate);
      apiValues.settlementStatus = 'COMPLETED';
      processOpeningBalanceAPI.fetch({}, apiValues);
    },
  });

  if (getNoteAPI.response) {
    return (
      <Page id={'deposits'}>
        <Grid>
          <PageHeader
            heading={getNoteAPI.response.data.noteReferenceId}
            subHeading={'Deposit'}
          >
            <Col sm={12} style={{ textAlign: 'right', flex: 'space-evenly' }}>
              <Link to={{ pathname: `/secure/notes/${getNoteAPI.response.data.uuid}` }}>
                <Button color={'primary'}>Details</Button>
              </Link>
            </Col>
          </PageHeader>
          {getPartyNotesAPI.response
          && <Col sm={12}>
            <Card>
              <NotesTable
                data={getPartyNotesAPI.response.data.filter((note) => note.status === 'ACTIVE')}
                party
                rowClick={(row) => history.push(`/secure/notes/${row.values.uuid}/deposits`)}
                selected={getNoteAPI.response.data.uuid}
              />
            </Card>
          </Col>
          }
          <Col lg={8} md={12} sm={12}>
            <Card>
              <CardHeader><CardTitle>Deposit</CardTitle></CardHeader>
              <CardBody>
                <FormikProvider value={depositForm}>
                  <Form>
                    <Grid nested>
                      <DepositForm form={depositForm}/>
                      <Col sm={12} style={{ textAlign: 'right' }}>
                        <Button color={'primary'} type={'submit'}>Submit</Button>
                      </Col>
                    </Grid>
                  </Form>
                </FormikProvider>
              </CardBody>
            </Card>
          </Col>
          <Col lg={4} md={12} sm={12}>
            <Card>
              <CardHeader><CardTitle>Note</CardTitle></CardHeader>
              <CardBody>
                <NoteOverview getNote={getNoteAPI.fetch} note={getNoteAPI.response.data} small/>
              </CardBody>
            </Card>
          </Col>
        </Grid>
      </Page>
    );
  }
  if (getNoteAPI.loading) return <Loading size={'3x'}/>;
  return null;
};

export default Deposits;
