import {
  CardBody, Checkbox, Col, Grid, TextField,
} from 'capitalroadkit';
import { FastField } from 'formik';
import React from 'react';
import * as Yup from 'yup';

import AddressForm, { AddressFormInitialValues, AddressFormSchema } from './AddressForm';
import PhoneForm, { PhoneFormInitialValues, PhoneFormSchema } from './PhoneForm';

import { withNameSpace } from '../../../functions';

const fields = {
  EMAIL_ADDRESS: 'emailAddress',
  WEB_ADDRESS: 'webAddress',
  MOBILE: 'mobile',
  LANDLINE: 'landLine',
  ADDRESS: 'address',
  SEPARATE_POSTAL_ADDRESS: 'separatePostalAddress',
  POSTAL_ADDRESS: 'postalAddress',
};

const initialValues = {
  [fields.EMAIL_ADDRESS]: {
    address: '',
  },
  [fields.WEB_ADDRESS]: {
    url: '',
  },
  [fields.MOBILE]: PhoneFormInitialValues(true),
  [fields.LANDLINE]: PhoneFormInitialValues(false),
  [fields.ADDRESS]: AddressFormInitialValues,
  [fields.SEPARATE_POSTAL_ADDRESS]: false,
  [fields.POSTAL_ADDRESS]: AddressFormInitialValues,
};

const schema = Yup.object({
  [fields.EMAIL_ADDRESS]: Yup.object().shape({
    address: Yup.string()
      .email('Email must be a valid email address')
      .required('Email is required'),
  }),
  [fields.WEB_ADDRESS]: Yup.object().shape({
    address: Yup.string()
      .matches(/[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/gi, { message: 'Must be a valid url' }),
  }),
  [fields.MOBILE]: PhoneFormSchema(true),
  [fields.LANDLINE]: PhoneFormSchema(false),
  [fields.ADDRESS]: AddressFormSchema,
  [fields.SEPARATE_POSTAL_ADDRESS]: Yup.boolean(),
  [fields.POSTAL_ADDRESS]: Yup.object().when('separatePostalAddress', {
    is: true,
    then: AddressFormSchema,
    otherwise: Yup.object(),
  }),
});

const ContactDetailsForm = ({ form, nameSpace, readOnly }) => (
    <>
      <CardBody nested>
        <Grid nested>
          <Col sm={12}>
            <h5>Contact Details</h5>
          </Col>
          <Col sm={6}>
            <FastField component={TextField} fullWidth label={'Email'}
                       name={withNameSpace(nameSpace, 'emailAddress.address')} readOnly={readOnly} required/>
          </Col>
          <Col sm={6}>
            <FastField component={TextField} fullWidth label={'Website'}
                       name={withNameSpace(nameSpace, 'webAddress.url')} readOnly={readOnly}/>
          </Col>
          <Col sm={6}>
            <PhoneForm nameSpace={withNameSpace(nameSpace, fields.MOBILE)} readOnly={readOnly} required
                       title={'Mobile'}/>
          </Col>
          <Col sm={6}>
            <PhoneForm nameSpace={withNameSpace(nameSpace, fields.LANDLINE)} readOnly={readOnly}
                       title={'Landline'}/>
          </Col>
        </Grid>
      </CardBody>
      <CardBody nested>
        <Grid nested>
          <Col sm={12}>
            <h5>Address</h5>
          </Col>
          <Col sm={12}>
            <AddressForm nameSpace={withNameSpace(nameSpace, fields.ADDRESS)} readOnly={readOnly}/>
          </Col>
        </Grid>
      </CardBody>
      <CardBody>
        <FastField component={Checkbox} label={'Separate Postal Address?'}
                   name={withNameSpace(nameSpace, fields.SEPARATE_POSTAL_ADDRESS)} readOnly={readOnly}/>
      </CardBody>
      {/* <Collapse in={form.values[nameSpace].separatePostalAddress}> */}
      {/*  <CardBody nested style={{marginBottom: '1.25rem'}}> */}
      {/*    <Grid nested> */}
      {/*      <Col sm={12}> */}
      {/*        <h5>Postal Address</h5> */}
      {/*      </Col> */}
      {/*      <Col sm={12}> */}
      {/*        <AddressForm nameSpace={withNameSpace(nameSpace, fields.POSTAL_ADDRESS)} readOnly={readOnly}/> */}
      {/*      </Col> */}
      {/*    </Grid> */}
      {/*  </CardBody> */}
      {/* </Collapse> */}
    </>
);

export default ContactDetailsForm;
export { initialValues as ContactDetailsFormInitialValues, schema as ContactDetailsFormSchema };
