import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import { faDonate, faDownload } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table } from 'capitalroadkit';
import React, { useMemo } from 'react';

import { parseEnum, toDateString } from '../../formatters';

const BankFileTable = ({
  api, data, download, process, processing,
}) => {
  const columns = useMemo(() => [
    {
      id: 'notes',
      Header: '',
      Footer: '',
      columns: [
        {
          Header: 'uuid',
          accessor: 'uuid',
        },
        {
          Header: 'Name',
          accessor: 'name',
        },
        {
          Header: 'Product',
          accessor: 'productName',
        },
        {
          Header: 'Date',
          accessor: 'date',
          Cell: ({ cell: { value } }) => toDateString(value),
        },
        {
          Header: 'Status',
          accessor: 'status',
          Cell: ({ cell: { value } }) => parseEnum(value),
        },
        {
          id: 'actions',
          headerStyle: { width: '4.5rem' },
          Cell: ({ row: { values } }) => (
            <>
              <FontAwesomeIcon
                icon={faDownload}
                onClick={() => download(values)}
                style={{ cursor: 'pointer', fontSize: '1rem', width: '1rem' }}
              />
              {values.status === 'PROCESSING'
                ? processing
                  ? <FontAwesomeIcon
                    icon={faSpinnerThird}
                    spin
                    style={{
                      fontSize: '1rem', marginLeft: '.5rem', width: '1rem',
                    }}
                  />
                  : <FontAwesomeIcon
                    icon={faDonate}
                    onClick={() => process(values)}
                    style={{
                      cursor: 'pointer', fontSize: '1rem', marginLeft: '.5rem', width: '1rem',
                    }}
                  />
                : null
              }
            </>
          ),
        },
      ],
    },
  ], []);

  return (
    <Table
      api={api}
      columns={columns}
      data={data}
      dataKey={'uuid'}
      hiddenColumns={['uuid']}
      sortBy={[
        {
          id: 'date',
          desc: true,
        },
      ]}
    />
  );
};

export default BankFileTable;
