import {
  CardBody, Col, Grid, TextField,
} from 'capitalroadkit';
import { Field } from 'formik';
import React, { useEffect } from 'react';
import * as Yup from 'yup';

import FixedInterestNoteForm, {
  FixedInterestNoteFormInitialValues,
  FixedInterestNoteFormSchema,
} from '../../FixedInterestNoteForm';

const fields = {
  DATE: 'date',
  NOTE_DEFINITION: 'noteDefinition',
};

const initialValues = {
  [fields.DATE]: '',
  [fields.NOTE_DEFINITION]: FixedInterestNoteFormInitialValues,
};

const schema = Yup.object({
  [fields.DATE]: Yup.string()
    .required('Date is required'),
  [fields.NOTE_DEFINITION]: FixedInterestNoteFormSchema,
});

const RolloverForm = ({ form, investmentValue }) => {
  useEffect(() => {
    form.setFieldValue(`${fields.NOTE_DEFINITION}.principalAtInception`, investmentValue);
  }, [investmentValue]);

  return (
    <>
      <Col sm={12}>
        <Field
          component={TextField}
          label={'Date'}
          mask={'99/99/9999'}
          name={fields.DATE}
          required
          style={{ width: '15rem' }}
        />
      </Col>
      <Col sm={12}>
        <CardBody nested>
          <Grid nested>
            <Col sm={12}>
              <h6>New Note Term</h6>
            </Col>
            <FixedInterestNoteForm form={form} nameSpace={fields.NOTE_DEFINITION} readOnly={['principalAtInception']}/>
          </Grid>
        </CardBody>
      </Col>
    </>
  );
};

export default RolloverForm;
export { initialValues as RolloverFormInitialValues, schema as RolloverFormSchema };
