import {
  Button, Card, CardBody, CardHeader, CardTitle, Col, Grid, Page, useTheme,
} from 'capitalroadkit';
import { Form, FormikProvider, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';

import ChangePasswordForm, { ChangePasswordFormInitialValues, ChangePasswordFormSchema } from './ChangePasswordForm';

import { updatePasswordAxios } from '../../api/AuthUserResource';
import useAPI from '../../hooks/useAPI';

const Settings = ({ user }) => {
  const theme = useTheme();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const updatePasswordAPI = useAPI(updatePasswordAxios(), false);

  useEffect(() => {
    if (!updatePasswordAPI.loading && updatePasswordAPI.response) {
      enqueueSnackbar('Password changed');
      history.push('/secure/admin');
    }
  }, [updatePasswordAPI.loading, updatePasswordAPI.response]);

  const changePasswordForm = useFormik({
    initialValues: ChangePasswordFormInitialValues,
    onSubmit: (values) => updatePasswordAPI.fetch({}, values),
    validationSchema: ChangePasswordFormSchema,
  });

  return (
    <Page id={'login'}>
      <Grid>
        <Col sm={12}>
          <Card>
            <CardBody>
              <h4>
                Settings<br/>
                <small>{user.name}</small>
              </h4>
            </CardBody>
          </Card>
        </Col>
        <Col lg={3} md={6} sm={12}>
          <Card>
            <CardHeader><CardTitle>Change Password</CardTitle></CardHeader>
            <CardBody>
              <FormikProvider value={changePasswordForm}>
                <Form>
                  <Grid nested>
                    <ChangePasswordForm/>
                    <Col sm={12} style={{ textAlign: 'right' }}>
                      <Button
                        color={'success'}
                        loading={updatePasswordAPI.loading}
                        type={'submit'}
                      >
                        Submit
                      </Button>
                      {updatePasswordAPI.error
                      && <p style={{ color: theme.palette.danger.main, marginTop: '.5rem' }}>
                        {updatePasswordAPI.error.response.data.errorDescription}
                      </p>
                      }
                    </Col>
                  </Grid>
                </Form>
              </FormikProvider>
            </CardBody>
          </Card>
        </Col>
      </Grid>
    </Page>
  );
};

export default Settings;
