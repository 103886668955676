import {
  CardBody, Col, Grid, useTheme,
} from 'capitalroadkit';
import React from 'react';
import { Link } from 'react-router-dom';

import ReinvestModal from './ReinvestModal';
import SuspensionsModal from './SuspensionsModal';

import {
  parseBoolean,
  parseEnum, toCurrency, toDateString, toPercent,
} from '../../../formatters';

const NoteOverview = ({ getNote, note, small }) => {
  const theme = useTheme();

  const getInstruction = (maturityInstruction) => {
    const {
      rolloverOfPrincipal,
      rolloverOfInterest,
      rollover,
      partialRolloverOfPrincipal,
    } = maturityInstruction;
    if (!rollover) return 'Redeem';
    if (rollover && rolloverOfPrincipal && rolloverOfInterest && !partialRolloverOfPrincipal) return 'Rollover';
    return 'Partial Rollover';
  };

  const getPartialRolloverAndRedemptionAmount = (maturityInstruction) => {
    const object = { rollover: 0, redemption: note.balance.value };

    if (note.maturityInstruction.rolloverOfPrincipal) {
      if (!note.maturityInstruction.partialRolloverOfPrincipal) {
        object.rollover += parseFloat(note.principal.value);
        object.redemption -= parseFloat(note.principal.value);
      } else {
        object.rollover += parseFloat(note.maturityInstruction.principalAmountToRollover.value);
        object.redemption -= parseFloat(note.maturityInstruction.principalAmountToRollover.value);
      }
    }

    if (note.maturityInstruction.rolloverOfInterest) {
      object.rollover += parseFloat(note.capitalisedInterest.value);
      object.redemption -= parseFloat(note.capitalisedInterest.value);
    }

    return object;
  };

  return (
    <Grid nested>
      <Col lg={small ? 12 : 4} md={small ? 12 : 4} sm={12}>
        <CardBody height={'100%'} nested>
          <table
            style={{
              borderCollapse: 'collapse', tableLayout: 'fixed', textAlign: 'left', width: '100%',
            }}
          >
            <tbody>
            <tr>
              <th>Client</th>
              <td>
                <Link to={`/secure/clients/${note.party.uuid}`}>{note.party.name}</Link>
              </td>
            </tr>
            <tr>
              <th>Status</th>
              <td
                style={{ color: note.status === 'ACTIVE' ? theme.palette.success.main : theme.palette.warning.main }}>{parseEnum(note.status)}</td>
            </tr>
            <tr>
              <th>Term</th>
              <td>{`${note.duration} months`}</td>
            </tr>
            {note.status === 'ACTIVE'
            && <>
              <tr>
                <th>Inception Date</th>
                <td>{toDateString(note.dateOfInception)}</td>
              </tr>
              <tr>
                <th>Maturity Date</th>
                <td>{toDateString(note.dateOfMaturity)}</td>
              </tr>
            </>
            }
            <tr>
              <th>Principal Payments Suspended</th>
              <td>
                {parseBoolean(note.principalPaymentSuspended)}
                {note.status === 'ACTIVE'
                && <SuspensionsModal getNote={getNote} note={note}/>
                }
              </td>
            </tr>
            <tr>
              <th>Interest Payments Suspended</th>
              <td>
                {parseBoolean(note.interestPaymentSuspended)}
                {note.status === 'ACTIVE'
                && <SuspensionsModal getNote={getNote} note={note}/>
                }
              </td>
            </tr>
            </tbody>
          </table>
        </CardBody>
      </Col>
      <Col lg={small ? 12 : 4} md={small ? 12 : 4} sm={12}>
        <CardBody height={'100%'} nested>
          <table
            style={{
              borderCollapse: 'collapse', tableLayout: 'fixed', textAlign: 'left', width: '100%',
            }}
          >
            <tbody>
            <table
              style={{
                borderCollapse: 'collapse', tableLayout: 'fixed', textAlign: 'left', width: '100%',
              }}
            >
              <tbody>
              <tr>
                <th>Distribution Frequency</th>
                <td>{parseEnum(note.distributionFrequency)}</td>
              </tr>
              <tr>
                <th>Interest Distribution</th>
                <td>
                  {note.interestToBeReinvested ? 'Reinvest' : 'Monthly'}
                  {note.status === 'ACTIVE'
                  && <ReinvestModal getNote={getNote} note={note}/>
                  }
                </td>
              </tr>
              <tr>
                <th>Interest Rate</th>
                <td>{toPercent(note.interestRate)}</td>
              </tr>
              <tr>
                <th>Principal at Inception</th>
                <td>{toCurrency(note.principalAtInception.value)}</td>
              </tr>
              <tr>
                <th>Principal Amount</th>
                <td>{toCurrency(note.principal.value)}</td>
              </tr>
              <tr>
                <th>Capitalised Interest</th>
                <td>{toCurrency(note.capitalisedInterest.value)}</td>
              </tr>
              <tr>
                <th>Balance</th>
                <td
                  style={{
                    color: note.balance.value > 0
                      ? theme.palette.success.main
                      : theme.palette.danger.main,
                  }}
                >
                  {toCurrency(note.balance.value)}
                </td>
              </tr>
              </tbody>
            </table>
            </tbody>
          </table>
        </CardBody>
      </Col>
      <Col lg={small ? 12 : 4} md={small ? 12 : 4} sm={12}>
        <CardBody height={'100%'} nested>
          <table
            style={{
              borderCollapse: 'collapse', tableLayout: 'fixed', textAlign: 'left', width: '100%',
            }}
          >
            <tbody>
            {(note.maturityInstruction)
              ? <>
                  <tr>
                    <th colSpan={2}>
                      Maturity Instruction
                    </th>
                  </tr>
              <tr>
                <th>Instruction</th>
                <td>{getInstruction(note.maturityInstruction)}</td>
              </tr>
              {(getInstruction(note.maturityInstruction) === 'Rollover')
              && <tr>
                <th>Rollover Amount to Date</th>
                <td>{toCurrency(note.balance.value)}</td>
              </tr>
              }
              {(getInstruction(note.maturityInstruction) === 'Partial Rollover')
              && <>
                <tr>
                  <th>Rollover Amount to Date</th>
                  <td>
                    {toCurrency(
                      getPartialRolloverAndRedemptionAmount(note.maturityInstruction).rollover,
                    )}
                  </td>
                </tr>
                <tr>
                  <th>Redemption Amount to Date</th>
                  <td>
                    {toCurrency(
                      getPartialRolloverAndRedemptionAmount(note.maturityInstruction).redemption,
                    )}
                  </td>
                </tr>
              </>
              }
              {(getInstruction(note.maturityInstruction) === 'Redeem')
              && <tr>
                <th>Redemption Amount to Date</th>
                <td>{toCurrency(note.balance.value)}</td>
              </tr>
              }
              {(getInstruction(note.maturityInstruction) === 'Rollover' || getInstruction(note.maturityInstruction) === 'Partial Rollover')
              && <>
                <tr>
                  <th colSpan={2}>Note</th>
                </tr>
                <tr>
                  <th>Duration</th>
                  <td>{note.maturityInstruction.noteDefinition.duration}</td>
                </tr>
                <tr>
                  <th>Interest Rate</th>
                  <td>{toPercent(note.maturityInstruction.noteDefinition.interestRate)}</td>
                </tr>
              </>
              }
            </>
              : <>
                  <tr>
                    <th>
                      Maturity Instruction
                    </th>
                  </tr>
                <tr>
                  No maturity instruction has been added. The system will automatically
                  rollover the note at maturity with the same rate and term of the current note.
                </tr>
                </>
            }
            </tbody>
          </table>
        </CardBody>
      </Col>
    </Grid>
  );
};

export default NoteOverview;
