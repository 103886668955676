import 'capitalroadkit/dist/index.css';
import 'sanitize.css';

import './App.css';

import {
  globalStyles, capitalroad, kpmg, light, mayfair, navag8, nommad, oktowealth, ThemeProvider,
} from 'capitalroadkit';
import { SnackbarProvider } from 'notistack';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Redirect, Switch } from 'react-router-dom';

import Route from './components/Route/Route';
import useLocalStorage from './hooks/useLocalStorage';
import DefaultLayout from './layouts/DefaultLayout';
import ErrorLayout from './layouts/ErrorLayout';
import LandingLayout from './layouts/LandingLayout';
import Banking from './pages/Banking';
import Clients from './pages/Clients';
import Party from './pages/Clients/Client';
import Client from './pages/Clients/Client/Client';
import Dashboard from './pages/Dashboard';
import Unauthorzied from './pages/Error/40100';
import NetworkError from './pages/Error/NetworkError';
import Login from './pages/Login';
import NewParty from './pages/NewParty';
import Notes from './pages/Notes';
import NewNote from './pages/Notes/New';
import Note from './pages/Notes/Note';
import Deposits from './pages/Notes/Note/Deposits';
import Instructions from './pages/Notes/Note/Instructions';
import MaturityInstruction from './pages/Notes/Note/MaturityInstruction';
import Payments from './pages/Notes/Note/Payments';
import Products from './pages/Products';
import NewProduct from './pages/Products/NewProduct';
import UpdateProduct from './pages/Products/UpdateProduct';
import Reports from './pages/Reports';
import ResetPassword from './pages/ResetPassword';
import ResetPasswordInit from './pages/ResetPassword/ResetPasswordInitialization';
import Secure from './pages/Secure';
import Settings from './pages/Settings';

const routes = [
  {
    path: '/error/40100', page: Unauthorzied, layout: ErrorLayout, authorized: false,
  },
  {
    path: '/error/network-error', page: NetworkError, layout: ErrorLayout, authorized: false,
  },
  {
    path: '/login', page: Login, layout: LandingLayout, authorized: false,
  },
  {
    path: '/reset-password/:resetToken', page: ResetPassword, layout: LandingLayout, authorized: false,
  },
  {
    path: '/reset-password', page: ResetPasswordInit, layout: LandingLayout, authorized: false,
  },
  {
    path: '/secure/admin', page: Dashboard, layout: DefaultLayout, authorized: true,
  },
  {
    path: '/secure/banking', page: Banking, layout: DefaultLayout, authorized: true,
  },
  {
    path: '/secure/clients/:uuid/update', page: Party, layout: DefaultLayout, authorized: true,
  },
  {
    path: '/secure/clients/:uuid', page: Client, layout: DefaultLayout, authorized: true,
  },
  {
    path: '/secure/clients', page: Clients, layout: DefaultLayout, authorized: true,
  },
  {
    path: '/secure/new-party', page: NewParty, layout: DefaultLayout, authorized: true,
  },
  {
    path: '/secure/notes/new', page: NewNote, layout: DefaultLayout, authorized: true,
  },
  {
    path: '/secure/notes/:uuid/deposits', page: Deposits, layout: DefaultLayout, authorized: true,
  },
  {
    path: '/secure/notes/:uuid/instructions', page: Instructions, layout: DefaultLayout, authorized: true,
  },
  {
    path: '/secure/notes/:uuid/maturity-instruction', page: MaturityInstruction, layout: DefaultLayout, authorized: true,
  },
  {
    path: '/secure/notes/:uuid/payments', page: Payments, layout: DefaultLayout, authorized: true,
  },
  {
    path: '/secure/notes/:uuid', page: Note, layout: DefaultLayout, authorized: true,
  },
  {
    path: '/secure/notes', page: Notes, layout: DefaultLayout, authorized: true,
  },
  {
    path: '/secure/products/new', page: NewProduct, layout: DefaultLayout, authorized: true,
  },
  {
    path: '/secure/products/:uuid/update', page: UpdateProduct, layout: DefaultLayout, authorized: true,
  },
  {
    path: '/secure/products', page: Products, layout: DefaultLayout, authorized: true,
  },
  {
    path: '/secure/reports', page: Reports, layout: DefaultLayout, authorized: true,
  },
  {
    path: '/secure/settings', page: Settings, layout: DefaultLayout, authorized: true,
  },
  {
    path: '/secure', page: Secure, layout: DefaultLayout, authorized: true,
  },
  {
    path: '/', page: () => <Redirect to={'/secure'}/>, layout: DefaultLayout, authorized: true,
  },
];

const App = () => {
  const getInitialSkin = () => {
    if (window.location.href.indexOf('captialroad') > -1) return capitalroad;
    if (window.location.href.indexOf('kpmg') > -1) return kpmg;
    if (window.location.href.indexOf('mayfair') > -1) return mayfair;
    if (window.location.href.indexOf('navag8') > -1) return navag8;
    if (window.location.href.indexOf('nommad') > -1) return nommad;
    if (window.location.href.indexOf('oktowealth') > -1) return oktowealth;
    return capitalroad;
  };

  const [mode, setMode] = useLocalStorage('mode', light);
  const [skin, setSkin] = useState(getInitialSkin());

  const classes = globalStyles({ mode, skin });

  return (
    <div className={classes.app} id={'app'}>
      <ThemeProvider mode={mode} skin={skin}>
        <Helmet>
          <title>{skin.name}</title>
          <link href={skin.images.favicon} rel={'icon'} sizes={'16x16'} type={'image/png'}/>
        </Helmet>
        <SnackbarProvider
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'top',
          }}
        >
          <Router>
            <Switch>
              {routes.map((route) => (
                <Route
                  authorized={route.authorized}
                  component={route.page}
                  key={route.path}
                  layout={route.layout}
                  path={route.path}
                  setMode={setMode}
                  setSkin={setSkin}
                />
              ))}
            </Switch>
          </Router>
        </SnackbarProvider>
      </ThemeProvider>
    </div>
  );
};

export default App;
