import { Checkbox, Col, TextField } from 'capitalroadkit';
import { Field } from 'formik';
import moment from 'moment';
import React from 'react';
import * as Yup from 'yup';

import { now, toDateString } from '../../../formatters';
import { withNameSpace } from '../../../functions';

const fields = {
  CODE: 'code',
  PRODUCT_NAME: 'name',
  ISSUER: 'issuer',
  MANAGER_NAME: 'managerName',
  IS_RESTRICTED: 'isRestricted',
  PRODUCT_DESCRIPTION: 'productDescription',
  START_DATE: 'startDate',
  END_DATE: 'endDate',
  CLOSED: 'closed',
};

const initialValues = (product) => ({
  [fields.CODE]: product?.code,
  [fields.PRODUCT_NAME]: product?.name,
  [fields.ISSUER]: product?.issuer,
  [fields.MANAGER_NAME]: product?.managerName,
  [fields.START_DATE]: product?.startDate
    ? toDateString(product.startDate)
    : toDateString(now()),
  [fields.END_DATE]: product?.endDate
    ? toDateString(product.endDate)
    : '',
  [fields.CLOSED]: product?.closed,
});

const schema = Yup.object({
  [fields.CODE]: Yup.string()
    .required('Code is required'),
  [fields.PRODUCT_NAME]: Yup.string()
    .required('Product name is required'),
  [fields.ISSUER]: Yup.string()
    .required('Issuer is required'),
  [fields.MANAGER_NAME]: Yup.string()
    .required('Manager name is required'),
  [fields.START_DATE]: Yup.date()
    .typeError('Date must be a valid date')
    .transform((value, originalValue) => {
      value = moment(originalValue, 'DD/MM/YYYY');
      return value.isValid() ? value.toDate() : null;
    })
    .required('Start date is required'),
  [fields.END_DATE]: Yup.date()
    .typeError('Date must be a valid date')
    .transform((value, originalValue) => {
      value = moment(originalValue, 'DD/MM/YYYY');
      return value.isValid() ? value.toDate() : null;
    }),
  [fields.CLOSED]: Yup.bool(),
});

const ProductDetailsForm = ({
  nameSpace, readOnly, product, editing,
}) => (
  <>
    <Col lg={3} sm={12}>
      <Field
        component={TextField} fullWidth label={'Product ID'}
        name={withNameSpace(nameSpace, fields.CODE)}
        readOnly={!!product || readOnly} required/>
    </Col>
    <Col lg={9} sm={12}>
      <Field
        component={TextField} fullWidth label={'Product name'}
        name={withNameSpace(nameSpace, fields.PRODUCT_NAME)}
        readOnly={!!product || readOnly} required/>
    </Col>
    <Col lg={6} sm={12}>
      <Field
        component={TextField} fullWidth label={'Issuer'}
        name={withNameSpace(nameSpace, fields.ISSUER)}
        readOnly={(!!product && editing !== 'productDetails') || readOnly} required/>
    </Col>
    <Col lg={6} sm={12}>
      <Field
        component={TextField} fullWidth label={'Manager name'}
        name={withNameSpace(nameSpace, fields.MANAGER_NAME)}
        readOnly={(!!product && editing !== 'productDetails') || readOnly} required/>
    </Col>
    <Col lg={3} sm={12}>
      <Field
        component={TextField} fullWidth label={'Effective date'}
        mask={'99/99/9999'} name={withNameSpace(nameSpace, fields.START_DATE)}
        readOnly={!!product || readOnly} required/>
    </Col>
    <Col lg={3} sm={12}>
      <Field
        component={TextField} fullWidth label={'End date'}
        mask={'99/99/9999'} name={withNameSpace(nameSpace, fields.END_DATE)}
        readOnly={(!!product && editing !== 'productDetails') || readOnly}/>
    </Col>
    {product
    && <Col lg={6} sm={12}>
      <Field
        component={Checkbox} fullWidth label={'Product closed'}
        mask={'99/99/9999'} name={withNameSpace(nameSpace, fields.CLOSED)}
        readOnly={(!!product && editing !== 'productDetails') || readOnly}/>
    </Col>}
  </>
);

export default ProductDetailsForm;
export {
  initialValues as ProductDetailsFormInitialValues,
  schema as ProductDetailsFormSchema,
};
