import {CardBody, Checkbox, Col, Grid, TextField,} from 'capitalroadkit';
import {FastField} from 'formik';
import React from 'react';
import * as Yup from 'yup';

import BankAccountForm, {BankAccountFormInitialValues, BankAccountFormSchema} from './SubForm/BankAccountForm';
import ContactDetailsForm, {
  ContactDetailsFormInitialValues,
  ContactDetailsFormSchema,
} from './SubForm/ContactDetailsForm';

import {withNameSpace} from '../../functions';

const fields = {
  NAME: 'name',
  BANK_ACCOUNT: 'bankAccount',
  CONTACT_DETAILS: 'contactDetails',
};

const initialValues = {
  [fields.NAME]: '',
  [fields.CONTACT_DETAILS]: ContactDetailsFormInitialValues,
  [fields.BANK_ACCOUNT]: BankAccountFormInitialValues,
};

const schema = {
  [fields.NAME]: Yup.string().required('Name is required'),
  [fields.CONTACT_DETAILS]: ContactDetailsFormSchema,
  [fields.BANK_ACCOUNT]: BankAccountFormSchema,
};

const PartyAttributesForm = ({ form, nameSpace, readOnly }) => (
  <CardBody>
    <CardBody nested>
      <Grid nested>
        <Col sm={3}>
          <FastField
            component={TextField}
            fullWidth
            required
            label={'Name'}
            name={fields.NAME}
          />
        </Col>
        <Col sm={3}>
          <FastField
            component={Checkbox}
            fullWidth
            label={'Is a foreign resident'}
            name={'foreignResident'}
          />
        </Col>
      </Grid>
    </CardBody>
    <CardBody nested>
      <Grid nested>
        <Col sm={12}>
          <h5>Bank account</h5>
        </Col>
        <BankAccountForm form={form} nameSpace={withNameSpace(nameSpace, fields.BANK_ACCOUNT)}/>
      </Grid>
    </CardBody>
        <ContactDetailsForm
          form={form}
          nameSpace={withNameSpace(nameSpace, fields.CONTACT_DETAILS)}/>
  </CardBody>
);

export default PartyAttributesForm;

export {
  initialValues as PartyAttributesValues,
  schema as PartyAttribuesSchema,
  fields as PartyAttributesFields,
};
