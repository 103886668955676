import { Table } from 'capitalroadkit';
import React, { useMemo } from 'react';

import { toDateString } from '../../formatters';

const NoteTransactionSummaryTable = ({
  data, rowClick,
}) => {
  const columns = useMemo(() => [
    {
      id: 'notes',
      Header: '',
      Footer: '',
      columns: [
        {
          Header: 'Settlement Date',
          accessor: 'date',
          Cell: ({ cell: { value } }) => toDateString(value),
        },
        {
          Header: 'Number of Credits',
          accessor: 'numberOfCredits',
        },
        {
          Header: 'Number of Debits',
          accessor: 'numberOfDebits',
        },
      ],
    },
  ], []);

  return (
    <Table
      columns={columns}
      data={data}
      rowClick={rowClick}
      sortBy={[
        {
          id: 'date',
          desc: true,
        },
      ]}
    />
  );
};

export default NoteTransactionSummaryTable;
