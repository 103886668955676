import {Col, TextField} from 'capitalroadkit';
import {FastField} from 'formik';
import moment from 'moment';
import React from 'react';
import * as Yup from 'yup';

import {withNameSpace} from '../../../functions';

const fields = {
  PASSPORT_NUMBER: 'passportNumber',
  DATE_OF_ISSUE: 'dateOfIssue',
  DATE_OF_EXPIRY: 'dateOfExpiry',
  COUNTRY_OF_ISSUE: 'countryOfIssue',
};

const initialValues = {
  [fields.PASSPORT_NUMBER]: '',
  [fields.DATE_OF_ISSUE]: '',
  [fields.DATE_OF_EXPIRY]: '',
  [fields.COUNTRY_OF_ISSUE]: '',
  type: 'PASSPORT',
};

const schema = Yup.object({
  [fields.PASSPORT_NUMBER]: Yup.string()
    .matches(/^(?!^0+$)[a-zA-Z0-9]{3,20}$/, 'Passport number must be a valid passport number')
    .required('Passport number is required'),
  [fields.DATE_OF_ISSUE]: Yup.date()
    .typeError('Date of issue must be a valid date')
    .transform((value, originalValue) => {
      value = moment(originalValue, 'DD/MM/YYYY');
      return value.isValid() ? value.toDate() : null;
    })
    .max(new Date())
    .required('Date of issue is required'),
  [fields.DATE_OF_EXPIRY]: Yup.date()
    .typeError('Date of expiry must be a valid date')
    .transform((value, originalValue) => {
      value = moment(originalValue, 'DD/MM/YYYY');
      return value.isValid() ? value.toDate() : null;
    })
    .min(new Date())
    .required('Date of expiry is required'),
  [fields.COUNTRY_OF_ISSUE]: Yup.string()
    .required('Country of issue is required'),
});

const PassportDocumentForm = ({ nameSpace, readOnly }) => (
  <>
    <Col sm={12}>
      <h5>Passport</h5>
    </Col>
    <Col sm={12}>
      <FastField component={TextField} fullWidth key={readOnly} label={'Passport number'}
                 name={withNameSpace(nameSpace, fields.PASSPORT_NUMBER)}
                 readOnly={readOnly} required/>
    </Col>
    <Col lg={6} sm={12}>
      <FastField component={TextField} fullWidth label={'Date of issue'}
                 mask={'99/99/9999'}
                 name={withNameSpace(nameSpace, fields.DATE_OF_ISSUE)} readOnly={readOnly}
                 required/>
    </Col>
    <Col lg={6} sm={12}>
      <FastField component={TextField} fullWidth label={'Date of expiry'}
                 mask={'99/99/9999'}
                 name={withNameSpace(nameSpace, fields.DATE_OF_EXPIRY)} readOnly={readOnly}
                 required/>
    </Col>
    <Col lg={6} sm={12}>
      <FastField component={TextField} fullWidth key={readOnly} label={'Country of issue'}
                 name={withNameSpace(nameSpace, fields.COUNTRY_OF_ISSUE)}
                 readOnly={readOnly} required/>
    </Col>
  </>
);

export default PassportDocumentForm;
export {
  initialValues as PassportDocumentFormInitialValues,
  schema as PassportDocumentFormSchema,
  fields as PassportDocumentFormFields,
};
