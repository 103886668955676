import { Col, TextField } from 'capitalroadkit';
import { Field } from 'formik';
import React from 'react';
import * as Yup from 'yup';

const fields = {
  USERNAME: 'username',
  PASSWORD: 'password',
};

const initialValues = {
  [fields.USERNAME]: '',
  [fields.PASSWORD]: '',
};

const schema = Yup.object({
  [fields.USERNAME]: Yup.string()
    .required('Username is required'),
  [fields.PASSWORD]: Yup.string()
    .required('Password is required'),
});

const LoginForm = () => (
  <>
    <Col sm={12}>
      <Field component={TextField} fullWidth label={'Username'} name={fields.USERNAME} required/>
    </Col>
    <Col sm={12}>
      <Field component={TextField} fullWidth label={'Password'} name={fields.PASSWORD} required type={'password'}/>
    </Col>
  </>
);

export default LoginForm;
export { initialValues as LoginFormInitialValues, schema as LoginFormSchema };
