import { faPencil, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Card,
  CardActions,
  CardBody,
  CardHeader,
  CardTitle,
  Checkbox,
  Col,
  Grid,
  IconButton, Modal,
} from 'capitalroadkit';
import React, { useEffect, useState } from 'react';

import {
  resumeInterestPaymentAxios, resumePrincipalPaymentAxios,
  suspendInterestPaymentAxios,
  suspendPrincipalPaymentAxios,
} from '../../../api/NoteResource';
import useAPI from '../../../hooks/useAPI';

const SuspensionsModal = ({ getNote, note }) => {
  const [open, setOpen] = useState(false);

  const suspendInterestPaymentAPI = useAPI(
    suspendInterestPaymentAxios(note.uuid),
    false,
  );

  useEffect(() => {
    if (!suspendInterestPaymentAPI.loading && suspendInterestPaymentAPI.response) {
      getNote();
    }
  }, [suspendInterestPaymentAPI.response, suspendInterestPaymentAPI.loading]);

  const resumeInterestPaymentAPI = useAPI(
    resumeInterestPaymentAxios(note.uuid),
    false,
  );

  useEffect(() => {
    if (!resumeInterestPaymentAPI.loading && resumeInterestPaymentAPI.response) {
      getNote();
    }
  }, [resumeInterestPaymentAPI.response, resumeInterestPaymentAPI.loading]);

  const suspendPrincipalPaymentAPI = useAPI(
    suspendPrincipalPaymentAxios(note.uuid),
    false,
  );

  useEffect(() => {
    if (!suspendPrincipalPaymentAPI.loading && suspendPrincipalPaymentAPI.response) {
      getNote();
    }
  }, [suspendPrincipalPaymentAPI.response, suspendPrincipalPaymentAPI.loading]);

  const resumePrincipalPaymentAPI = useAPI(
    resumePrincipalPaymentAxios(note.uuid),
    false,
  );

  useEffect(() => {
    if (!resumePrincipalPaymentAPI.loading && resumePrincipalPaymentAPI.response) {
      getNote();
    }
  }, [resumePrincipalPaymentAPI.response, resumePrincipalPaymentAPI.loading]);

  const suspensionsHandler = (e, value) => {
    if (e.target.name === 'suspendInterestPayments') {
      if (value) suspendInterestPaymentAPI.fetch();
      else resumeInterestPaymentAPI.fetch();
    }

    if (e.target.name === 'suspendPrincipalPayments') {
      if (value) suspendPrincipalPaymentAPI.fetch();
      else resumePrincipalPaymentAPI.fetch();
    }
  };

  return (
    <>
      <FontAwesomeIcon
        icon={faPencil}
        onClick={() => setOpen(true)}
        style={{ cursor: 'pointer', fontSize: '1rem', marginLeft: '.75rem' }}
      />
      <Modal open={open}>
        <Card style={{ textAlign: 'left' }}>
          <CardHeader>
            <CardTitle>Suspensions</CardTitle>
            <CardActions>
              <IconButton icon={faTimes} onClick={() => setOpen(false)}/>
            </CardActions>
          </CardHeader>
          <CardBody>
            <Grid nested>
              <Col sm={12}>
                <Checkbox
                  field={{
                    name: 'suspendInterestPayments',
                    onChange: suspensionsHandler,
                    value: note.interestPaymentSuspended,
                  }}
                  label={'Suspend Interest Payments'}
                />
              </Col>
              <Col sm={12}>
                <Checkbox
                  field={{
                    name: 'suspendPrincipalPayments',
                    onChange: suspensionsHandler,
                    value: note.principalPaymentSuspended,
                  }}
                  label={'Suspend Principal Payments'}
                />
              </Col>
            </Grid>
          </CardBody>
        </Card>
      </Modal>
    </>
  );
};

export default SuspensionsModal;
