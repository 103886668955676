import MenuItem from '@material-ui/core/MenuItem';
import {Button, Card, CardBody, Col, Grid, HorizontalWizard, Page, PageHeader, Step, TextField,} from 'capitalroadkit';
import {FastField, FieldArray, Form, FormikProvider, useFormik,} from 'formik';
import React, {useEffect, useState} from 'react';
import * as Yup from 'yup';

import CompanyForm, {CompanyInitialValues, CompanySchema} from './CompanyForm';
import IndividualForm, {IndividualFormInitialValues, IndividualFormSchema} from './IndividualForm';
import {JointIndividualFormInitialValues, JointIndividualFormSchema} from './JointIndividualForm';
import PartyAttributesForm, {PartyAttribuesSchema} from './PartyAttributesForm';
import {PartySummary} from './PartySummary';
import {AustralianLicenseFormSchema} from './SubForm/AustralianDriversLicenseForm';
import BankAccountForm, {BankAccountFormSchema} from './SubForm/BankAccountForm';
import {CompanyTaxSchema} from './SubForm/CompanyTaxInformationForm';
import {PassportDocumentFormSchema} from './SubForm/PassportDocumentForm';
import {TrustInitialValues, TrustSchema} from './TrustForm';

import {createParty} from '../../api/PartyResource';
import Error from '../../components/Error/Error';
import {parseEnum, toAPIDate} from '../../formatters';
import {PartyFormatter} from '../../formatters/PartyFormatter';
import useAPI from '../../hooks/useAPI';

const NewParty = ({ user }) => {
  const [wizard, setWizard] = useState(-1);

  const submitPartyApi = useAPI(createParty(), false);

  const [party, setParty] = useState(null);

  const moveNext = () => setWizard(wizard + 1);

  useEffect(() => {
    if (submitPartyApi.error) {

    }
  }, [submitPartyApi.error]);

  const partyForm = useFormik({
    initialValues: { partyType: '' },
    onSubmit: (values) => {
      const updatedParty = party;
      updatedParty.type = values.partyType;

      setParty(updatedParty);
      TrustForm.resetForm();
      setWizard(0);
    },
  });

  const individualForm = useFormik({
    initialValues: IndividualFormInitialValues,
    onSubmit: async (values) => {
      let updatedParty = party;

      updatedParty = {
        ...updatedParty,
        ...values,
        dateOfBirth: toAPIDate(values.dateOfBirth),
        contactDetails: cleanContactDetailsObject(values.contactDetails),
        taxInformationDetails: cleanTaxInformationDetails(values.taxInformationDetails),
      };

      if (!updatedParty.middleName) {
        delete updatedParty.middleName;
      }

      delete updatedParty.primaryDocumentType;

      updatedParty.identityDocuments = await validateIdentityDocuments(values.identityDocuments, true);

      setParty(updatedParty);
      moveNext();
      individualForm.setSubmitting(false);
    },
    validationSchema: IndividualFormSchema,
  });

  const submitBankDetailsForIndividual = () => {
    try {
      const validatedBankDetails = individualForm.values.bankAccount;
      BankAccountFormSchema.validate(validatedBankDetails);

      setParty({
        ...party,
        bankAccount: validatedBankDetails,
      });
      moveNext();
    } catch (e) {
      console.log(e);
      individualForm.submitForm();
    }
  };

  const jointIndividualForm = useFormik({
    initialValues: JointIndividualFormInitialValues,
    onSubmit: (values) => {
    },
    validationSchema: JointIndividualFormSchema,
  });

  const TrustForm = useFormik({
    initialValues: TrustInitialValues(partyForm.values.trustType, partyForm.values.numberOfTrustees),
    onSubmit: (values) => {
    },
    validationSchema: TrustSchema(partyForm.values.trustType, partyForm.values.numberOfTrustees),
  });

  const CompanyFormikForm = useFormik({
    initialValues: CompanyInitialValues,
    onSubmit: (values) => {
    },
    validationSchema: CompanySchema,
  });

  const currentForm = () => {
    switch (party?.type) {
      case 'INDIVIDUAL':
        return individualForm;
      case 'JOINT_INDIVIDUAL':
        return jointIndividualForm;
      case 'TRUST':
        return TrustForm;
      case 'COMPANY':
        return CompanyFormikForm;
      default:
        return null;
    }
  };

  const submitCompanyTaxInformation = async () => {
    const validatedCompanyTax = currentForm().values.australianCompanyTaxInformation;

    await CompanyTaxSchema.validate(validatedCompanyTax);
    return validatedCompanyTax;
  };

  const submitPartyAttributesForm = async () => {
    try {
      const partyAttributesObject = {
        name: currentForm().values.name,
        bankAccount: currentForm().values.bankAccount,
        contactDetails: cleanContactDetailsObject(currentForm().values.contactDetails),
      };

      await Yup.object(PartyAttribuesSchema).validate(partyAttributesObject);

      const finalPartyObj = {
        ...party,
        name: partyAttributesObject.name,
        contactDetails: partyAttributesObject.contactDetails,
        bankAccount: partyAttributesObject.bankAccount,
      };

      if (party.type === 'COMPANY') {
        finalPartyObj.australianCompanyTaxInformation = await submitCompanyTaxInformation();
      }

      setParty(finalPartyObj);
      moveNext();
    } catch (e) {
      console.log(e);
      currentForm().submitForm();
    }
  };

  const cleanContactDetailsObject = (contactDetails) => {
    delete contactDetails.separatePostalAddress;

    if (!contactDetails.landLine?.number) {
      delete contactDetails.landLine;
    }

    if (!contactDetails.postalAddress?.line1) {
      delete contactDetails.postalAddress;
    }

    if (!contactDetails.webAddress?.url) {
      delete contactDetails.webAddress;
    }

    return contactDetails;
  };

  const prepLinkedIndividualsForSubmission = async (linkedParties) => {
    for (let i = 0; i < linkedParties.length; i += 1) {
      if (linkedParties[i].party.dateOfBirth) {
        linkedParties[i].party.dateOfBirth = toAPIDate(linkedParties[i].party.dateOfBirth);
        if (linkedParties[i].party.identityDocuments) {
          linkedParties[i].party.identityDocuments = await validateIdentityDocuments(linkedParties[i].party.identityDocuments, true);
        }
      }
    }
    return linkedParties;
  };

  const validateIdentityDocuments = async (documents, format) => {
    for (let i = 0; i < documents.length; i += 1) {
      if (documents[i].type === 'PASSPORT') {
        await PassportDocumentFormSchema.validate(documents[i]);
        if (format) {
          documents[i].dateOfExpiry = toAPIDate(documents[i].dateOfExpiry);
          documents[i].dateOfIssue = toAPIDate(documents[i].dateOfIssue);
        }
      } else if (documents[i].type === 'AUSTRALIAN_DRIVERS_LICENSE') {
        await AustralianLicenseFormSchema.validate(documents[i]);
        if (format) {
          documents[i].dateOfExpiry = toAPIDate(documents[i].dateOfExpiry);
          documents[i].dateOfIssue = toAPIDate(documents[i].dateOfIssue);
        }
      }
    }

    return documents;
  };

  const cleanTaxInformationDetails = (taxInfo) => {
    if (!taxInfo.australianTaxResident) {
      delete taxInfo.australianTaxInformation;
    } else {
      if (taxInfo.australianTaxInformation.taxFileNumber) {
        delete taxInfo.australianTaxInformation.tfnExemptionCode;
      } else {
        delete taxInfo.australianTaxInformation.taxFileNumber;
      }
    }
    return taxInfo;
  };

  const submitIndividualLinkedParty = async (index) => {
    try {
      const validatedParty = {
        party: {
          ...currentForm().values.linkedParties[index].party,
          contactDetails: cleanContactDetailsObject(currentForm().values.linkedParties[index].party.contactDetails),
          taxInformationDetails: cleanTaxInformationDetails(currentForm().values.linkedParties[index].party.taxInformationDetails),
        },
        role: currentForm().initialValues.linkedParties[index].role,
      };

      delete validatedParty.party.primaryDocumentType;

      await IndividualFormSchema.validate(validatedParty.party);

      validatedParty.party.identityDocuments = await validateIdentityDocuments(validatedParty.party.identityDocuments);

      if (validatedParty.taxInformationDetails) {
        validatedParty.taxInformationDetails = cleanTaxInformationDetails(validatedParty.taxInformationDetails);
      }

      updatePartyWithLinkedParty(index, validatedParty);
      moveNext();
    } catch (e) {
      console.log(e);
      await currentForm().submitForm();
    }
  };

  const updatePartyWithLinkedParty = (index, linkedParty) => {
    const updatedParty = party;

    if (!updatedParty.linkedParties) {
      updatedParty.linkedParties = [];
    }
    if (updatedParty.linkedParties[index]) {
      updatedParty.linkedParties[index] = linkedParty;
    } else {
      updatedParty.linkedParties.push(linkedParty);
    }
    setParty(updatedParty);
  };

  const getPartyAttributesTitle = () => {
    switch (party?.type) {
      case 'JOINT_INDIVIDUAL':
        return 'Account Details';
      case 'TRUST':
        return 'Trust Details';
      case 'COMPANY':
        return 'Company Details';
      default:
        return '';
    }
  };

  useEffect(() => {
    if (partyForm.values.partyType) {
      setParty({ type: partyForm.values.partyType });
    }
  }, [partyForm.values.partyType]);

  const finishAndSubmitParty = async () => {
    const finalParty = { ...party };

    if (finalParty.linkedParties) {
      finalParty.linkedParties = await prepLinkedIndividualsForSubmission(finalParty.linkedParties);
    }
    submitPartyApi.fetch({}, finalParty);
  };

  const createNextBackButtons = (form, goNextFunction) => <Col sm={12} style={{ textAlign: 'right' }}>
    <Button
      color={'danger'}
      disabled={form.isSubmitting}
      loading={form.isSubmitting}
      onClick={() => setWizard(wizard === -1 ? -1 : wizard - 1)}
      type={'button'}
    >
      Back
    </Button>
    <Button
      color={'primary'}
      disabled={form.isSubmitting}
      loading={form.isSubmitting}
      onClick={goNextFunction}
      type={'button'}
    >
      Next
    </Button>
  </Col>;

  if (submitPartyApi.response) {
    return (
      <div>
        <h4>Client succesfully created</h4>
        <Col sm={12}>
          <PartyOnboardingBreakDown party={party}/>
        </Col>
      </div>
    );
  }
  return (
    <Page id={'newClient'}>
      <Grid>
        <PageHeader
          heading={'New Client'}
          subHeading={user.name}
        />
        <Col sm={12}>
          <Card>
            <CardBody>
              {wizard === -1
              && <FormikProvider value={partyForm}>
                <Form>
                  <Grid>
                    <Col sm={6}>
                      <CardBody>
                        <CardBody nested>
                          <Grid nested>
                            <Col sm={12}>
                              <h5>Party type</h5>
                            </Col>
                            <Col sm={3}>
                              <FastField
                                component={TextField}
                                fullWidth
                                label={'Type'}
                                name={'partyType'}
                                required
                                select
                              >
                                {PartyFormatter.getPartyTypes()
                                  .map((title) => (
                                    <MenuItem key={title.value}
                                              value={title.value}>{title.label}</MenuItem>
                                  ))}
                              </FastField>
                            </Col>
                            {partyForm.values.partyType === 'TRUST' && <Col sm={3}>
                              <FastField
                                component={TextField}
                                fullWidth
                                label={'Trust type'}
                                name={'trustType'}
                                required
                                select
                              >
                                <MenuItem key={'INDIVIDUAL'}
                                          value={'INDIVIDUAL'}>Individual</MenuItem>
                                <MenuItem disabled
                                          key={'CORPORATE'}
                                          value={'CORPORATE'}>Corporate</MenuItem>
                              </FastField>
                            </Col>}
                            {(partyForm.values.partyType === 'TRUST' && partyForm.values.trustType)
                            && <Col sm={3}>
                              <FastField
                                component={TextField}
                                fullWidth
                                label={'Number of trustees'}
                                name={'numberOfTrustees'}
                                required
                                select
                              >
                                <MenuItem key={1}
                                          value={1}>1</MenuItem>
                                <MenuItem key={2}
                                          value={2}>2</MenuItem>
                                <MenuItem key={3}
                                          value={3}>3</MenuItem>
                                <MenuItem key={4}
                                          value={4}>4</MenuItem>
                              </FastField>
                            </Col>
                            }
                          </Grid>
                        </CardBody>
                      </CardBody>
                      <Col sm={12} style={{ textAlign: 'right' }}>
                        <Button
                          color={'primary'}
                          disabled={!party?.type || (partyForm.values.partyType === 'TRUST' && (!partyForm.values.trustType || !partyForm.values.numberOfTrustees))}
                          style={{ margin: '.5rem' }}
                          type={'submit'}
                        >
                          Next
                        </Button>
                      </Col>
                    </Col>
                    <Col sm={6}>
                      <PartyOnboardingBreakDown party={party}/>
                    </Col>
                  </Grid>
                </Form>
              </FormikProvider>
              }
              <FormikProvider
                enableReinitialize
                value={currentForm() || individualForm}>
                <Form>
                  {wizard >= 0
                  && <FieldArray
                    name={'linkedParties'}
                    render={(arrayHelpers) => (
                      <HorizontalWizard active={wizard}>
                        {/* FINAL CORRESPONDENCE */}
                        {party?.type !== 'INDIVIDUAL'
                        && <Step name={getPartyAttributesTitle()}>
                          <Grid>
                            <Col sm={6}>
                              <PartyAttributesForm form={currentForm()}/>
                              {party?.type === 'COMPANY' && <CompanyForm form={currentForm()}/>}
                              {createNextBackButtons(currentForm(), submitPartyAttributesForm)}
                            </Col>
                            <Col sm={6}>
                              <PartyOnboardingBreakDown party={party}/>
                            </Col>
                          </Grid>
                        </Step>}

                        {party?.type === 'INDIVIDUAL'
                        && <Step name={'Individual Details'}>
                          <Grid>
                            <Col sm={6}>
                              <CardBody>
                                <IndividualForm
                                  form={individualForm}
                                  party={party}/>
                              </CardBody>
                              {createNextBackButtons(individualForm, individualForm.submitForm)}
                            </Col>
                            <Col sm={6}>
                              <PartyOnboardingBreakDown party={party}/>
                            </Col>
                          </Grid>
                        </Step>
                        }
                        {party?.type === 'INDIVIDUAL'
                        && <Step name={'Bank Details'}>
                          <Grid>
                            <Col sm={6}>
                              <CardBody>
                                <CardBody nested>
                                  <Grid nested>
                                <BankAccountForm
                                  form={individualForm}
                                  nameSpace={'bankAccount'}
                                  party={party}/>
                                  </Grid>
                                </CardBody>
                              </CardBody>
                              {createNextBackButtons(individualForm, submitBankDetailsForIndividual)}
                            </Col>
                            <Col sm={6}>
                              <PartyOnboardingBreakDown party={party}/>
                            </Col>
                          </Grid>
                        </Step>
                        }
                        {party?.type === 'JOINT_INDIVIDUAL'
                        && jointIndividualForm.values.linkedParties.map((linkedParty, index) => <Step
                          name={`Details - Individual ${index + 1}`}>
                          <Grid>
                            <Col sm={6}>
                              <CardBody>
                                <IndividualForm
                                  form={jointIndividualForm}
                                  nameSpace={`linkedParties.${index}.party`}/>
                              </CardBody>
                              {createNextBackButtons(TrustForm, () => submitIndividualLinkedParty(index))}
                            </Col>
                            <Col sm={6}>
                              <PartyOnboardingBreakDown party={party}/>
                            </Col>
                          </Grid>
                        </Step>)}
                        {party?.type === 'TRUST'
                        && TrustForm.values.linkedParties.map((linkedParty, index) => <Step
                          name={`Trustee ${index + 1} Details`}>
                          <Grid>
                            <Col sm={6}>
                              <CardBody>
                                {partyForm.values.trustType === 'INDIVIDUAL'
                                  ? <IndividualForm
                                    form={TrustForm}
                                    nameSpace={`linkedParties.${index}.party`}/>
                                  : <CompanyFormikForm
                                    form={TrustForm}
                                    nameSpace={`linkedParties.${index}.party`}/>
                                }
                              </CardBody>
                              {createNextBackButtons(TrustForm, () => submitIndividualLinkedParty(index))}
                            </Col>
                            <Col sm={6}>
                              <PartyOnboardingBreakDown party={party}/>
                            </Col>
                          </Grid>
                        </Step>)
                        }
                        {(party?.type === 'TRUST' && partyForm.values.trustType === 'CORPORATE')
                        && TrustForm.values.linkedParties[0].party.linkedParties.map((linkedParty, index) => <Step
                          name={`Company Director ${index + 1} Details`}>
                          <Grid>
                            <Col sm={6}>
                              <CardBody>
                                <IndividualForm
                                  form={TrustForm}
                                  nameSpace={`linkedParties.${index}.party.linkedParties.${index}.party`}/>
                              </CardBody>
                              {createNextBackButtons(TrustForm, () => submitIndividualLinkedParty(index))}
                            </Col>
                            <Col sm={6}>
                              <PartyOnboardingBreakDown party={party}/>
                            </Col>
                          </Grid>
                        </Step>)
                        }
                        {/* {party?.type === 'COMPANY' */}
                        {/* && <Step name={'Company Tax Information'}> */}
                        {/*  <Grid> */}
                        {/*    <Col sm={6}> */}
                        {/*      <CardBody> */}
                        {/*        <CompanyForm */}
                        {/*          form={CompanyFormikForm}/> */}

                        {/*      </CardBody> */}
                        {/*      {createNextBackButtons(TrustForm, () => submitCompanyTaxInformation())} */}
                        {/*    </Col> */}
                        {/*    <Col sm={6}> */}
                        {/*      <PartyOnboardingBreakDown party={party}/> */}
                        {/*    </Col> */}
                        {/*  </Grid> */}
                        {/* </Step> */}
                        {/* } */}
                        {party?.type === 'COMPANY'
                        && CompanyFormikForm.values.linkedParties.map((linkedParty, index) => <Step
                          name={`Company Director ${index + 1} Details`}>
                          <Grid>
                            <Col sm={6}>
                              <CardBody>
                                <IndividualForm
                                  form={CompanyFormikForm}
                                  nameSpace={`linkedParties.${index}.party`}/>
                              </CardBody>
                              {createNextBackButtons(TrustForm, () => submitIndividualLinkedParty(index))}
                            </Col>
                            <Col sm={6}>
                              <PartyOnboardingBreakDown party={party}/>
                            </Col>
                          </Grid>
                        </Step>)
                        }

                        <Step name={'Review'}>
                          <Col sm={6}>
                            <PartyOnboardingBreakDown party={party}/>
                            <Col sm={12} style={{ textAlign: 'right' }}>
                              <Button
                                color={'danger'}
                                loading={submitPartyApi.loading}
                                name={'submitPartyBack'}
                                onClick={() => setWizard(wizard === 0 ? 0 : wizard - 1)}
                                type={'button'}
                              >
                                Back
                              </Button>
                              <Button
                                color={'success'}
                                loading={submitPartyApi.loading}
                                name={'submitPartyFinish'}
                                onClick={finishAndSubmitParty}
                                style={{ margin: '.5rem' }}
                                type={'button'}
                              >
                                Submit
                              </Button>
                            </Col>
                          </Col>
                        </Step>
                      </HorizontalWizard>
                    )}>
                  </FieldArray>
                  }
                </Form>
              </FormikProvider>
          {(submitPartyApi.error?.data.errorViolations || submitPartyApi.error?.data.errorDescription)
          && <Error error={submitPartyApi.error?.data}/>}
            </CardBody>
          </Card>
        </Col>
      </Grid>
    </Page>
  );
};

const PartyOnboardingBreakDown = ({ party }) => (
  <CardBody>
    {party && <CardBody nested>
      <Grid nested>
        <Col sm={12}>
          <p>Account details</p>
          <p><small>{parseEnum(party.type)}</small></p>

        </Col>
        <PartySummary
          party={party}
          role={'ACCOUNT'}/>
        {party?.linkedParties && <Col sm={12}>
          <p>Clients</p>
        { party.linkedParties.map((p) => <PartySummary
          party={p.party}
          role={p.role}/>)}
        </Col>}
      </Grid>
    </CardBody>}
  </CardBody>
);

export default NewParty;
export { PartyOnboardingBreakDown };
