import { Table } from 'capitalroadkit';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router';

import { parseBoolean, toCurrency, toDateString } from '../../formatters';

const ProductsTable = ({
  data, selected, productManager,
}) => {
  const history = useHistory();
  const columns = useMemo(() => [
    {
      id: 'notes',
      Header: '',
      Footer: '',
      columns: [
        {
          Header: 'uuid',
          accessor: 'uuid',
        },
        {
          Header: 'Code',
          accessor: 'code',
        },
        {
          Header: 'Name',
          accessor: 'name',
        },
        {
          Header: 'Active',
          accessor: 'active',
          Cell: ({ cell: { value } }) => parseBoolean(value),
        },
        {
          Header: 'Start date',
          accessor: 'startDate',
          Cell: ({ cell: { value } }) => toDateString(value),
        },
        {
          Header: 'Min. investment',
          accessor: 'valueOfMinimumInvestment.value',
          Cell: ({ cell: { value } }) => toCurrency(value),
        },
      ],
    },
  ], []);

  return (
    <Table
      columns={columns}
      data={data}
      dataKey={'uuid'}
      hiddenColumns={['uuid']}
      rowClick={(row) => history.push(`/secure/products/${row.values.uuid}/update`)}
      selected={selected}
      width={ '80rem' }
    />
  );
};

export default ProductsTable;
