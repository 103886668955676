import {
  Button, CardBody, Col, Grid, useTheme,
} from 'capitalroadkit';
import React from 'react';
import { useHistory } from 'react-router';
import * as Yup from 'yup';

import ProductContactDetailsForm, { ProductContactDetailsFormInitialValues } from './NewProduct/ProductContactDetailsForm';
import ProductDefinitionForm, { ProductDefinitionFormInitialValues, ProductDefinitionFormSchema } from './NewProduct/ProductDefinitionForm';
import ProductDetailsForm, {
  ProductDetailsFormInitialValues,
  ProductDetailsFormSchema,
} from './NewProduct/ProductDetailsForm';

import BankAccountForm, { BankAccountFormInitialValues, BankAccountFormSchema } from '../NewParty/SubForm/BankAccountForm';
import { ContactDetailsFormSchema } from '../NewParty/SubForm/ContactDetailsForm';

const fields = {
  DETAILS: 'details',
  DEFINITION: 'definition',
  CONTACT_DETAILS: 'contactDetails',
  BANK_ACCOUNT: 'bankAccount',
};

const initialValues = (product) => ({
  [fields.DETAILS]: ProductDetailsFormInitialValues(product),
  [fields.DEFINITION]: ProductDefinitionFormInitialValues(product),
  [fields.CONTACT_DETAILS]: ProductContactDetailsFormInitialValues(product?.contactDetails),
  [fields.BANK_ACCOUNT]: BankAccountFormInitialValues(product?.bankAccount),
});

const schema = Yup.object({
  [fields.DETAILS]: ProductDetailsFormSchema,
  [fields.DEFINITION]: ProductDefinitionFormSchema,
  [fields.CONTACT_DETAILS]: ContactDetailsFormSchema,
  [fields.BANK_ACCOUNT]: BankAccountFormSchema,
});

const ProductForm = ({
  form, product, readOnly, errorViolations, loading, editing, setEditing,
  updateDetails, updateDefinition, updateBankAccount, updateContactDetails,
}) => {
  const theme = useTheme();
  const history = useHistory();
  return (
    <Grid nested>
      <Col lg={6} sm={12}>
        <Grid nested>
          <Col sm={12}>
            <CardBody nested style={{ height: '100%' }}>
              <Grid nested>
                <Col sm={product && !readOnly ? 6 : 12}><h5>Product details</h5></Col>
                {product && !readOnly
                && editButtons(editing, 'productDetails', loading, setEditing, updateDetails)}
                <ProductDetailsForm editing={editing} nameSpace={fields.DETAILS} product={product} readOnly={readOnly}/>
                {errorViolations && editing === 'productDetails' && errorViolations.map((error) => (
                  <Col style={{ textAlign: 'right' }}>
                    <p style={{ color: theme.palette.danger.main, marginTop: '.5rem' }}>
                      {error.name}: {error.value}
                    </p>
                  </Col>
                ))}
              </Grid>
            </CardBody>
          </Col>
          <Col sm={12}>
            <CardBody nested style={{ height: '100%' }}>
              <Grid nested>
                <Col sm={product && !readOnly ? 6 : 12}><h5>Product definition</h5></Col>
                {product && !readOnly
                && editButtons(editing, 'productDefinition', loading, setEditing, updateDefinition)}
                <ProductDefinitionForm editing={editing} form={form} nameSpace={fields.DEFINITION}
                                       product={product} readOnly={readOnly}/>
                {errorViolations && editing === 'productDefinition' && errorViolations.map((error) => (
                  <Col style={{ textAlign: 'right' }}>
                    <p style={{ color: theme.palette.danger.main, marginTop: '.5rem' }}>
                      {error.name}: {error.value}
                    </p>
                  </Col>
                ))}
              </Grid>
            </CardBody>
          </Col>
          <Col sm={12}>
            <CardBody nested>
              <Grid nested>
                <Col sm={product && !readOnly ? 6 : 12}><h5>Bank account</h5></Col>
                {product && !readOnly
                && editButtons(editing, 'bankAccount', loading, setEditing, updateBankAccount)}
                <BankAccountForm
                  form={form}
                  nameSpace={fields.BANK_ACCOUNT}
                  readOnly={(!!product && editing !== 'bankAccount') || readOnly}/>
                {errorViolations && editing === 'bankAccount' && errorViolations.map((error) => (
                  <Col style={{ textAlign: 'right' }}>
                    <p style={{ color: theme.palette.danger.main, marginTop: '.5rem' }}>
                      {error.name}: {error.value}
                    </p>
                  </Col>
                ))}
              </Grid>
            </CardBody>
          </Col>
        </Grid>
      </Col>
      <Col lg={6} sm={12}>
        <CardBody nested style={{ height: '100%' }}>
          <Grid nested>
            <Col sm={product && !readOnly ? 6 : 12}><h5>Contact details</h5></Col>
            {product && !readOnly
            && editButtons(editing, 'contactDetails', loading, setEditing, updateContactDetails)}
            <ProductContactDetailsForm
              form={form}
              nameSpace={fields.CONTACT_DETAILS}
              readOnly={(!!product && editing !== 'contactDetails') || readOnly}/>
            {errorViolations && editing === 'contactDetails' && errorViolations.map((error) => (
              <Col style={{ textAlign: 'right' }}>
                <p style={{ color: theme.palette.danger.main, marginTop: '.5rem' }}>
                  {error.name}: {error.value}
                </p>
              </Col>
            ))}
          </Grid>
        </CardBody>
      </Col>
      {!readOnly && !product
      && <Col lg={product ? 6 : 12} style={{ textAlign: 'right' }}>
        {errorViolations && errorViolations.map((error) => (
          <p style={{ color: theme.palette.danger.main, marginTop: '.5rem' }}>
            {error.name}: {error.value}
          </p>
        ))}
        <Button
          color={'danger'} loading={loading} name={'submit-product'}
          onClick={() => history.push('/secure/products')} style={{ marginLeft: '.5rem' }}
          type={'button'}>
          Cancel
        </Button>
        <Button
          color={'success'} loading={loading} name={'submit-product'}
          style={{ marginLeft: '.5rem' }} type={'submit'}>
          Save
        </Button>
      </Col>
      }
    </Grid>
  );
};

const editButtons = (editing, editType, loading, setEditing, updateFunction) => (
  <Col sm={6} style={{ textAlign: 'right' }}>
    {editing === editType
      ? <>
        <Button
          color={'danger'} loading={loading} name={'cancel-edit'}
          onClick={() => setEditing(false)} type={'button'}>
          Cancel
        </Button>
        <Button
          color={'success'} loading={loading} name={'submit-product'}
          onClick={() => updateFunction()}
          style={{ marginLeft: '.5rem' }} type={'button'}>
          Save
        </Button>
      </>
      : <Button
        color={'primary'} loading={loading} name={'edit-product'}
        onClick={() => setEditing(editType)} type={'button'}>
        Edit
      </Button>}
  </Col>
);

export default ProductForm;
export {
  schema as ProductFormSchema,
  initialValues as ProductFormInitialValues,
};
