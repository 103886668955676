import { Col, TextField } from 'capitalroadkit';
import { FastField } from 'formik';
import React from 'react';
import * as Yup from 'yup';

import { withNameSpace } from '../../../functions';

const fields = {
  ACCOUNT_NAME: 'accountName',
  BSB: 'bsbNumber',
  ACCOUNT_NUMBER: 'accountNumber',
};

const initialValues = (bankAccount) => ({
  [fields.ACCOUNT_NAME]: bankAccount?.accountName || '',
  [fields.BSB]: bankAccount?.bsbNumber || '',
  [fields.ACCOUNT_NUMBER]: bankAccount?.accountNumber || '',
});

const schema = Yup.object({
  [fields.ACCOUNT_NAME]: Yup.string()
    .required('Account name is required'),
  [fields.BSB]: Yup.string()
    .matches(/^[0-9]+$/, 'BSB must be a valid bank state branch')
    .length(6, 'BSB must be 6 digits long')
    .required('BSB is required'),
  [fields.ACCOUNT_NUMBER]: Yup.number()
    .typeError('Account number must be a valid account number')
    .required('Account Number is required'),
});

const BankAccountForm = ({ nameSpace, readOnly }) => (
  <>
    <Col sm={12}>
      <FastField component={TextField} fullWidth key={readOnly} label={'Name'}
                 name={withNameSpace(nameSpace, fields.ACCOUNT_NAME)}
                 readOnly={readOnly} required/>
    </Col>
    <Col lg={6} sm={12}>
      <FastField component={TextField} fullWidth key={readOnly} label={'BSB'} name={withNameSpace(nameSpace, fields.BSB)}
                 readOnly={readOnly} required/>
    </Col>
    <Col lg={6} sm={12}>
      <FastField component={TextField} fullWidth key={readOnly} label={'Account Number'}
                 name={withNameSpace(nameSpace, fields.ACCOUNT_NUMBER)} readOnly={readOnly} required/>
    </Col>
  </>
);

export default BankAccountForm;
export { initialValues as BankAccountFormInitialValues, schema as BankAccountFormSchema, fields as BankAccountFormFields };
