import {
  Col, Radio, RadioGroup, TextField,
} from 'capitalroadkit';
import { Field } from 'formik';
import React from 'react';
import * as Yup from 'yup';

export const Type = Object.freeze({
  Interest: 'INTEREST',
  Principal: 'PRINCIPAL',
});

const fields = {
  TYPE: 'type',
  DATE: 'date',
  AMOUNT: 'amount',
  SETTLEMENT_DATE: 'settlementDate',
};

const initialValues = {
  [fields.TYPE]: Type.Interest,
  [fields.DATE]: '',
  [fields.AMOUNT]: '',
  [fields.SETTLEMENT_DATE]: '',
};

const schema = Yup.object({
  [fields.TYPE]: Yup.mixed()
    .oneOf(Object.values(Type)),
  [fields.DATE]: Yup.string(),
  [fields.AMOUNT]: Yup.number()
    .typeError('Payment amount must be a number')
    .positive('Payment amount must be positive')
    .required('Payment amount is required'),
  [fields.SETTLEMENT_DATE]: Yup.string(),
});

const NotePaymentForm = () => (
  <>
    <Col sm={12}>
      <Field component={RadioGroup} label={'Type'} name={fields.TYPE} row>
        {Object.entries(Type)
          .map((type) => (
            <Radio key={type[1]} value={type[1]}>{type[0]}</Radio>
          ))
        }
      </Field>
    </Col>
    <Col sm={12}>
      <Field component={TextField} label={'Date'} mask={'99/99/9999'} name={fields.DATE}/>
    </Col>
    <Col sm={12}>
      <Field component={TextField} label={'Amount'} name={fields.AMOUNT}/>
    </Col>
    <Col sm={12}>
      <Field component={TextField} label={'Settlement Date'} mask={'99/99/9999'} name={fields.SETTLEMENT_DATE}/>
    </Col>
  </>
);

export default NotePaymentForm;
export { initialValues as NotePaymentFormInitialValues, schema as NotePaymentFormSchema };
