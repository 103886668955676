import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import {
  Checkbox, Col, TextField,
} from 'capitalroadkit';
import { Field } from 'formik';
import React from 'react';
import * as Yup from 'yup';

import { withNameSpace } from '../../../functions';

const fields = {
  MINIMUM_VALUE: 'minimumValue',
  CURRENCY: 'currency',
  INTEREST_RATE_OVERRIDE_NOT_PERMITTED: 'interestRateOverrideNotPermitted',
  EARLY_REDEMPTION_PENALTY_RATE: 'earlyRedemptionPenaltyRate',
  INTEREST_RATE_CALCULATION_DENOMINATOR_OVERRIDE: 'interestRateCalculationDenominatorOverride',
  INTEREST_RATE_CALCULATION_DENOMINATOR: 'interestRateCalculationDenominator',
};

const initialValues = (product) => ({
  [fields.MINIMUM_VALUE]: product?.valueOfMinimumInvestment.value,
  [fields.CURRENCY]: 'AUD',
  [fields.INTEREST_RATE_OVERRIDE_NOT_PERMITTED]: product?.interestRateOverrideNotPermitted,
  [fields.EARLY_REDEMPTION_PENALTY_RATE]: product?.earlyRedemptionPenaltyRate,
  [fields.INTEREST_RATE_CALCULATION_DENOMINATOR_OVERRIDE]:
  product?.interestRateCalculationDenominatorOverride,
  [fields.INTEREST_RATE_CALCULATION_DENOMINATOR]:
    product?.interestRateCalculationDenominator
      ? product?.interestRateCalculationDenominator
      : '365',
});

const schema = Yup.object({
  [fields.MINIMUM_VALUE]: Yup.number()
    .required('Minimum investment value is required'),
  [fields.CURRENCY]: Yup.mixed()
    .oneOf(['AUD'])
    .required('Currency is required'),
  [fields.INTEREST_RATE_OVERRIDE_NOT_PERMITTED]: Yup.bool(),
  [fields.EARLY_REDEMPTION_PENALTY_RATE]: Yup.number()
    .required('Early redemption fee required'),
  [fields.INTEREST_RATE_CALCULATION_DENOMINATOR_OVERRIDE]: Yup.bool(),
  [fields.INTEREST_RATE_CALCULATION_DENOMINATOR]: Yup.number()
    .min(1)
    .max(365),
});

const ProductDefinitionForm = ({
  nameSpace, readOnly, product, editing, form,
}) => (
  <>
    <Col lg={6} sm={12}>
      <Field component={TextField} fullWidth label={'Currency'}
             name={withNameSpace(nameSpace, fields.CURRENCY)}
             readOnly={!!product || readOnly} required select>
        {[{ value: 'AUD', label: 'AUD' }].map(
          (currency) => <MenuItem
            key={currency.value}
            value={currency.value}>
            {currency.label}
          </MenuItem>,
        )}
      </Field>
    </Col>
    <Col lg={6} sm={12}>
      <Field
        component={TextField} fullWidth label={'Min. investment'}
        name={withNameSpace(nameSpace, fields.MINIMUM_VALUE)}
        readOnly={!!product || readOnly}
        required startAdornment={'$'}/>
    </Col>
    <Col lg={6} sm={12}>
      <Field
        component={Checkbox} fullWidth label={'Override interest rate not applicable?'}
        name={withNameSpace(nameSpace, fields.INTEREST_RATE_OVERRIDE_NOT_PERMITTED)}
        readOnly={(!!product && editing !== 'productDefinition') || readOnly}/>
    </Col>
    <Col lg={6} sm={12}>
      <Field
        component={TextField} fullWidth label={'Early redemption fee (%)'}
        name={withNameSpace(nameSpace, fields.EARLY_REDEMPTION_PENALTY_RATE)}
        readOnly={(!!product && editing !== 'productDefinition') || readOnly}
        required/>
    </Col>
    <Col lg={6} sm={12}>
      <Field
        component={Checkbox} fullWidth label={interestDaysOverrideTooltip()}
        name={withNameSpace(nameSpace, fields.INTEREST_RATE_CALCULATION_DENOMINATOR_OVERRIDE)}
        readOnly={!!product || readOnly}/>
    </Col>
    {form.values.definition?.interestRateCalculationDenominatorOverride
    && <Col lg={6} sm={12}>
      <Field
        component={TextField} fullWidth label={'Interest days per year'}
        name={withNameSpace(nameSpace, fields.INTEREST_RATE_CALCULATION_DENOMINATOR)}
        readOnly={!!product || readOnly} required/>
    </Col>
    }
  </>
);

const interestDaysOverrideTooltip = () => (
  <Tooltip
    arrow={'top'}
    title={'Default number of days in a year, used for interest calculations is equal '
    + 'to 365 days. The interest calculation also caters for a leap year where the '
    + 'number of days in a year is equal to 366 days. If the Product is required to be '
    + 'less than 365 and/or ignore a leap year please override the ‘Interest days per '
    + 'year’ and enter the required number of days to be used for interest calculations.'}>
    <p>Override interest days per year <FontAwesomeIcon icon={faQuestionCircle}/></p>
  </Tooltip>
);

export default ProductDefinitionForm;
export {
  initialValues as ProductDefinitionFormInitialValues,
  schema as ProductDefinitionFormSchema,
  fields as ProductDefinitionFormFields,
};
