import { Col, TextField } from 'capitalroadkit';
import { Field } from 'formik';
import React from 'react';
import * as Yup from 'yup';

const fields = {
  USERNAME: 'username',
  NEW_PASSWORD: 'newPassword',
  CONFIRM_PASSWORD: 'confirmPassword',
};

const initialValues = {
  [fields.USERNAME]: '',
  [fields.NEW_PASSWORD]: '',
  [fields.CONFIRM_PASSWORD]: '',
};

const schema = Yup.object({
  [fields.USERNAME]: Yup.string()
    .required('Username is required'),
  [fields.NEW_PASSWORD]: Yup.string()
    .matches(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=*!])(?=\S+$).{8,}$/,
      {
        message: 'Must be 8 characters or longer and contain at least one capital letter, number, and special character',
        excludeEmptyString: true,
      })
    .required('New password is required'),
  [fields.CONFIRM_PASSWORD]: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], 'Must match new password')
    .required('Confirmation password is required'),
});

const ResetPasswordForm = () => (
  <>
    <Col sm={12}>
      <Field component={TextField} fullWidth label={'Username'} name={fields.USERNAME} required/>
    </Col>
    <Col sm={12}>
      <Field component={TextField} fullWidth label={'New password'} name={fields.NEW_PASSWORD} required type={'password'}/>
    </Col>
    <Col sm={12}>
      <Field component={TextField} fullWidth label={'Confirm password'} name={fields.CONFIRM_PASSWORD} required type={'password'}/>
    </Col>
  </>
);

export default ResetPasswordForm;
export {
  initialValues as ResetPasswordFormInitialValues,
  schema as ResetPasswordFormSchema,
};
