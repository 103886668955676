import {CardBody, Col, Grid, TextField,} from 'capitalroadkit';
import {FastField} from 'formik';
import React from 'react';
import * as Yup from 'yup';

import {withNameSpace} from '../../../functions';

const fields = {
  ABN: 'australianBusinessNumber',
  ACN: 'australianCompanyNumber',
};

const initialValues = {
  [fields.ABN]: '',
  [fields.ACN]: '',
};

const schema = Yup.object({
  [fields.ABN]: Yup.string()
    .matches(/^(\d *?){11}$/, 'ABN must be valid')
    .required('ABN is required'),
  [fields.ACN]: Yup.string()
    .matches(/[0-9]$/, 'ACN must be valid')
    .required('ACN is required'),
});

const CompanyTaxForm = ({ form, nameSpace, readOnly }) => (
    <CardBody>
      <CardBody nested>
        <Grid nested>
          <Col sm={12}>
            <h5>Company Tax Details</h5>
          </Col>
          <Col sm={12}>
            <FastField component={TextField} fullWidth label={'ABN'}
                       name={withNameSpace(nameSpace, fields.ABN)}
                       readOnly={readOnly} required

            />
          </Col>
          <Col sm={12}>
            <FastField component={TextField} fullWidth label={'ACN'}
                       name={withNameSpace(nameSpace, fields.ACN)}
                       readOnly={readOnly} required
                       mask={'999 999 999'}
            />
          </Col>
        </Grid>
      </CardBody>
    </CardBody>
);

export default CompanyTaxForm;

export { initialValues as CompanyTaxInitialValues, schema as CompanyTaxSchema };
