import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import {
  Card, CardActions, CardHeader, CardTitle, Col, Grid, IconButton, Modal, Page, PageHeader,
} from 'capitalroadkit';
import { Form, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';

import BankFileModal from './BankFileModal';
import BankFileTable from './BankFileTable';
import NoteTransactionsTable from './NoteTransactionsTable';
import NoteTransactionSummaryTable from './NoteTransactionSummaryTable';

import { getURLAxios, listBankFilesAxios, processBankFileAxios } from '../../api/BankFileResource';
import {
  getNoteTransactionSummaryAxios,
  searchNoteTransactionsAxios,
  updateNoteTransactionSettlementDateAxios,
} from '../../api/NoteTransactionResource';
import Loading from '../../components/Loading/Loading';
import { toAPIDate, toDateString } from '../../formatters';
import useAPI from '../../hooks/useAPI';

const Banking = () => {
  const [date, setDate] = useState(null);

  useEffect(() => {
    if (date) searchNoteTransactionsAPI.fetch({ cash: true, date });
  }, [date]);

  const listDebitBankFilesAPI = useAPI(listBankFilesAxios(), true, { type: 'DIRECT_DEBIT' });
  const getNoteTransactionSummaryAPI = useAPI(
    getNoteTransactionSummaryAxios(),
    true,
    { cash: true },
  );
  const searchNoteTransactionsAPI = useAPI(searchNoteTransactionsAxios(), false);

  const [transactionUuid, setTransactionUuid] = useState(null);

  useEffect(() => {
    if (transactionUuid) {
      for (const transaction of searchNoteTransactionsAPI.response.data) {
        if (transaction.uuid === transactionUuid) {
          transactionForm.setFieldValue('settlementDate', toDateString(transaction.settlementDate));
          break;
        }
      }
    }
  }, [transactionUuid]);

  const updateNoteTransactionSettlementDateAPI = useAPI(
    updateNoteTransactionSettlementDateAxios(transactionUuid),
    false,
  );

  useEffect(() => {
    if (!updateNoteTransactionSettlementDateAPI.loading
      && updateNoteTransactionSettlementDateAPI.response) {
      setTransactionUuid(null);
      getNoteTransactionSummaryAPI.fetch(getNoteTransactionSummaryAPI.lastParams);
      searchNoteTransactionsAPI.fetch(searchNoteTransactionsAPI.lastParams);
    }
  }, [updateNoteTransactionSettlementDateAPI.response,
    updateNoteTransactionSettlementDateAPI.loading]);

  const transactionForm = useFormik({
    initialValues: {
      settlementDate: '',
    },
    onSubmit: (values) => {
      updateNoteTransactionSettlementDateAPI.fetch(
        {}, { settlementDate: toAPIDate(values.settlementDate) },
      );
    },
  });

  const [uuid, setUuid] = useState(null);

  useEffect(() => {
    if (uuid) getURLAPI.fetch();
  }, [uuid]);

  const getURLAPI = useAPI(getURLAxios(uuid), false);

  useEffect(() => {
    if (getURLAPI.response && !getURLAPI.loading) {
      window.location = getURLAPI.response.data;
      setUuid(null);
    }
  }, [getURLAPI.response, getURLAPI.loading]);

  const [processUuid, setProcessUuid] = useState(null);

  useEffect(() => {
    if (processUuid) processBankFileAPI.fetch();
  }, [processUuid]);

  const processBankFileAPI = useAPI(processBankFileAxios(processUuid), false);

  useEffect(() => {
    if (processBankFileAPI.response && !processBankFileAPI.loading) {
      listDebitBankFilesAPI.fetch();
      setProcessUuid(null);
    }
  }, [processBankFileAPI.response, processBankFileAPI.loading]);

  const loading = getNoteTransactionSummaryAPI.loading && listDebitBankFilesAPI.loading;
  const response = getNoteTransactionSummaryAPI.response && listDebitBankFilesAPI.response;

  if (response) {
    return (
      <Page id={'banking'}>
        <Grid>
          <PageHeader
            heading={'Banking'}
          />
          <Col sm={12}>
            <Card>
              <CardHeader>
                <CardTitle>Withdrawal Processing</CardTitle>
                <CardActions>
                  <BankFileModal
                    fetchBankFiles={
                      () => listDebitBankFilesAPI.fetch(listDebitBankFilesAPI.lastParams)
                    }
                    type={'DIRECT_DEBIT'}
                  />
                </CardActions>
              </CardHeader>
              <BankFileTable
                api={{
                  limit: listDebitBankFilesAPI.response.data.limit,
                  offset: listDebitBankFilesAPI.response.data.offset,
                  totalNumberOfRecords: listDebitBankFilesAPI.response.data.totalNumberOfRecords,
                  fetch: listDebitBankFilesAPI.fetch,
                  params: listDebitBankFilesAPI.lastParams,
                }}
                data={listDebitBankFilesAPI.response.data.list}
                download={(values) => setUuid(values.uuid)}
                process={(values) => setProcessUuid(values.uuid)}
                processing={processBankFileAPI.loading}
              />
            </Card>
          </Col>
          <Col sm={12}>
            <Card>
              <CardHeader><CardTitle>Transaction Summary</CardTitle></CardHeader>
              <NoteTransactionSummaryTable
                data={getNoteTransactionSummaryAPI.response.data}
                rowClick={(row) => setDate(row.values.date)}
              />
            </Card>
          </Col>
          <Modal open={date} size={'xl'}>
            <Card>
              {searchNoteTransactionsAPI.loading
                ? <Loading size={'2x'}/>
                : searchNoteTransactionsAPI.response
                && <>
                  <CardHeader>
                    <CardTitle>{toDateString(date)} Transactions</CardTitle>
                    <CardActions>
                      <IconButton icon={faTimes} onClick={() => setDate(null)}/>
                    </CardActions>
                  </CardHeader>
                  <FormikProvider value={transactionForm}>
                    <Form>
                      <NoteTransactionsTable
                        data={searchNoteTransactionsAPI.response.data}
                        edit={setTransactionUuid}
                        editable={transactionUuid}
                        form={transactionForm}
                        loading={updateNoteTransactionSettlementDateAPI.loading}
                      />
                    </Form>
                  </FormikProvider>
                </>
              }
            </Card>
          </Modal>
        </Grid>
      </Page>
    );
  }
  if (loading) return <Loading size={'3x'}/>;
  return null;
};

export default Banking;
