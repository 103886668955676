import {
  Button, CardBody, Col, Grid, TextField,
} from 'capitalroadkit';
import { FastField, Formik } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import { updateIndividualPartyAxios } from '../../../api/PartyResource';
import useAPI from '../../../hooks/useAPI';

const EditBasicDetailsIndividualForm = ({ party }) => {
  const updateIndividualPartyAPI = useAPI(updateIndividualPartyAxios(party.uuid));
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  useEffect(() => {
    const response = updateIndividualPartyAPI.response || updateIndividualPartyAPI.response;
    const loading = updateIndividualPartyAPI.loading || updateIndividualPartyAPI.loading;

    if (!loading && response) {
      enqueueSnackbar('Client was successfully updated');
      history.push(`/secure/clients/${party.uuid}`);
    }
  }, [updateIndividualPartyAPI.response, updateIndividualPartyAPI.loading,
    updateIndividualPartyAPI.response, updateIndividualPartyAPI.loading]);
  const handleOnSubmit = (values, { setSubmitting, setValues }) => {
    updateIndividualPartyAPI.fetch({}, values);
    party.name = values.name;
    party.title = values.title;
    party.firstName = values.firstName;
    party.lastName = values.lastName;
    party.dateOfBirth = values.dateOfBirth;
    setValues(party);
    setSubmitting(false);
  };
  return (
    <>
      <Formik
        initialValues={{
          name: party.name,
          title: party.title,
          firstName: party.firstName,
          lastName: party.lastName,
          dateOfBirth: party.dateOfBirth,
          gender: party.gender,
          type: 'INDIVIDUAL',
        }}
        onSubmit={handleOnSubmit}
      >
        {({ errors, touched, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Grid nested>
              <Col sm={12}>
                <CardBody nested>
                  <Grid nested>
                    <Col lg={2} sm={12}>
                      <FastField component={TextField} fullWidth label={'Title'}
                                 name='title' required/>
                      {errors.title && touched.title && errors.title}
                    </Col>
                    <Col lg={5} sm={12}>
                      <FastField component={TextField} fullWidth label={'First name'}
                                 name='firstName' required/>
                      {errors.firstName && touched.firstName && errors.firstName}
                    </Col>
                    <Col lg={5} sm={12}>
                      <FastField component={TextField} fullWidth label={'Last name'}
                                 name='lastName' required/>
                      {errors.lastName && touched.lastName && errors.lastName}
                    </Col>
                    <Col lg={2} sm={12}>
                      <FastField component={TextField} fullWidth label={'Date of birth'}
                                 name='dateOfBirth' required/>
                      {errors.dateOfBirth && touched.dateOfBirth && errors.dateOfBirth}
                    </Col>
                    <Col lg={2} sm={12}>
                      <FastField component={TextField} fullWidth label={'Gender'}
                                 name='gender' required/>
                      {errors.gender && touched.gender && errors.gender}
                    </Col>
                  </Grid>
                </CardBody>
              </Col>
              <Col style={{ textAlign: 'right' }}>
                <Link to={`/secure/clients/${party.uuid}`}>
                  <Button color={'danger'}>Cancel</Button>
                </Link>
                <Button color={'success'} style={{ marginLeft: '.5rem' }} type={'submit'}>
                  Submit
                </Button>
              </Col>
            </Grid>
          </form>
        )}
      </Formik>
    </>
  );
};

export default EditBasicDetailsIndividualForm;
