import {Button, CardBody, Col, Grid} from 'capitalroadkit';
import React from 'react';

import {
  parseBoolean, toCurrency, toDateString, toPercent,
} from '../../formatters';

const ProductSummary = ({ product }) => (
  <Grid nested>
    <Col lg={4} sm={12}>
      <Grid nested style={{ height: '100%' }}>
        <Col style={{ height: '100%' }}>
          <CardBody nested style={{ height: '100%' }}>
            <Grid nested>
              <Col><h5>Product details</h5></Col>
              <Col>
                <table
                  style={{
                    borderCollapse: 'collapse', tableLayout: 'fixed', textAlign: 'left', width: '100%',
                  }}
                >
                  <tbody>
                  <tr>
                    <th>Product ID</th>
                    <td>{product.code}</td>
                  </tr>
                  <tr>
                    <th>Name</th>
                    <td>{product.name}</td>
                  </tr>
                  <tr>
                    <th>Effective date</th>
                    <td>{toDateString(product.startDate)}</td>
                  </tr>
                  <tr>
                    <th>End date</th>
                    <td>{product.endDate ? toDateString(product.endDate) : ''}</td>
                  </tr>
                  <tr>
                    <th>Issuer</th>
                    <td>{product.issuer || ''}</td>
                  </tr>
                  <tr>
                    <th>Manager name</th>
                    <td>{product.managerName}</td>
                  </tr>
                  <tr>
                    <th>Product closed</th>
                    <td>{parseBoolean(product.closed)}</td>
                  </tr>
                  </tbody>
                </table>
              </Col>
            </Grid>
          </CardBody>
        </Col>
      </Grid>
    </Col>
    <Col lg={4} sm={12}>
      <Grid nested style={{ height: '100%' }}>
        <Col style={{ height: '100%' }}>
          <CardBody nested style={{ height: '100%' }}>
            <Grid nested>
              <Col><h5>Product definition</h5></Col>
              <Col>
                <table
                  style={{
                    borderCollapse: 'collapse', tableLayout: 'fixed', textAlign: 'left', width: '100%',
                  }}
                >
                  <tbody>
                    <tr>
                      <th>Currency</th>
                      <td>{product.valueOfMinimumInvestment.currency}</td>
                    </tr>
                    <tr>
                      <th>Minimum investment</th>
                      <td>{toCurrency(product.valueOfMinimumInvestment.value)}</td>
                    </tr>
                    <tr>
                      <th>Override interest rate not applicable</th>
                      <td>{parseBoolean(product.interestRateOverrideNotPermitted)}</td>
                    </tr>
                    <tr>
                      <th>Early redemption fee</th>
                      <td>{toPercent(product.earlyRedemptionPenaltyRate)}</td>
                    </tr>
                    <tr>
                      <th>Override interest days per year</th>
                      <td>{parseBoolean(product.interestRateCalculationDenominatorOverride)}</td>
                    </tr>
                    {product.interestRateCalculationDenominatorOverride
                    && <tr>
                      <th>Interest days per year</th>
                      <td>{product.interestRateCalculationDenominator}</td>
                    </tr>
                    }
                  </tbody>
                </table>
              </Col>
            </Grid>
          </CardBody>
        </Col>
      </Grid>
    </Col>
    <Col lg={4} sm={12}>
      <Grid nested style={{ height: '100%' }}>
        <Col style={{ height: '100%' }}>
          <CardBody nested style={{ height: '100%' }}>
            <Grid nested>
              <Col><h5>Bank details</h5></Col>
              <Col>
                <table
                  style={{
                    borderCollapse: 'collapse', tableLayout: 'fixed', textAlign: 'left', width: '100%',
                  }}
                >
                  <tbody>
                  <tr>
                    <th>Account name</th>
                    <td>{product.bankAccount?.accountName}</td>
                  </tr>
                  <tr>
                    <th>BSB</th>
                    <td>{product.bankAccount?.bsbNumber}</td>
                  </tr>
                  <tr>
                    <th>Account number</th>
                    <td>{product.bankAccount?.accountNumber}</td>
                  </tr>
                  </tbody>
                </table>
              </Col>
              <Col><h5>Contact details</h5></Col>
              <Col>
                <table
                  style={{
                    borderCollapse: 'collapse', tableLayout: 'fixed', textAlign: 'left', width: '100%',
                  }}
                >
                  <tbody>
                  <tr>
                    <th>Email</th>
                    <td>{product.contactDetails?.emailAddress.address}</td>
                  </tr>
                  <tr>
                    <th>Web address</th>
                    <td>{product.contactDetails?.webAddress.url}</td>
                  </tr>
                  <tr>
                    <th>Phone</th>
                    {product.contactDetails?.mobile.number
                    && <td>{`${product.contactDetails.mobile.countryCode} ${product.contactDetails.mobile.number}`}</td>
                    }
                  </tr>
                  <tr>
                    <th>Address</th>
                    {product.contactDetails?.address
                    && <td>{`${product.contactDetails.address.line1}, 
                        ${product.contactDetails.address.city} 
                        ${product.contactDetails.address.state}
                        ${product.contactDetails.address.country}
                        ${product.contactDetails.address.postcode}`}
                    </td>}
                  </tr>
                  </tbody>
                </table>
              </Col>
            </Grid>
          </CardBody>
        </Col>
      </Grid>
    </Col>
  </Grid>
);

export default ProductSummary;
