import Collapse from '@material-ui/core/Collapse';
import MenuItem from '@material-ui/core/MenuItem';
import {Checkbox, Col, Grid, TextField,} from 'capitalroadkit';
import {FastField} from 'formik';
import React, {useEffect} from 'react';
import * as Yup from 'yup';

import {PartyFormatter} from '../../../formatters/PartyFormatter';
import {resolveProperty, withNameSpace} from '../../../functions';

const fields = {
  AUSTRALIAN_TAX_RESIDENT: 'australianTaxResident',
  AUSTRALIAN_TAX_INFORMATION: 'australianTaxInformation',
};

const initialValues = {
  [fields.AUSTRALIAN_TAX_RESIDENT]: false,
  [fields.AUSTRALIAN_TAX_INFORMATION]: {
    taxFileNumber: '',
    tfnExemptionCode: '',
  },
};

const schema = Yup.object({
  [fields.AUSTRALIAN_TAX_RESIDENT]: Yup.boolean(),
  [fields.AUSTRALIAN_TAX_INFORMATION]: Yup.object()
    .when('australianTaxResident', {
      is: true,
      then: Yup.object()
        .shape({
          taxFileNumber: Yup.string()
            .when('tfnExemptionCode', {
              is: (tfnExemptionCode) => !tfnExemptionCode,
              then: Yup.string()
                .matches(/^[0-9]+$/, 'TFN must be a valid tax file number')
                .min(8, 'TFN must be a minimum of 8 digits')
                .max(9, 'TFN must be a maximum of 9 digits')
                .required('Either TFN of TFN Exemption Rule is required'),
              otherwise: Yup.string(),
            }),
          tfnExemptionCode: Yup.mixed()
            .when('taxFileNumber', {
              is: (taxFileNumber) => !taxFileNumber,
              then: Yup.mixed()
                .oneOf(['', ...PartyFormatter.getTFNExemptionTypes()
                  .map((reason) => reason.value)])
                .required('Either TFN of TFN Exemption Rule is required'),
              otherwise: Yup.mixed(),
            }),
        }, [['tfnExemptionCode', 'taxFileNumber'], ['taxFileNumber', 'tfnExemptionCode']]),
      otherwise: Yup.object(),
    }),
});

const TaxInformationForm = ({ form, nameSpace, readOnly }) => {
  useEffect(() => {
    if (resolveProperty(withNameSpace(nameSpace, 'australianTaxInformation.tfnExemptionCode'), form.values) !== ''
      && resolveProperty(withNameSpace(nameSpace, 'australianTaxInformation.taxFileNumber'), form.values) !== '') {
      form.setFieldValue(withNameSpace(nameSpace, 'australianTaxInformation.tfnExemptionCode'), '');
    }
  }, [form.values]);

  useEffect(() => {
    if (resolveProperty(withNameSpace(nameSpace, 'australianTaxInformation.tfnExemptionCode'), form.values) !== ''
      && resolveProperty(withNameSpace(nameSpace, 'australianTaxInformation.taxFileNumber'), form.values) !== '') {
      form.setFieldValue(withNameSpace(nameSpace, 'australianTaxInformation.taxFileNumber'), '');
    }
  }, [form.values]);

  return (
    <Grid nested>
        <Col sm={4}>
            <FastField component={Checkbox} label={'Australian Resident for Tax Purposes?'}
                       name={withNameSpace(nameSpace, fields.AUSTRALIAN_TAX_RESIDENT)}
                       readOnly={readOnly}/>
        </Col>
        <Col sm={8}/>
        <Col sm={12}>
            <Collapse in={resolveProperty(withNameSpace(nameSpace, fields.AUSTRALIAN_TAX_RESIDENT), form.values)}>
                <Grid nested>
                    <Col sm={6}>
                        <FastField component={TextField} fullWidth label={'TFN'}
                                   name={withNameSpace(nameSpace, 'australianTaxInformation.taxFileNumber')}
                                   readOnly={readOnly}/>
                    </Col>
                    <Col sm={6}>
                        <FastField component={TextField} fullWidth label={'TFN Exemption Reason'}
                                   name={withNameSpace(nameSpace, 'australianTaxInformation.tfnExemptionCode')}
                                   readOnly={readOnly}
                                   select>
                            <MenuItem value={''}><em>None</em></MenuItem>
                            {PartyFormatter.getTFNExemptionTypes()
                              .map((reason) => <MenuItem key={reason.value}
                                                           value={reason.value}>{reason.label}</MenuItem>)}
                        </FastField>
                    </Col>
                </Grid>
            </Collapse>
        </Col>
    </Grid>
  );
};

export default TaxInformationForm;
export { initialValues as TaxInformationFormInitialValues, schema as TaxInformationFormSchema };
