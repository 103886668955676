import {
  Button, Card, CardActions, CardBody, CardHeader, CardTitle, Col, Grid, Page, PageHeader,
} from 'capitalroadkit';
import React from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

import { getPartyByUuidAxios, getPartyDocumentsAxios, getPartyNotesAxios } from '../../../api/PartyResource';
import Loading from '../../../components/Loading/Loading';
import { toCurrency } from '../../../formatters';
import useAPI from '../../../hooks/useAPI';
import NoteDocumentsTable from '../../Notes/Note/NoteDocumentsTable';
import NotesTable from '../../Notes/NotesTable';

const Client = () => {
  const params = useParams();

  const getPartyFromAPI = useAPI(getPartyByUuidAxios(params.uuid));
  const getPartyDocumentsAPI = useAPI(getPartyDocumentsAxios(params.uuid));

  const getPartyNotesAPI = useAPI(getPartyNotesAxios(params.uuid));

  const getTotalNoteBalance = () => {
    let total = 0;
    if (getPartyNotesAPI.response.data[0]) {
      for (const note of getPartyNotesAPI.response.data) {
        total += note.balance.value;
      }
    }
    return total;
  };

  if (getPartyFromAPI.loading || getPartyNotesAPI.loading) return <Loading size={'3x'}/>;
  if (getPartyFromAPI.response && getPartyNotesAPI.response) {
    return (
      <Page id={'client'}>
        <Grid>
          <PageHeader
            heading={getPartyFromAPI.response.data.name}
            subHeading={'Client'}>
            <span>Total account value: {toCurrency(getTotalNoteBalance())}</span>
          </PageHeader>
          <Col lg={getPartyDocumentsAPI.response?.data ? 8 : 12} sm={12}>
            <Card>
              <CardHeader>
                <CardTitle>Client details</CardTitle>
                <CardActions>
                  <Link to={`/secure/clients/${params.uuid}/update`}>
                    <Button color={'primary'} variant={'text'}>Edit details</Button>
                  </Link>
                </CardActions>
              </CardHeader>
              <CardBody>
                <Grid nested>
                  <Col lg={6} sm={12}>
                    <CardBody height={'100%'} nested>
                      <Grid nested>
                        <Col><h5>Contact details</h5></Col>
                      </Grid>
                      <table style={{ borderCollapse: 'collapse', textAlign: 'left', width: '100%' }}>
                        <tbody>
                        <tr>
                          <th>Email</th>
                          {getPartyFromAPI.response.data.contactDetails.emailAddress
                          && <td>{getPartyFromAPI.response.data.contactDetails.emailAddress.address}</td>
                          }
                        </tr>
                        <tr>
                          <th>Address</th>
                          {getPartyFromAPI.response.data.contactDetails.address
                          && <td>{`${getPartyFromAPI.response.data.contactDetails.address.line1} 
                            ${getPartyFromAPI.response.data.contactDetails.address.city} 
                            ${getPartyFromAPI.response.data.contactDetails.address.state} 
                            ${getPartyFromAPI.response.data.contactDetails.address.country} 
                            ${getPartyFromAPI.response.data.contactDetails.address.postcode}`}</td>}
                        </tr>
                        <tr>
                          <th>Landline</th>
                          {getPartyFromAPI.response.data.contactDetails.landLine
                          && <td>{getPartyFromAPI.response.data.contactDetails.landLine.countryCode}
                            {getPartyFromAPI.response.data.contactDetails.landLine.number}</td>
                          }
                        </tr>
                        <tr>
                          <th>Mobile</th>
                          {getPartyFromAPI.response.data.contactDetails.mobile
                          && <td>{getPartyFromAPI.response.data.contactDetails.mobile.countryCode}
                          {getPartyFromAPI.response.data.contactDetails.mobile.number}</td>
                          }
                          </tr>
                        </tbody>
                      </table>
                    </CardBody>
                  </Col>
                  <Col lg={6} sm={12}>
                    <CardBody height={'100%'} nested>
                      <Grid nested>
                        <Col><h5>Bank details</h5></Col>
                      </Grid>
                      <table style={{ borderCollapse: 'collapse', textAlign: 'left', width: '100%' }}>
                        <tbody>
                        <tr>
                          <th>Account name</th>
                          <td>{getPartyFromAPI.response.data.bankAccount.accountName}</td>
                        </tr>
                        <tr>
                          <th>Bsb</th>
                          <td>{getPartyFromAPI.response.data.bankAccount.bsbNumber}</td>
                        </tr>
                        <tr>
                          <th>Account number</th>
                          <td>{getPartyFromAPI.response.data.bankAccount.accountNumber}</td>
                        </tr>
                        </tbody>
                      </table>
                    </CardBody>
                  </Col>
                </Grid>
              </CardBody>
            </Card>
          </Col>
          {getPartyDocumentsAPI.response?.data
          && <Col lg={4} sm={12}>
            <Card>
              <CardHeader><CardTitle>Documents</CardTitle></CardHeader>
              <NoteDocumentsTable data={getPartyDocumentsAPI.response?.data}/>
            </Card>
          </Col>}
          {getPartyNotesAPI.response.data[0]
          && <Col sm={12}>
            <Card>
              <CardHeader><CardTitle>Notes</CardTitle></CardHeader>
              <NotesTable data={getPartyNotesAPI.response.data} party/>
              <CardBody>
                <Grid nested>
                  <Col style={{ textAlign: 'right' }}>
                    <p>Total account value: {toCurrency(getTotalNoteBalance())}</p>
                  </Col>
                </Grid>
              </CardBody>
            </Card>
          </Col>}
        </Grid>
      </Page>
    );
  }
  return null;
};

export default Client;
