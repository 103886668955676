import { faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table, useTheme } from 'capitalroadkit';
import React, { useMemo } from 'react';

import { parseBoolean, toPercent } from '../../../formatters';

const RateTable = ({ data, remove }) => {
  const theme = useTheme();
  const columns = useMemo(() => [
    {
      id: 'rateTable',
      Header: '',
      Footer: '',
      columns: [
        {
          Header: 'Duration (months)',
          accessor: 'duration',
        },
        {
          Header: 'Interest rate',
          accessor: 'interestRate',
          Cell: ({ cell: { value } }) => toPercent(value),
        },
        {
          id: 'actions',
          Cell: ({ row }) => (
            <FontAwesomeIcon
              icon={faMinusCircle}
              style={{
                cursor: 'pointer',
                fontSize: '1rem',
                color: theme.palette.danger.main,
              }}
            />
          ),
          headerStyle: {
            width: '2.5rem',
          },
        },
      ],
    },
  ], []);

  const columns2 = useMemo(() => [
    {
      id: 'rateTable',
      Header: '',
      Footer: '',
      columns: [
        {
          Header: 'Duration (months)',
          accessor: 'duration',
        },
        {
          Header: 'Interest rate',
          accessor: 'interestRate',
          Cell: ({ cell: { value } }) => toPercent(value),
        },
      ],
    },
  ], []);

  return (
    <Table
      columns={remove ? columns : columns2}
      data={data}
      dataKey={'duration'}
      rowClick={remove ? (row) => remove(row) : null}
      sortBy={[{ id: 'duration', desc: false }]}
    />
  );
};

export default RateTable;
