import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import { faPencil, faSave, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table } from 'capitalroadkit';
import { Field } from 'formik';
import React, { useMemo } from 'react';

import TableInput from '../../components/TableInput/TableInput';
import { parseEnum, toCurrency, toDateString } from '../../formatters';
import {Link} from "react-router-dom";

const NoteTransactionsTable = ({
  data, edit, editable, form, loading,
}) => {
  const columns = useMemo(() => [
    {
      id: 'transactions',
      Header: '',
      Footer: '',
      columns: [
        {
          Header: 'uuid',
          accessor: 'uuid',
        },
        {
          Header: 'noteUuid',
          accessor: 'noteUuid',
        },
        {
          Header: 'Name',
          accessor: 'noteReferenceId',
          Cell: ({ cell: { value }, row: { values } }) => <Link to={`/secure/notes/${values.noteUuid}`}>{value}</Link>,
        },
        {
          Header: 'Product',
          accessor: 'productName',
        },
        {
          Header: 'Date',
          accessor: 'transactionDate',
          Cell: ({ cell: { value } }) => toDateString(value),
        },
        {
          Header: 'Settlement Date',
          accessor: 'settlementDate',
          style: editable ? { padding: '0' } : null,
          Cell: ({ cell: { value }, row: { values } }) => (editable === values.uuid
            ? <Field component={TableInput} name={'settlementDate'}/>
            : toDateString(value)
          ),
        },
        {
          Header: 'Status',
          accessor: 'settlementStatus',
          Cell: ({ cell: { value } }) => parseEnum(value),
        },
        {
          Header: 'Type',
          accessor: 'type',
          Cell: ({ cell: { value } }) => parseEnum(value),
        },
        {
          Header: 'Amount',
          accessor: 'transactionAmount.value',
          Cell: ({ cell: { value } }) => toCurrency(value),
        },
        {
          id: 'actions',
          headerStyle: { width: '4.5rem' },
          Cell: ({ row: { values } }) => (editable === values.uuid
            ? loading
              ? <FontAwesomeIcon icon={faSpinnerThird} style={{ fontSize: '1rem', width: '1rem' }}/>
              : <>
                  <FontAwesomeIcon
                    icon={faSave}
                    onClick={() => form.submitForm()}
                    style={{ cursor: 'pointer', fontSize: '1rem', width: '1rem' }}
                  />
                  <FontAwesomeIcon
                    icon={faTimes}
                    onClick={() => edit(null)}
                    style={{
                      cursor: 'pointer', fontSize: '1rem', marginLeft: '.5rem', width: '1rem',
                    }}
                  />
                </>
            : values.settlementStatus === 'PENDING'
            && <FontAwesomeIcon
              icon={faPencil}
              onClick={() => edit(values.uuid)}
              style={{ cursor: 'pointer', fontSize: '1rem', width: '1rem' }}
            />
          ),
        },
      ],
    },
  ], [editable]);

  return (
    <Table
      columns={columns}
      data={data}
      dataKey={'uuid'}
      hiddenColumns={['uuid', 'noteUuid']}
      sortBy={[
        {
          id: 'settlementStatus',
        },
      ]}
    />
  );
};

export default NoteTransactionsTable;
