import {
  Button,
  Card, CardBody, CardHeader, CardTitle,
  Col, Grid, Page, PageHeader,
} from 'capitalroadkit';
import React, { useEffect, useState } from 'react';

import ReportsTable from './ReportsTable';

import { generateCurrentInvestmentsReportAxios, generateMaturedNotesReportAxios, generateNotesInterestPaymentsFlagReportAxios, generateNotesPrincipalPaymentsFlagReportAxios,
  generateNotesStatementEmailFlagReportAxios} from '../../api/NoteReportResource';
import useAPI from '../../hooks/useAPI';

const Reports = ({ user }) => {
  const [selected, setSelected] = useState(null);

  const generateReportAPI = useAPI(selected?.axios(), false);

  useEffect(() => {
    if (generateReportAPI.response) window.open(generateReportAPI.response.data.url);
  }, [generateReportAPI.response]);

  return (
    <Page id={'reports'}>
      <Grid>
        <PageHeader
          heading={'Reports'}
          subHeading={user.name}
        />
        <Col lg={selected ? 6 : 12} md={12} sm={12}>
          <Card>
            <CardHeader><CardTitle>Reports</CardTitle></CardHeader>
            <ReportsTable
              data={[
                { axios: generateCurrentInvestmentsReportAxios, name: 'Current Investments Report' },
                { axios: generateMaturedNotesReportAxios, name: 'Matured Notes Report' },
                { axios: generateNotesInterestPaymentsFlagReportAxios, name: 'Interest payments active Report' },
                { axios: generateNotesPrincipalPaymentsFlagReportAxios, name: 'Principal payments active Report' },
                { axios: generateNotesStatementEmailFlagReportAxios, name: 'Statement email suspended Report' },
              ]}
              setSelected={setSelected}
            />
          </Card>
        </Col>
        {selected
        && <Col lg={6} md={12} sm={12}>
          <Card>
            <CardHeader><CardTitle>{selected.name}</CardTitle></CardHeader>
            <CardBody>
              <Button onClick={() => generateReportAPI.fetch()} color={'primary'}>
                Generate
              </Button>
            </CardBody>
          </Card>
        </Col>
        }
      </Grid>
    </Page>
  );
};

export default Reports;
