import {
  Card,
  CardBody,
  Col,
  Grid,
  Page,
  PageHeader,
  Tab, Tabs,
} from 'capitalroadkit';
import React from 'react';
import { useParams } from 'react-router';

import EditBankAccountDetailsForm from './EditBankAccountDetailsForm';
import EditBasicDetailsIndividualForm from './EditBasicDetailsIndividualForm';
import EditBasicDetailsOrganisationForm from './EditBasicDetailsOrganisationForm';
import EditContactDetailsForm from './EditContactDetailsForm';

import {
  getPartyByUuidAxios,
} from '../../../api/PartyResource';
import Loading from '../../../components/Loading/Loading';
import useAPI from '../../../hooks/useAPI';

const Client = ({ user }) => {
  const params = useParams();

  const getPartyFromAPI = useAPI(getPartyByUuidAxios(params.uuid));

  const { loading } = getPartyFromAPI;
  const { response } = getPartyFromAPI;

  if (loading) return <Loading size={'3x'}/>;
  if (response) {
    return (
      <Page id={'party'}>
        <Grid>
          <PageHeader
            heading={getPartyFromAPI.response.data.name}
            subHeading={'Client Details'}
          />
          <Col sm={12}>
            <Card>
              <Tabs id={'party-maintenance'} vertical>
                <Tab id={'basicDetails'} key={'basicDetails'} name={'Basic details'}>
                  <CardBody>
                    {getPartyFromAPI.response.data.type === 'INDIVIDUAL'
                    && <EditBasicDetailsIndividualForm party={getPartyFromAPI.response.data}/>}
                    {getPartyFromAPI.response.data.type === 'TRUST'
                    && <EditBasicDetailsOrganisationForm party={getPartyFromAPI.response.data}/>}
                    {getPartyFromAPI.response.data.type === 'COMPANY'
                      && <EditBasicDetailsOrganisationForm party={getPartyFromAPI.response.data}/>}
                    {getPartyFromAPI.response.data.type === 'JOINT_INDIVIDUAL'
                      && <EditBasicDetailsOrganisationForm party={getPartyFromAPI.response.data}/>}
                  </CardBody>
                </Tab>
                <Tab id={'contactDetails'} key={'contactDetails'} name={'Contact details'}>
                  <CardBody>
                    <EditContactDetailsForm
                        contactDetails={(getPartyFromAPI.response.data.contactDetails)}
                        uuid={(getPartyFromAPI.response.data.uuid)}/>
                  </CardBody>
                </Tab>
                <Tab id={'bankDetails'} key={'bankDetails'} name={'Bank Account details'}>
                  <CardBody>
                    <EditBankAccountDetailsForm
                        bankAccount={(getPartyFromAPI.response.data.bankAccount)}
                        uuid={(getPartyFromAPI.response.data.uuid)}/>
                  </CardBody>
                </Tab>
              </Tabs>
            </Card>
          </Col>
        </Grid>
      </Page>
    );
  }
  return null;
};

export default Client;
