import { Col, TextField } from 'capitalroadkit';
import { Field } from 'formik';
import React from 'react';
import * as Yup from 'yup';

import { listProductsAxios } from '../../api/ProductResource';
import Autocomplete from '../../components/Autocomplete/Autocomplete';
import { withNameSpace } from '../../functions';

const fields = {
  PRODUCT: 'product',
  DATE: 'date',
};

const initialValues = {
  [fields.PRODUCT]: '',
  [fields.DATE]: '',
};

const schema = Yup.object({
  [fields.PRODUCT]: Yup.string()
    .required('Product is required'),
  [fields.DATE]: Yup.string()
    .required('Date is required'),
});

const ProductBankFileForm = ({ nameSpace }) => (
  <>
    <Col sm={12}>
      <Field
        axios={listProductsAxios}
        component={Autocomplete}
        fullWidth
        label={'Product'}
        name={withNameSpace(nameSpace, fields.PRODUCT)}
        required
      />
    </Col>
    <Col sm={12}>
      <Field
        component={TextField}
        fullWidth
        label={'Date'}
        mask={'99/99/9999'}
        name={withNameSpace(nameSpace, fields.DATE)}
        required
      />
    </Col>
  </>
);

export default ProductBankFileForm;
export {
  initialValues as ProductBankFileFormInitialValues,
  schema as ProductBankFileFormSchema,
};
