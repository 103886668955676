import React from 'react';
import * as Yup from 'yup';

import {IndividualFormInitialValues, IndividualFormSchema} from './IndividualForm';
import {PartyAttribuesSchema, PartyAttributesFields, PartyAttributesValues} from './PartyAttributesForm';
import CompanyTaxForm, {CompanyTaxInitialValues, CompanyTaxSchema} from './SubForm/CompanyTaxInformationForm';

import {withNameSpace} from '../../functions';

const fields = {
  ...PartyAttributesFields,
  AUS_COMPANY_TAX_INFO: 'australianCompanyTaxInformation',
  LINKED_PARTIES: 'linkedParties',
};

const initialValues = {
  ...PartyAttributesValues,
  [fields.AUS_COMPANY_TAX_INFO]: CompanyTaxInitialValues,
  [fields.LINKED_PARTIES]: [
    {
      party: IndividualFormInitialValues,
      role: 'DIRECTOR',
    }, {
      party: IndividualFormInitialValues,
      role: 'DIRECTOR',
    },
  ],
};

const schema = Yup.object({
  ...PartyAttribuesSchema,
  [fields.AUS_COMPANY_TAX_INFO]: CompanyTaxSchema,
  [fields.LINKED_PARTIES]: Yup.array()
    .of(IndividualFormSchema)
    .min(2, 'A minimum of 2 individuals').max(2, 'A maximum of 2 individuals'),
});

const CompanyForm = ({ form, nameSpace, readOnly }) => (
    <CompanyTaxForm
      form={form}
      nameSpace={withNameSpace(nameSpace, fields.AUS_COMPANY_TAX_INFO)}
      readOnly={readOnly}/>
);

export default CompanyForm;

export { initialValues as CompanyInitialValues, schema as CompanySchema };
