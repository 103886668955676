import { useTheme } from 'capitalroadkit';
import React from 'react';

const Error = ({ error, center }) => {
  const theme = useTheme();

  return (
    <>
      {(error.errorDescription || error.errorMessage)
      && <p style={{ color: theme.palette.danger.main, marginTop: '.5rem', textAlign: center ? 'center' : 'right' }}>
        {error.errorDescription || error.errorMessage}
      </p>
      }
      {error.errorViolations?.map((violation) => (
        <p style={{ color: theme.palette.danger.main, marginTop: '.5rem' }}>
          <strong style={{ color: theme.palette.danger.main }}>
            Error:
          </strong> {violation.value}
        </p>
      ))}
    </>
  );
};

export default Error;
