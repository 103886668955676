import { faPlus } from '@fortawesome/free-solid-svg-icons';
import {
  CardBody, CardTitle, Col, Grid,
  useTheme, Card, CardHeader, IconButton, Button,
} from 'capitalroadkit';
import {
  Form, FormikProvider, isEmptyArray, useFormik,
} from 'formik';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';

import RateTable from './RateTable';

import {
  createProductAxios,
  getProductRateTablesAxios,
  updateProductBankAccountAxios,
  updateProductContactDetailsAxios,
  updateProductDefinitionAxios,
  updateProductDetailsAxios,
} from '../../../api/ProductResource';
import { now, toAPIDate } from '../../../formatters';
import useAPI from '../../../hooks/useAPI';
import ProductForm, { ProductFormInitialValues, ProductFormSchema } from '../ProductForm';
import ProductSummary from '../ProductSummary';
import CreateRates from '../UpdateProduct/CreateRates';
import RateTables from '../UpdateProduct/RateTables';

const CreateProduct = ({
  readOnly, product, productCreated,
}) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  // RATE TABLE/S
  const [rateTables, setRateTables] = useState([]);
  const [selected, setSelected] = useState({ rates: [] });

  const getProductRateTablesAPI = useAPI(getProductRateTablesAxios(product?.uuid), !readOnly);

  const updateRateTable = (rateTable, remove) => {
    const rates = [...rateTables];
    let i = 0;
    let exists = false;
    for (const table of rates) {
      if (table.uuid === rateTable.uuid) {
        exists = true;
        if (remove) rates.splice(i, 1);
        else rates.splice(i, 1, rateTable);
      }
      if (table.uuid === '1') rates.splice(i, 1);
      i += 1;
    }
    if (!exists) {
      rates.push(rateTable);
    }
    setRateTables(rates);
    setSelected(rateTable);
  };

  useEffect(() => {
    if (!getProductRateTablesAPI.loading && getProductRateTablesAPI.response) {
      setRateTables(getProductRateTablesAPI.response.data);
    }
  }, [getProductRateTablesAPI.response, getProductRateTablesAPI.loading]);

  useEffect(() => {
    setSelected(getSelected);
  }, [rateTables]);

  // ERROR VIOLATIONS
  const [errorViolations, setErrorViolations] = useState(null);

  // CREATE PRODUCT
  const createProductAPI = useAPI(createProductAxios(), false);

  // UPDATE PRODUCT
  const [editView, setEditView] = useState(false);
  const [editing, setEditing] = useState(null);
  const [loading, setLoading] = useState(false);

  const updateProductDetailsAPI = useAPI(updateProductDetailsAxios(product?.uuid), false);
  const updateProductDefinitionAPI = useAPI(updateProductDefinitionAxios(product?.uuid), false);
  const updateProductBankAccountAPI = useAPI(updateProductBankAccountAxios(product?.uuid), false);
  const updateProductContactDetailsAPI = useAPI(updateProductContactDetailsAxios(product?.uuid), false);

  const productForm = useFormik({
    initialValues: ProductFormInitialValues(product),
    onSubmit: (values) => {
      console.log('help!');
      const req = productFormatApi(values);
      if (values.definition.interestRateCalculationDenominatorOverride
        && values.definition.interestRateCalculationDenominator === undefined) {
        setErrorViolations([{
          name: 'Denominator',
          value: 'Must provide interest calculation denominator if overriding',
        }]);
      } else {
        setErrorViolations(null);
        if (!product) {
          createProductAPI.fetch({}, req);
        }
      }
    },
    validationSchema: ProductFormSchema,
  });

  const productFormatApi = (values) => {
    const req = {
      ...values.details,
      ...values.definition,
      contactDetails: values.contactDetails,
      bankAccount: values.bankAccount,
    };
    console.log(req);
    if (req.startDate !== '') req.startDate = toAPIDate(req.startDate);
    if (req.endDate !== '') req.endDate = toAPIDate(req.endDate);
    else if (req.endDate === '') delete req.endDate;
    req.valueOfMinimumInvestment = { value: req.minimumValue, currency: req.currency };
    delete req.minimumValue;
    delete req.currency;
    const landLine = req.contactDetails?.landLine;
    if (landLine === {} || landLine?.countryCode === undefined || landLine?.number === undefined
      || landLine?.countryCode === '' || landLine?.number === '') delete req.contactDetails.landLine;
    delete req.bankAccount?.directDebitAllowed;
    return req;
  };

  const updateDetails = () => {
    const req = productFormatApi({ ...productForm.values });
    updateProductDetailsAPI.fetch({}, req);
  };

  const updateDefinition = () => {
    const req = productFormatApi({ ...productForm.values });
    updateProductDefinitionAPI.fetch({}, req);
  };

  const updateBankAccount = () => {
    const req = productForm.values.bankAccount;
    delete req.directDebitAllowed;
    updateProductBankAccountAPI.fetch({}, req);
  };

  const updateContactDetails = () => {
    const req = productForm.values.contactDetails;
    if (req.landLine?.countryCode === undefined || req.landLine?.number === undefined) delete req.landLine;
    if (req.mobile?.countryCode === undefined || req.mobile?.number === undefined) delete req.mobile;
    updateProductContactDetailsAPI.fetch({}, req);
  };

  useEffect(() => {
    if (product) {
      productForm.values.contactDetails = product.contactDetails;
      productForm.values.bankAccount = product.bankAccount;
    }
  }, [product]);

  const prodUpdate = (value, api) => {
    setErrorViolations(null);
    enqueueSnackbar(`Product successfully ${value}`);
    productCreated(api.response.data);
    setEditing(null);
    setLoading(false);
  };

  useEffect(() => {
    if (createProductAPI.response) prodUpdate('created', createProductAPI);
  }, [createProductAPI.response]);

  useEffect(() => {
    if (updateProductDetailsAPI.response) prodUpdate('updated', updateProductDetailsAPI);
  }, [updateProductDetailsAPI.response]);

  useEffect(() => {
    if (updateProductDefinitionAPI.response) prodUpdate('updated', updateProductDefinitionAPI);
  }, [updateProductDefinitionAPI.response]);

  useEffect(() => {
    if (updateProductContactDetailsAPI.response) prodUpdate('updated', updateProductContactDetailsAPI);
  }, [updateProductContactDetailsAPI.response]);

  useEffect(() => {
    if (updateProductBankAccountAPI.response) prodUpdate('updated', updateProductBankAccountAPI);
  }, [updateProductBankAccountAPI.response]);

  useEffect(() => {
    if (createProductAPI.error) setErrorViolations(createProductAPI.error.response.data.errorViolations);
  }, [createProductAPI.error]);

  useEffect(() => {
    if (updateProductDetailsAPI.error) {
      setErrorViolations(updateProductDetailsAPI.error.response.data.errorViolations);
    }
  }, [updateProductDetailsAPI.error]);

  useEffect(() => {
    if (updateProductDetailsAPI.loading || updateProductDefinitionAPI.loading || createProductAPI.loading
      || updateProductContactDetailsAPI.loading || updateProductBankAccountAPI.loading) {
      setLoading(true);
    } else setLoading(false);
  }, [
    updateProductDetailsAPI, updateProductDefinitionAPI,
    updateProductContactDetailsAPI, updateProductBankAccountAPI,
    createProductAPI,
  ]);

  const getSelected = () => {
    if (selected?.rates.length > 0) return selected;
    if (product?.currentRateTable) return product.currentRateTable;
    if (rateTables[0]?.effectiveDate === now()) return rateTables[0];
    return { rates: [] };
  };

  const newRateTable = () => {
    const ratesWithNewTable = [...rateTables];
    let newExists = false;
    for (const rt of rateTables) if (rt.uuid === '1') newExists = true;
    if (!newExists) ratesWithNewTable.push({ uuid: '1', effectiveDate: 'New rate table', rates: [] });
    setRateTables(ratesWithNewTable);
    const sel = { ...selected };
    sel.uuid = '1';
    sel.rateTable = [];
    sel.effectiveDate = ''
    setSelected(sel);
  };

  return (
    <>
      <Col>
        <Card>
          <CardHeader><CardTitle>{product?.name || 'Product'}</CardTitle></CardHeader>
          <CardBody>
            <Grid nested>
              <Col sm={12}>
                {editView || !product
                  ? <FormikProvider value={productForm}>
                    <Form>
                      <ProductForm
                        editing={editing} errorViolations={errorViolations}
                        form={productForm} loading={loading} product={product}
                        readOnly={readOnly} setEditing={setEditing}
                        updateBankAccount={updateBankAccount}
                        updateContactDetails={updateContactDetails}
                        updateDefinition={updateDefinition}
                        updateDetails={updateDetails}/>
                    </Form>
                  </FormikProvider>
                  : <ProductSummary product={product}/>}
              </Col>
              {!readOnly && product
              && <Col sm={12} style={{ textAlign: 'right' }}>
                <Button
                  color={editView ? 'secondary' : 'primary'} name={'enable-edit'}
                  onClick={() => setEditView(!editView)} type={'button'}>
                  {editView ? 'Finish editing' : 'Edit'}
                </Button>
              </Col>}
            </Grid>
          </CardBody>
        </Card>
      </Col>
      {product?.currentRateTable?.rates && readOnly
      && <Col>
        <Card>
          <CardHeader><CardTitle>Rates</CardTitle></CardHeader>
          <RateTable data={product.currentRateTable.rates}/>
        </Card>
      </Col>}
      {product && !readOnly
      && <>
        <Col lg={3} sm={12}>
          <Card>
            <CardHeader>
              <CardTitle>Rate tables</CardTitle>
              <IconButton icon={faPlus} onClick={() => newRateTable()}/>
            </CardHeader>
            {isEmptyArray(rateTables)
              ? <CardBody>
                <Grid nested>
                  <Col>
                    <CardBody nested>
                      <Grid nested>
                        <Col><h5>Add rate table</h5></Col>
                        <Col>
                          <p>This product has no rates.</p>
                          <p>To add rates enter an effective date and then add applicable rates to
                            the table by specifying the duration and interest rate.</p>
                          <p>Once you're happy save the rate table by clicking on 'Create'.</p>
                        </Col>
                      </Grid>
                    </CardBody>
                  </Col>
                </Grid>
              </CardBody>
              : <RateTables data={rateTables} selected={getSelected()} setSelected={setSelected}/>
            }
          </Card>
        </Col>
        <Col lg={9} sm={12}>
          <Card>
            {(selected?.uuid !== product.currentRateTable?.uuid || !product.currentRateTable)
              ? <CardBody>
                <Grid nested>
                  <Col>
                    <CreateRates product={product} selected={selected} updateRateTable={updateRateTable}/>
                  </Col>
                </Grid>
              </CardBody>
              : <RateTable data={selected.rates}/>
            }
          </Card>
        </Col>
      </>}
    </>
  );
};

export default CreateProduct;
