import React from 'react';
import * as Yup from 'yup';

import { CompanyInitialValues, CompanySchema } from './CompanyForm';
import { IndividualFormInitialValues, IndividualFormSchema } from './IndividualForm';
import { PartyAttribuesSchema, PartyAttributesValues } from './PartyAttributesForm';

const fields = {
  LINKED_PARTIES: 'linkedParties',
};

const initialValues = (trustType, numberOfTrustees) => {
  const linkedParties = [];
  for (let i = 0; i < numberOfTrustees; i++) {
    if (trustType === 'INDIVIDUAL') {
      linkedParties.push({
        party: IndividualFormInitialValues,
        role: 'TRUSTEE',
      });
    } else if (trustType === 'CORPORATE') {
      linkedParties.push({
        party: CompanyInitialValues,
        role: 'TRUSTEE',
      });
    }
  }
  return (
    {
      ...PartyAttributesValues,
      [fields.LINKED_PARTIES]: linkedParties || [],
    }
  );
};

const TrustSchema = (trustType, numberOfTrustees) => (
  Yup.object({
    ...PartyAttribuesSchema,
    [fields.LINKED_PARTIES]: Yup.array()
      .of(trustType === 'INDIVIDUAL'
        ? Yup.object({ role: Yup.string().required('Role is required'), party: IndividualFormSchema })
        : Yup.object({ role: Yup.string().required('Role is required'), party: CompanySchema }))
      .min(numberOfTrustees, 'A minimum of 2 individuals').max(numberOfTrustees, 'A maximum of 2 individuals'),
  })
);

export { initialValues as TrustInitialValues, TrustSchema };
