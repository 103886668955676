import { Col, TextField } from 'capitalroadkit';
import { Field } from 'formik';
import React from 'react';
import * as Yup from 'yup';

const fields = {
  CURRENT_PASSWORD: 'currentPassword',
  NEW_PASSWORD: 'newPassword',
  CONFIRMATION_PASSWORD: 'confirmationPassword',
};

const initialValues = {
  [fields.CURRENT_PASSWORD]: '',
  [fields.NEW_PASSWORD]: '',
  [fields.CONFIRMATION_PASSWORD]: '',
};

const schema = Yup.object({
  [fields.CURRENT_PASSWORD]: Yup.string()
    .required('Current password is required'),
  [fields.NEW_PASSWORD]: Yup.string()
    .required('New password is required'),
  [fields.CONFIRMATION_PASSWORD]: Yup.string()
    .required('Password confirmation is required'),
});

const ChangePasswordForm = () => (
  <>
    <Col sm={12}>
      <Field component={TextField} fullWidth label={'Current Password'} name={fields.CURRENT_PASSWORD} required type={'password'}/>
    </Col>
    <Col sm={12}>
      <Field component={TextField} fullWidth label={'New Password'} name={fields.NEW_PASSWORD} required type={'password'}/>
    </Col>
    <Col sm={12}>
      <Field component={TextField} fullWidth label={'Password Confirmation'} name={fields.CONFIRMATION_PASSWORD} required type={'password'}/>
    </Col>
  </>
);

export default ChangePasswordForm;
export { initialValues as ChangePasswordFormInitialValues, schema as ChangePasswordFormSchema };
