import { Col, Grid, useTheme } from 'capitalroadkit';
import React from 'react';

const ErrorLayout = ({ children }) => {
  const theme = useTheme();

  return (
    <div
      style={{
        backgroundColor: theme.palette.background.main,
        minHeight: '100vh',
        minWidth: '100%',
      }}
    >
      <Grid>
        <Col lg={3} md={6} sm={9} style={{ margin: '3rem auto', textAlign: 'center' }}>
          {children}
        </Col>
      </Grid>
    </div>
  );
};

export default ErrorLayout;
