import { Col, TextField } from 'capitalroadkit';
import { Field } from 'formik';
import React from 'react';
import * as Yup from 'yup';

import { withNameSpace } from '../../functions';

const fields = {
  FILTER: 'filter',
};

const initialValues = {
  [fields.FILTER]: '',
};

const schema = Yup.object({
});

const NotesSearchForm = ({ form, nameSpace }) => (
    <>
      <Col lg={2} md={4} sm={6}>
        <Field component={TextField} fullWidth label={'Filter'} name={withNameSpace(nameSpace, fields.FILTER)}/>
      </Col>
    </>
);

export default NotesSearchForm;
export { initialValues as NotesSearchFormInitialValues, schema as NotesSearchFormSchema };
