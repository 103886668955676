import {
  Card, CardBody, CardHeader, CardTitle, Col, Grid, Page, PageHeader, PieChart, LineChart, useTheme,
} from 'capitalroadkit';
import React, {useEffect} from 'react';

import { getNotesSummaryAxios } from '../../api/NoteResource';
import { getProductSnapshotsAxios } from '../../api/ProductSnapshotResource';
import Loading from '../../components/Loading/Loading';
import { toCurrency } from '../../formatters';
import useAPI from '../../hooks/useAPI';
import {setAmchartsLicense} from '../../hooks/useGlobalSettings';


const Dashboard = ({ user }) => {

  useEffect(() => {
    setAmchartsLicense();
    console.log('license');
  }, []);


  const theme = useTheme();

  const getNoteSummaryAPI = useAPI(getNotesSummaryAxios(), true);
  const getProductSnapshotsAPI = useAPI(getProductSnapshotsAxios(), true);

  const getTotalBalance = () => {
    const summary = getNoteSummaryAPI.response.data;
    summary.sort((a, b) => a.duration - b.duration);

    const totalBalance = {
      total: 0,
      totalPrincipal: 0,
      totalInterest: 0,
    };

    for (const summaryItem of summary) {
      totalBalance.totalPrincipal += summaryItem.principal.value;
      totalBalance.totalInterest += summaryItem.capitalisedInterest.value;
    }

    totalBalance.total = totalBalance.totalPrincipal + totalBalance.totalInterest;
    return totalBalance;
  };

  if (getNoteSummaryAPI.loading) return <Loading size={'3x'}/>;
  if (getNoteSummaryAPI.response) {
    return (
      <Page id={'dashboard'}>
        <Grid>
          <PageHeader
            heading={'Dashboard'}
            subHeading={user.name}
          >
            <span
              style={{ color: theme.palette.text.muted }}>Total Principal:</span> {toCurrency(getTotalBalance().totalPrincipal)}<br/>
            <span
              style={{ color: theme.palette.text.muted }}>Total Capitalised Interest:</span> {toCurrency(getTotalBalance().totalInterest)}<br/>
            <span
              style={{ color: theme.palette.text.muted }}>Total Value:</span> <span
            style={{ color: theme.palette.success.main }}>{toCurrency(getTotalBalance().total)}</span>
          </PageHeader>
          <Col lg={8} sm={12}>
            <Card>
              <CardHeader>
                <CardTitle>Total Funds Under Administration</CardTitle>
              </CardHeader>
              <CardBody>
                {getProductSnapshotsAPI.response
                  ? <LineChart
                    data={getProductSnapshotsAPI.response.data.totalFundsSnapshot}
                    id={'totalFundsUnderProduct'}
                    theme={theme}
                    xValue={{ value: 'date', name: 'Date' }}
                    yValues={[
                      { value: 'totalFundsUnderProduct', name: 'Funds', label: '$'},
                      { value: 'totalNotesUnderProduct', name: 'Notes', label: ''},
                    ]}/>
                  : <Loading size={'3x'}/>
                }
              </CardBody>
            </Card>
          </Col>
          <Col lg={4} sm={12}>
            <Card>
              <CardHeader>
                <CardTitle>Note principal breakdown by duration</CardTitle>
              </CardHeader>
              <CardBody>
                <PieChart
                  category={'name'}
                  data={
                    getNoteSummaryAPI.response.data
                      .sort((a, b) => a.duration - b.duration)
                      .map((s) => ({
                        name: s.duration,
                        value: s.principal.value,
                      }))
                  }
                  hideLabels={true}
                  id={'noteBreakdownByDurationChart'}
                  theme={theme}
                  tooltipFormat="{category} Months: ${value.value.formatNumber('#,###.00')} - ({value.percent.formatNumber('#.00')}%)"
                  value={'value'}
                />
              </CardBody>
            </Card>
          </Col>
        </Grid>
      </Page>
    );
  }
  return null;
};

export default Dashboard;
