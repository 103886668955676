import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import {
  Button,
  Card, CardActions, CardBody, CardHeader, CardTitle, Col, Grid, IconButton, Modal,
} from 'capitalroadkit';
import { Form, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';

import ProductBankFileForm, { ProductBankFileFormInitialValues, ProductBankFileFormSchema } from './ProductBankFileForm';

import { generateABABankFileAxios } from '../../api/PartyResource';
import Error from '../../components/Error/Error';
import { parseEnum, toAPIDate } from '../../formatters';
import useAPI from '../../hooks/useAPI';

const BankFileModal = ({ fetchBankFiles, type }) => {
  const [open, setOpen] = useState(false);

  const productBankFileForm = useFormik({
    initialValues: ProductBankFileFormInitialValues,
    onSubmit: (values) => generateABABankFileAPI.fetch({ date: toAPIDate(values.date), type }),
    validationSchema: ProductBankFileFormSchema,
  });

  const generateABABankFileAPI = useAPI(
    generateABABankFileAxios(productBankFileForm.values.product.uuid),
    false,
  );

  useEffect(() => {
    if (generateABABankFileAPI.response && !generateABABankFileAPI.loading) {
      setOpen(false);
      productBankFileForm.resetForm();
      fetchBankFiles();
    }
  }, [generateABABankFileAPI.response, generateABABankFileAPI.loading]);

  return (
    <>
      <Button
        color={'primary'}
        onClick={() => setOpen(true)}
        variant={'text'}
      >
        Generate
      </Button>
      <Modal open={open}>
        <Card>
          <CardHeader>
            <CardTitle>Generate {parseEnum(type)} Bank File</CardTitle>
            <CardActions>
              <IconButton icon={faTimes} onClick={() => setOpen(false)}/>
            </CardActions>
          </CardHeader>
          <CardBody>
            <FormikProvider value={productBankFileForm}>
              <Form>
                <Grid nested>
                  <ProductBankFileForm
                    form={productBankFileForm}
                  />
                  <Col sm={12} style={{ textAlign: 'right' }}>
                    <Button
                      color={'primary'}
                      loading={generateABABankFileAPI.loading}
                      type={'submit'}
                    >
                      Generate
                    </Button>
                    {generateABABankFileAPI.error
                    && <Error error={generateABABankFileAPI.error.response.data}/>
                    }
                  </Col>
                </Grid>
              </Form>
            </FormikProvider>
          </CardBody>
        </Card>
      </Modal>
    </>
  );
};

export default BankFileModal;
