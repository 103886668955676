export const host = () => getHostValue();

function getHostValue() {
  const { hostname } = window.location;
  const hostArray = hostname.split('.');
  if (hostArray.length === 1) return 'http://localhost:8080/mayfair-se';
  // if (hostArray.length === 1) return 'https://dev-api.nonprod.oktowealth.com.au';
  hostArray[0] += '-api';
  return `https://${hostArray.join('.')}`;
}
