import {
  Button, CardBody, Col, Grid, TextField,
} from 'capitalroadkit';
import { FastField, Formik } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import { updateContactDetailsPartyAxios } from '../../../api/PartyResource';
import useAPI from '../../../hooks/useAPI';

const EditContactDetailsForm = ({ contactDetails, uuid }) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const updateContactDetailsPartyAPI = useAPI(updateContactDetailsPartyAxios(uuid));

  useEffect(() => {
    const response = updateContactDetailsPartyAPI.response || updateContactDetailsPartyAPI.response;
    const loading = updateContactDetailsPartyAPI.loading || updateContactDetailsPartyAPI.loading;

    if (!loading && response) {
      enqueueSnackbar('Client was successfully updated');
      history.push(`/secure/clients/${uuid}`);
    }
  }, [updateContactDetailsPartyAPI.response, updateContactDetailsPartyAPI.loading,
    updateContactDetailsPartyAPI.response, updateContactDetailsPartyAPI.loading]);

  const handleOnSubmit = (values, { setSubmitting, setValues }) => {
    const responseUpdate = updateContactDetailsPartyAPI.fetch({}, values);

    contactDetails.address.line1 = values.address.line1;
    contactDetails.address.city = values.address.city;
    contactDetails.address.state = values.address.state;
    contactDetails.address.country = values.address.country;
    contactDetails.address.postcode = values.address.postcode;
    contactDetails.emailAddress.address = values.emailAddress.address;
    contactDetails.mobile.countryCode = values.mobile.countryCode;
    contactDetails.mobile.number = values.mobile.number;
    setValues(contactDetails);
    setSubmitting(false);
  };

  return (
    <>
      <Formik
        initialValues={{
          address: {
            line1: contactDetails.address.line1,
            city: contactDetails.address.city,
            state: contactDetails.address.state,
            country: contactDetails.address.country,
            postcode: contactDetails.address.postcode,
          },
          emailAddress: {
            address: contactDetails.emailAddress.address,
          },
          mobile: {
            countryCode: contactDetails.mobile == null ? null : contactDetails.mobile.countryCode,
            number: contactDetails.mobile == null ? null : contactDetails.mobile.number,
          },
          landLine: {
            countryCode: contactDetails.landLine == null ? null : contactDetails.landLine.countryCode,
            number: contactDetails.landLine == null ? null : contactDetails.landLine.number,
          },
        }}
        onSubmit={handleOnSubmit}
      >
        {({ errors, touched, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Grid nested>
              <Col lg={6} sm={12}>
                <CardBody nested>
                  <Grid nested>
                    <Col><p>Address</p></Col>
                    <Col lg={6} sm={12}>
                      <FastField component={TextField} fullWidth label={'Line 1'}
                                 name='address.line1' required/>
                      {errors.line1 && touched.line1 && errors.line1}
                    </Col>
                    <Col lg={6} sm={12}>
                      <FastField component={TextField} fullWidth label={'City'}
                                 name='address.city' required/>
                      {errors.city && touched.city && errors.city}
                    </Col>
                    <Col lg={6} sm={12}>
                      <FastField component={TextField} fullWidth label={'State'}
                                 name='address.state' required/>
                      {errors.state && touched.state && errors.state}
                    </Col>
                    <Col lg={6} sm={12}>
                      <FastField component={TextField} fullWidth label={'Country'}
                                 name='address.country' required/>
                      {errors.country && touched.country && errors.country}
                    </Col>
                    <Col lg={6} sm={12}>
                      <FastField component={TextField} fullWidth label={'Postcode'}
                                 name='address.postcode' required/>
                      {errors.postcode && touched.postcode && errors.postcode}
                    </Col>
                  </Grid>
                </CardBody>
              </Col>
              <Col lg={6} sm={12}>
                <CardBody nested>
                  <Grid nested>
                    <Col lg={12}>
                      <FastField component={TextField} fullWidth label={'Email address'}
                                 name='emailAddress.address' required/>
                      {errors.address && touched.address && errors.address}
                    </Col>
                    <Col lg={12}>
                      <FastField component={TextField} fullWidth label={'Mobile Country Code'}
                                 name='mobile.countryCode' required/>
                      {errors.countryCode && touched.countryCode && errors.countryCode}
                    </Col>
                    <Col lg={12}>
                      <FastField component={TextField} fullWidth label={'Mobile Number'}
                                 name='mobile.number' required/>
                      {errors.number && touched.number && errors.number}
                    </Col>
                      <Col lg={12}>
                          <FastField component={TextField} fullWidth label={'Landline Country Code'}
                                     name='landLine.countryCode' required/>
                          {errors.countryCode && touched.countryCode && errors.countryCode}
                      </Col>
                      <Col lg={12}>
                          <FastField component={TextField} fullWidth label={'Landline Number'}
                                     name='landLine.number' required/>
                          {errors.number && touched.number && errors.number}
                      </Col>
                  </Grid>
                </CardBody>
              </Col>
              <Col style={{ textAlign: 'right' }}>
                <Link to={`/secure/clients/${uuid}`}>
                  <Button color={'danger'}>Cancel</Button>
                </Link>
                <Button color={'success'} style={{ marginLeft: '.5rem' }} type={'submit'}>
                  Submit
                </Button>
              </Col>
            </Grid>
          </form>
        )}
      </Formik>
    </>
  );
};

export default EditContactDetailsForm;
