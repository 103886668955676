import MenuItem from '@material-ui/core/MenuItem';
import {
  CardBody, Checkbox,
  Col, TextField,
} from 'capitalroadkit';
import { Field } from 'formik';
import React, { useEffect } from 'react';
import * as Yup from 'yup';

import ProductRateTable from './ProductRateTable';

import { listPartiesAxios } from '../../../api/PartyResource';
import { listProductsAxios } from '../../../api/ProductResource';
import Autocomplete from '../../../components/Autocomplete/Autocomplete';
import Loading from '../../../components/Loading/Loading';
import { withNameSpace } from '../../../functions';
import useAPI from '../../../hooks/useAPI';

export const InterestDistribution = Object.freeze({
  Monthly: 'MONTHLY',
  Reinvest: 'REINVEST',
});

const fields = {
  PARTY: 'party',
  PRODUCT: 'product',
  INVESTMENT_AMOUNT: 'investmentAmount',
  DURATION: 'duration',
  INTEREST_RATE: 'interestRate',
  OVERRIDE_INTEREST_RATE: 'overwriteInterestRate',
  INTEREST_DISTRIBUTION: 'interestDistribution',
};

const initialValues = {
  [fields.PARTY]: '',
  [fields.PRODUCT]: '',
  [fields.INVESTMENT_AMOUNT]: '',
  [fields.DURATION]: '',
  [fields.INTEREST_RATE]: '',
  [fields.OVERRIDE_INTEREST_RATE]: false,
  [fields.INTEREST_DISTRIBUTION]: InterestDistribution.Reinvest,
};

const schema = Yup.object({

});

const FixedInterestNoteForm = ({
  form, hidden, nameSpace, readOnly,
}) => {
  const listProductsAPI = useAPI(listProductsAxios());

  useEffect(() => {
    if (!listProductsAPI.loading && listProductsAPI.response) {
      form.setFieldValue(
        withNameSpace(nameSpace, fields.PRODUCT),
        listProductsAPI.response.data[0].uuid,
      );
    }
  }, [listProductsAPI.loading, listProductsAPI.response]);

  useEffect(() => {
    if (form.values[fields.PRODUCT] && getSelectedProduct()) {
      form.setFieldValue(
        withNameSpace(nameSpace, fields.DURATION),
        getSelectedProduct().currentRateTable.rates[0].duration,
      );
    }
  }, [form.values[fields.PRODUCT]]);

  useEffect(() => {
    if (form.values[fields.DURATION] && getSelectedRate()) {
      form.setFieldValue(
        withNameSpace(nameSpace, fields.INTEREST_RATE),
        getSelectedRate().interestRate,
      );
    }
  }, [form.values[fields.DURATION]]);

  useEffect(() => {
    if (!form.values[fields.OVERRIDE_INTEREST_RATE] && getSelectedRate()) {
      form.setFieldValue(
        withNameSpace(nameSpace, fields.INTEREST_RATE),
        getSelectedRate().interestRate,
      );
    }
  }, [form.values[fields.OVERRIDE_INTEREST_RATE]]);

  const getSelectedProduct = () => {
    if (listProductsAPI.response) {
      for (const product of listProductsAPI.response.data) {
        if (product.uuid === form.values.product) return product;
      }
    }
    return null;
  };

  const getSelectedRate = () => {
    if (getSelectedProduct()) {
      for (const rate of getSelectedProduct()?.currentRateTable.rates) {
        if (rate.duration === form.values.duration) return rate;
      }
    }
    return null;
  };

  if (listProductsAPI.loading) return <Loading size={'2x'}/>;
  if (listProductsAPI.response) {
    return (
      <>
        <Col sm={12}>
          {!hidden.includes(fields.PARTY)
          && <Field
            axios={listPartiesAxios}
            component={Autocomplete}
            label={'1. Client'}
            name={withNameSpace(nameSpace, fields.PARTY)}
            required
            style={{ width: '15rem' }}
          />
          }
        </Col>
        <Col sm={12}>
          <Field
            component={TextField}
            label={'2. Product'}
            name={withNameSpace(nameSpace, fields.PRODUCT)}
            readOnly={readOnly.includes(fields.PRODUCT)}
            required
            select
            style={{ width: '15rem' }}
          >
            {listProductsAPI.response.data
              .filter((product) => product.currentRateTable)
              .map((product) => (
                <MenuItem key={product.uuid} value={product.uuid}>{product.name}</MenuItem>
              ))
            }
          </Field>
        </Col>
        <Col sm={12}>
          <Field component={TextField} label={'3. Investment Amount'} name={fields.INVESTMENT_AMOUNT} required/>
        </Col>
        {getSelectedProduct()
        && <Col sm={12}>
          <h6 style={{ color: 'rgba(0, 0, 0, 0.54)' }}>4. Select Investment Term</h6>
          <ProductRateTable
            data={getSelectedProduct().currentRateTable.rates}
            product={getSelectedProduct()}
            rowClick={(row) => form.setFieldValue(fields.DURATION, row.values.duration)}
            selected={form.values[fields.DURATION]}
          />
        </Col>
        }
        <Col sm={12}>
          <Field
            component={TextField}
            label={'5. Interest Rate'}
            name={fields.INTEREST_RATE}
            readOnly={
              getSelectedRate()?.interestRateOverrideNotPermitted
            || !form.values[fields.OVERRIDE_INTEREST_RATE]
            }
            required
            style={{ width: '8rem' }}
          />
        </Col>
        {!getSelectedRate()?.interestRateOverrideNotPermitted
        && <Col sm={12}>
          <Field
            component={Checkbox}
            label={'Overwrite Interest Rate?'}
            name={fields.OVERRIDE_INTEREST_RATE}
          />
        </Col>
        }
        <Col sm={12}>
          <Field
            component={TextField}
            label={'6. Interest Distribution'}
            name={fields.INTEREST_DISTRIBUTION}
            select
          >
            {Object.entries(InterestDistribution)
              .map((interestDistribution) => (
                <MenuItem
                  key={interestDistribution[1]}
                  value={interestDistribution[1]}
                >
                  {interestDistribution[0]}
                </MenuItem>
              ))
            }
          </Field>
        </Col>
        <Col sm={6}>
          <h6 style={{ color: 'rgba(0, 0, 0, 0.54)' }}>7. Deposit Details</h6>
          <CardBody nested>
            <table>
              <table
                style={{
                  borderCollapse: 'collapse', tableLayout: 'fixed', textAlign: 'left', width: '100%',
                }}
              >
                <tbody>
                <tr>
                  <th>BSB</th><td>{getSelectedProduct()?.bankAccount.bsbNumber}</td>
                  <th>Account Number</th><td>{getSelectedProduct()?.bankAccount.accountNumber}</td>
                </tr>
                <tr>
                  <th>Account Name</th><td>{getSelectedProduct()?.bankAccount.accountName}</td>
                </tr>
                </tbody>
              </table>
            </table>
          </CardBody>
        </Col>
        <Col sm={6}/>
      </>
    );
  }
  return null;
};

FixedInterestNoteForm.defaultProps = {
  hidden: [],
  readOnly: [],
};

export default FixedInterestNoteForm;
export {
  initialValues as FixedInterestNoteFormInitialValues,
  schema as FixedInterestNoteFormSchema,
};
