import { Table, useTheme } from 'capitalroadkit';
import React, { useMemo } from 'react';

import { toDateString } from '../../../formatters';

const RateTables = ({
  data, setSelected, selected,
}) => {
  const theme = useTheme();
  const columns = useMemo(() => [
    {
      id: 'rateTables',
      Header: '',
      Footer: '',
      columns: [
        {
          Header: 'Effective date',
          accessor: 'effectiveDate',
          Cell: ({ cell: { value } }) => (toDateString(value) === 'Invalid date' ? value : toDateString(value)),
        },
        {
          accessor: 'uuid',
          Cell: ({ cell: { value } }) => (selected?.uuid && selected?.uuid === value
            ? <span style={{ color: theme.palette.success.main }}>Active</span>
            : ''),
          headerStyle: {
            width: '6rem',
          },
        },
      ],
    },
  ], []);

  return (
    <Table
      columns={columns}
      data={data}
      dataKey={'uuid'}
      rowClick={(row) => setSelected(row.original)}
      selected={selected?.uuid}
      sortBy={[{ id: 'effectiveDate', desc: false }]}
    />
  );
};

export default RateTables;
