import {
  Button, CardBody, Col, Grid, TextField,
} from 'capitalroadkit';
import { FastField, Formik } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import { updateOrganisationPartyAxios } from '../../../api/PartyResource';
import useAPI from '../../../hooks/useAPI';

const EditBasicDetailsOrganisationForm = ({ party }) => {
  const updateOrganisationPartyAPI = useAPI(updateOrganisationPartyAxios(party.uuid));
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  useEffect(() => {
    const response = updateOrganisationPartyAPI.response || updateOrganisationPartyAPI.response;
    const loading = updateOrganisationPartyAPI.loading || updateOrganisationPartyAPI.loading;

    if (!loading && response) {
      enqueueSnackbar('Client was successfully updated');
      history.push(`/secure/clients/${party.uuid}`);
    }
  }, [updateOrganisationPartyAPI.response, updateOrganisationPartyAPI.loading,
    updateOrganisationPartyAPI.response, updateOrganisationPartyAPI.loading]);

  const handleOnSubmit = (values, { setSubmitting, setValues }) => {
    updateOrganisationPartyAPI.fetch({}, values);
    party.name = values.name;
    setValues(party);
    setSubmitting(false);
  };
  return (
    <>
      <Formik
        initialValues={{
          name: party.name,
          type: party.type,
        }}
        onSubmit={handleOnSubmit}
      >
        {({ errors, touched, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Grid nested>
              <Col>
                <CardBody nested>
                  <Grid nested>
                    <Col>
                      <FastField component={TextField} fullWidth label={'Organisation name'}
                                 name='name' required/>
                      {errors.name && touched.name && errors.name}
                    </Col>
                  </Grid>
                </CardBody>
              </Col>
              <Col style={{ textAlign: 'right' }}>
                <Link to={`/secure/clients/${party.uuid}`}>
                    <Button color={'danger'}>Cancel</Button>
                </Link>
                <Button color={'success'} style={{ marginLeft: '.5rem' }} type={'submit'}>
                    Submit
                </Button>
              </Col>
            </Grid>
          </form>
        )}
      </Formik>
    </>
  );
};

export default EditBasicDetailsOrganisationForm;
