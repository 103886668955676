import moment from 'moment';
import numeral from 'numeral';

export const now = () => moment()
  .format('YYYY-MM-DD');
export const parseBoolean = (value) => (value ? 'Yes' : 'No');
export const parseEnum = (value) => value
  .split('_')
  .join(' ')
  .split('-')
  .join(' ')
  .toLowerCase()
  .replace(/(?:^|\s)\S/g, (a) => a.toUpperCase());
export const toAPIDate = (value) => moment(value, 'DD/MM/YYYY')
  .format('YYYY-MM-DD');
export const toCurrency = (value) => numeral(value)
  .format('$0,0.00');
export const toDateString = (value) => moment(value)
  .format('DD/MM/YYYY');
export const toPercent = (value) => `${value}%`;
