import { Col, Grid, TextField } from 'capitalroadkit';
import { FastField } from 'formik';
import React from 'react';
import * as Yup from 'yup';

import { withNameSpace } from '../../../functions';


const fields = {
  COUNTRY_CODE: 'countryCode',
  NUMBER: 'number',
};

const initialValues = (required, phone) => ({
  [fields.COUNTRY_CODE]: phone?.countryCode || required ? '61' : '',
  [fields.NUMBER]: phone?.number || '',
});

const schema = (required) => Yup.object({
  [fields.COUNTRY_CODE]: required
    ? Yup.number()
      .typeError('Country Code must be a valid country code')
      .required('Country Code is required')
    : Yup.number()
      .typeError('Country Code must be a valid country code'),
  [fields.NUMBER]: required
    ? Yup.number()
      .typeError('Number must be a valid phone number')
      .required('Number is required')
    : Yup.number()
      .typeError('Number must be a valid phone number'),
});

const PhoneForm = ({
  nameSpace, readOnly, required, ...props
}) => (
  <Grid nested>
    <Col sm={12}>{props.title}</Col>
    <Col sm={3}>
      <FastField component={TextField} fullWidth key={readOnly} label={'Code'}
                 name={withNameSpace(nameSpace, fields.COUNTRY_CODE)} readOnly={readOnly} required={required}/>
    </Col>
    <Col sm={9}>
      <FastField component={TextField} fullWidth key={readOnly} label={'Number'} name={withNameSpace(nameSpace, fields.NUMBER)}
                 readOnly={readOnly} required={required}/>
    </Col>
  </Grid>
);

export default PhoneForm;
export { initialValues as PhoneFormInitialValues, schema as PhoneFormSchema };
