import {
  Button, CardBody, Col, Grid, TextField,
} from 'capitalroadkit';
import { FastField, Formik } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import { updateBankDetailsPartyAxios } from '../../../api/PartyResource';
import useAPI from '../../../hooks/useAPI';

const EditBankAccountDetailsForm = ({ bankAccount, uuid }) => {
  const updateBankDetailsPartyAPI = useAPI(updateBankDetailsPartyAxios(uuid));
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  useEffect(() => {
    const response = updateBankDetailsPartyAPI.response || updateBankDetailsPartyAPI.response;
    const loading = updateBankDetailsPartyAPI.loading || updateBankDetailsPartyAPI.loading;

    if (!loading && response) {
      enqueueSnackbar('Client was successfully updated');
      history.push(`/secure/clients/${uuid}`);
    }
  }, [updateBankDetailsPartyAPI.response, updateBankDetailsPartyAPI.loading,
    updateBankDetailsPartyAPI.response, updateBankDetailsPartyAPI.loading]);

  const handleOnSubmit = (values, { setSubmitting, setValues }) => {
    updateBankDetailsPartyAPI.fetch({}, values);
    bankAccount.accountName = values.accountName;
    bankAccount.bsbNumber = values.bsbNumber;
    bankAccount.accountNumber = values.accountNumber;
    bankAccount.branchAddress = values.branchAddress;
    setValues(bankAccount);
    setSubmitting(false);
  };
  return (
    <>
      <Formik
        initialValues={{
          accountName: bankAccount.accountName,
          bsbNumber: bankAccount.bsbNumber,
          accountNumber: bankAccount.accountNumber,
          branchAddress: bankAccount.branchAddress,
          dateOfDirectDebitAuthority: bankAccount.dateOfDirectDebitAuthority,
        }}
        onSubmit={handleOnSubmit}
      >
        {({ errors, touched, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Grid nested>
              <Col>
                <CardBody nested>
                  <Grid nested>
                    <Col>Bank account</Col>
                    <Col sm={6}>
                      <FastField component={TextField} fullWidth label={'Account name'}
                                 name='accountName' required/>
                      {errors.accountName && touched.accountName && errors.accountName}
                    </Col>
                    <Col sm={6}>
                      <FastField component={TextField} fullWidth label={'BSB'}
                                 name='bsbNumber' required/>
                      {errors.bsbNumber && touched.bsbNumber && errors.bsbNumber}
                    </Col>
                    <Col sm={6}>
                      <FastField component={TextField} fullWidth label={'Account number'}
                                 name='accountNumber' required/>
                      {errors.accountNumber && touched.accountNumber && errors.accountNumber}
                    </Col>
                    <Col sm={6}>
                      <FastField component={TextField} fullWidth label={'Branch address'}
                                 name='branchAddress' required/>
                      {errors.branchAddress && touched.branchAddress && errors.branchAddress}
                    </Col>
                  </Grid>
                </CardBody>
              </Col>
              <Col style={{ textAlign: 'right' }}>
                <Link to={`/secure/clients/${uuid}`}>
                  <Button color={'danger'}>Cancel</Button>
                </Link>
                <Button color={'success'} style={{ marginLeft: '.5rem' }} type={'submit'}>
                  Submit
                </Button>
              </Col>
            </Grid>
          </form>
        )}
      </Formik>
    </>
  );
};

export default EditBankAccountDetailsForm;
