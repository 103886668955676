import axios from 'axios';

import { host } from './utilities';

export const getNoteByUuidAxios = (uuid) => (config) => axios.get(`${host()}/fixed-interest-note/${uuid}`, config);

export const processOpeningBalanaceAxios = (uuid) => (config, data = {}) => axios.post(`${host()}/fixed-interest-note/${uuid}/deposit`, data, config);

export const processRollOverAxios = (uuid) => (config, data = {}) => axios.post(`${host()}/fixed-interest-note/${uuid}/roll-over`, data, config);

export const processRedeemAxios = (uuid) => (config, data = {}) => axios.post(`${host()}/fixed-interest-note/${uuid}/redeem`, data, config);

export const updateMaturityInstructionAxios = (uuid) => (config, data = {}) => axios.put(`${host()}/fixed-interest-note/${uuid}/maturity-instruction`, data, config);

export const deleteMaturityInstructionAxios = (uuid) => (config, data = {}) => axios.delete(`${host()}/fixed-interest-note/${uuid}/maturity-instruction`, config);

export const suspendInterestPaymentAxios = (uuid) => (config, data = {}) => axios.put(`${host()}/fixed-interest-note/${uuid}/suspend-interest-payment`, data, config);

export const resumeInterestPaymentAxios = (uuid) => (config, data = {}) => axios.put(`${host()}/fixed-interest-note/${uuid}/resume-interest-payment`, data, config);

export const suspendPrincipalPaymentAxios = (uuid) => (config, data = {}) => axios.put(`${host()}/fixed-interest-note/${uuid}/suspend-principal-payment`, data, config);

export const resumePrincipalPaymentAxios = (uuid) => (config, data = {}) => axios.put(`${host()}/fixed-interest-note/${uuid}/resume-principal-payment`, data, config);

export const interestToBeReinvestedAxios = (uuid) => (config, data = {}) => axios.put(`${host()}/fixed-interest-note/${uuid}/interest-to-be-reinvested`, data, config);

export const interestToBePaidAxios = (uuid) => (config, data = {}) => axios.put(`${host()}/fixed-interest-note/${uuid}/interest-to-be-paid`, data, config);

export const processInterestPaymentAxios = (uuid) => (config, data = {}) => axios.post(`${host()}/fixed-interest-note/${uuid}/interest-payment`, data, config);

export const processPrincipalPaymentAxios = (uuid) => (config, data = {}) => axios.post(`${host()}/fixed-interest-note/${uuid}/principal-payment`, data, config);

export const getNoteTransactionsAxios = (uuid) => (config) => axios.get(`${host()}/fixed-interest-note/${uuid}/transactions`, config);

export const searchNotesAxios = () => (config) => axios.get(`${host()}/fixed-interest-note/search`, config);

export const getNotesSummaryAxios = () => (config) => axios.get(`${host()}/fixed-interest-note/summary`, config);

export const getNoteDocumentsAxios = (uuid) => (config) => axios.get(`${host()}/fixed-interest-note/${uuid}/documents`, config);
