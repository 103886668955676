import {CardBody, Grid} from 'capitalroadkit';
import React from 'react';

import AustralianLicenseForm from './AustralianDriversLicenseForm';
import PassportDocumentForm from './PassportDocumentForm';

export const IdentityDocumentForm = ({
  form, nameSpace, readOnly, documentType, document,
}) => {
  const getDocumentOnType = () => {
    switch (documentType) {
      case 'PASSPORT':
        return (
          <PassportDocumentForm
            document={document}
            form={form}
            nameSpace={nameSpace} readOnly={readOnly}/>
        );
      case 'AUSTRALIAN_DRIVERS_LICENSE':
        return (
          <AustralianLicenseForm
            document={document}
            form={form}
            nameSpace={nameSpace} readOnly={readOnly}/>
        );
      default:
        return <></>;
    }
  };

  return (
    <CardBody nested>
      <Grid nested>
        {getDocumentOnType()}
      </Grid>
    </CardBody>
  );
};
