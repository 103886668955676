import { Button, useTheme } from 'capitalroadkit';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { logoutAxios } from '../../api/AuthResource';
import useAPI from '../../hooks/useAPI';
import useCookies from '../../hooks/useCookies';

const Unauthorzied = () => {
  const history = useHistory();
  const theme = useTheme();

  const [, setAuthToken] = useCookies('authToken', null);

  const logoutAPI = useAPI(logoutAxios(), false);

  useEffect(() => {
    if (!logoutAPI.loading && logoutAPI.response) {
      setAuthToken(null);
      history.push('/login');
    }
  }, [logoutAPI.loading, logoutAPI.response]);

  return (
    <>
      <h1
        style={{
          color: theme.palette.primary.main,
          textShadow: 'rgba(251, 164, 0, .3) 5px 1px, rgba(251, 164, 0, .2) 10px 3px',
          fontSize: '5.25rem',
        }}
      >
        401
      </h1>
      <h4
        style={{
          color: theme.palette.danger.main,
          fontSize: '1.125rem',
          fontWeight: '700',
          textTransform: 'uppercase',
        }}
      >
        Unauthorized
      </h4>
      <p style={{ color: theme.palette.text.muted }}>
        You do not have authorization to access that page.
        If you think we got this wrong, please contact your administrator.
      </p>
      <div style={{ marginTop: '1.5rem' }}>
        <Button
          color={'primary'}
          loading={logoutAPI.loading}
          onClick={() => logoutAPI.fetch()}
          style={{ marginLeft: '1rem' }}
        >
          Login
        </Button>
      </div>
    </>
  );
};

export default Unauthorzied;
