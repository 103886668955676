import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button, Col, Grid, Page, useTheme,
} from 'capitalroadkit';
import { Form, FormikProvider, useFormik } from 'formik';
import React from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';

import ResetPasswordForm, { ResetPasswordFormInitialValues, ResetPasswordFormSchema } from './ResetPasswordForm';

import { resetPasswordAxios } from '../../api/AuthResource';
import Error from '../../components/Error/Error';
import useAPI from '../../hooks/useAPI';


const ResetPassword = () => {
  const theme = useTheme();
  const location = useLocation();

  const resetPasswordAPI = useAPI(resetPasswordAxios(), false);

  // Encrypted token sometimes includes '/'
  const getResetToken = () => {
    const split = location.pathname.split('/').splice(2);
    return split.join('/');
  };

  const resetPasswordForm = useFormik({
    initialValues: ResetPasswordFormInitialValues,
    onSubmit: (values) => {
      const req = {
        username: values.username,
        resetPasswordToken: getResetToken(),
        newPassword: values.newPassword,
        confirmationPassword: values.confirmPassword,
        type: 'WEB',
      };
      resetPasswordAPI.fetch({}, req);
    },
    validationSchema: ResetPasswordFormSchema,
  });

  return (
    <Page id={'reset-password-init'}>
      <Grid>
        <Col sm={12} style={{ textAlign: 'center' }}>
          <img
            alt={theme.skin}
            src={theme.images.logo[theme.mode].large}
            style={{ height: '6rem' }}
          />
        </Col>
        <Col sm={12} style={{ textAlign: 'center' }}>
          <h4>Reset password</h4>
        </Col>
        <Col sm={12}>
          <FormikProvider value={resetPasswordForm}>
            <Form>
              <Grid nested>
                <ResetPasswordForm/>
                {!resetPasswordAPI.response
                && <Col sm={12} style={{ textAlign: 'right' }}>
                  <Link to={'/login'}>
                    <Button loading={resetPasswordForm.loading} color={'secondary'}>
                      Back
                    </Button>
                  </Link>
                  <Button loading={resetPasswordForm.loading} style={{ marginLeft: '.5rem' }}
                          type={'submit'} color={'primary'}>
                    Reset password
                  </Button>
                  {resetPasswordAPI.error
                  && <Error error={resetPasswordAPI.error.response.data}/>}
                </Col>}
                {resetPasswordAPI.response
                && <>
                  <Col sm={12} style={{ textAlign: 'right' }}>
                    <Link to={'/login'}>
                      <Button loading={resetPasswordForm.loading} color={'primary'}>
                        Return to login
                      </Button>
                    </Link>
                  </Col>
                  <Col sm={12} style={{ textAlign: 'right' }}>
                    <FontAwesomeIcon icon={faCheckCircle} style={{ color: theme.palette.success.main }}/>
                    <small> Your password has been successfully reset</small>
                  </Col>
                </>}
              </Grid>
            </Form>
          </FormikProvider>
        </Col>
      </Grid>
    </Page>
  );
};

export default ResetPassword;
