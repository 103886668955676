import axios from 'axios';

import { host } from './utilities';

export const listProductsAxios = () => (config) => axios.get(`${host()}/product/list`, config);

export const findProductByUuidAxios = (uuid) => (config) => axios.get(`${host()}/product/${uuid}`, config);

export const findProductByNoteReferenceIdAxios = (noteuuid) => (config) => axios.get(`${host()}/product/fixed-interest-note/${noteuuid}`, config);

export const getSnapshotsAxios = (uuid) => (config) => axios.get(`${host()}/product/${uuid}/snapshots`, config);

export const createProductAxios = () => (config, data = {}) => axios.post(`${host()}/product`, data, config);

export const createProductRateTableAxios = (uuid) => (config, data = {}) => axios.post(`${host()}/product/${uuid}/rate-table`, data, config);

export const getProductRateTablesAxios = (uuid) => (config) => axios.get(`${host()}/product/${uuid}/rate-tables`, config);

export const updateProductDetailsAxios = (uuid) => (config, data = {}) => axios.put(`${host()}/product/${uuid}/update-details`, data, config);

export const updateProductDefinitionAxios = (uuid) => (config, data = {}) => axios.put(`${host()}/product/${uuid}/update-definition`, data, config);

export const updateProductBankAccountAxios = (uuid) => (config, data = {}) => axios.put(`${host()}/product/${uuid}/update-bank-account`, data, config);

export const updateProductContactDetailsAxios = (uuid) => (config, data = {}) => axios.put(`${host()}/product/${uuid}/update-contact-details`, data, config);
