import MenuItem from '@material-ui/core/MenuItem';
import {
  Col, TextField,
} from 'capitalroadkit';
import { Field } from 'formik';
import React, { useEffect } from 'react';
import * as Yup from 'yup';

import { listProductsAxios } from '../../api/ProductResource';
import Loading from '../../components/Loading/Loading';
import { withNameSpace } from '../../functions';
import useAPI from '../../hooks/useAPI';

const fields = {
  PRINCIPAL_AT_INCEPTION: 'principalAtInception',
  PRODUCT: 'product',
  DURATION: 'duration',
  INTEREST_RATE: 'interestRate',
};

const initialValues = {
  [fields.PRINCIPAL_AT_INCEPTION]: '',
  [fields.PRODUCT]: '',
  [fields.DURATION]: '',
  [fields.INTEREST_RATE]: '',
};

const schema = Yup.object({

});

const FixedInterestNoteForm = ({
  form, hidden, nameSpace, readOnly,
}) => {
  const listProductsAPI = useAPI(listProductsAxios());

  useEffect(() => {
    if (!listProductsAPI.loading && listProductsAPI.response) {
      form.setFieldValue(
        withNameSpace(nameSpace, fields.PRODUCT),
        listProductsAPI.response.data[0].uuid,
      );
    }
  }, [listProductsAPI.loading, listProductsAPI.response]);

  useEffect(() => {
    if (form.values[nameSpace][fields.PRODUCT] && getSelectedProduct()) {
      form.setFieldValue(
        withNameSpace(nameSpace, fields.DURATION),
        getSelectedProduct().currentRateTable.rates[0].duration,
      );
    }
  }, [form.values[nameSpace][fields.PRODUCT]]);

  useEffect(() => {
    if (form.values[nameSpace][fields.DURATION] && getSelectedRate()) {
      form.setFieldValue(
        withNameSpace(nameSpace, fields.INTEREST_RATE),
        getSelectedRate().interestRate,
      );
    }
  }, [form.values[nameSpace][fields.DURATION]]);

  const getSelectedProduct = () => {
    if (listProductsAPI.response) {
      for (const product of listProductsAPI.response.data) {
        if (product.uuid === form.values[nameSpace].product) return product;
      }
    }
    return null;
  };

  const getSelectedRate = () => {
    if (getSelectedProduct()) {
      for (const rate of getSelectedProduct()?.currentRateTable.rates) {
        if (rate.duration === form.values[nameSpace].duration) return rate;
      }
    }
    return null;
  };

  if (listProductsAPI.loading) return <Loading size={'2x'}/>;
  if (listProductsAPI.response) {
    return (
      <>
        <Col sm={12}>
          {!hidden.includes(fields.PRINCIPAL_AT_INCEPTION)
          && <Field
            component={TextField}
            label={'Investment Value'}
            name={withNameSpace(nameSpace, fields.PRINCIPAL_AT_INCEPTION)}
            readOnly={readOnly.includes(fields.PRINCIPAL_AT_INCEPTION)}
            required
          />
          }
        </Col>
        <Col sm={12}>
          <Field
            component={TextField}
            label={'Product'}
            name={withNameSpace(nameSpace, fields.PRODUCT)}
            readOnly={readOnly.includes(fields.PRODUCT)}
            required
            select
            style={{ width: '15rem' }}
          >
            {listProductsAPI.response.data
              .filter((product) => product.currentRateTable)
              .map((product) => (
                <MenuItem key={product.uuid} value={product.uuid}>{product.name}</MenuItem>
              ))
            }
          </Field>
        </Col>
        <Col sm={12}>
          <Field
            component={TextField}
            label={'Duration'}
            name={withNameSpace(nameSpace, fields.DURATION)}
            readOnly={readOnly.includes(fields.DURATION)}
            required
            select
            style={{ width: '6rem' }}
          >
            {getSelectedProduct()?.currentRateTable.rates.map((rate) => (
              <MenuItem key={rate.duration} value={rate.duration}>
                {rate.duration}
              </MenuItem>
            ))}
          </Field>
        </Col>
        <Col sm={12}>
          <Field
            component={TextField}
            label={'Interest Rate'}
            name={withNameSpace(nameSpace, fields.INTEREST_RATE)}
            readOnly={
              readOnly.includes(fields.INTEREST_RATE)
              || getSelectedRate()?.interestRateOverrideNotPermitted
            }
            required
            style={{ width: '7rem' }}
          />
        </Col>
      </>
    );
  }
  return null;
};

FixedInterestNoteForm.defaultProps = {
  hidden: [],
  readOnly: [],
};

export default FixedInterestNoteForm;
export {
  initialValues as FixedInterestNoteFormInitialValues,
  schema as FixedInterestNoteFormSchema,
};
