import { faPencil } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, Table } from 'capitalroadkit';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import { parseEnum } from '../../formatters';

const PartyTable = ({ api, data }) => {
  const history = useHistory();
  const columns = useMemo(() => [
    {
      id: 'party',
      Header: '',
      Footer: '',
      columns: [
        {
          Header: 'uuid',
          accessor: 'uuid',
        },
        {
          Header: 'Name',
          accessor: 'name',
          Cell: ({ row: { values }, cell: { value } }) => (
            <Link to={`/secure/clients/${values.uuid}`}>{value}</Link>
          ),
        },
        {
          Header: 'Type',
          accessor: 'type',
          Cell: ({ cell: { value } }) => parseEnum(value),
        },
        {
          id: 'actions',
          Cell: ({ row: { values } }) => (
            <FontAwesomeIcon
              icon={faPencil}
              onClick={(() => history.push(`/secure/clients/${values.uuid}/update`))}
              style={{ cursor: 'pointer', fontSize: '1rem' }}
            />
          ),
          headerStyle: {
            width: '3rem',
          },
        },
      ],
    },
  ], []);

  return (
        <Table
            api={api}
            columns={columns}
            data={data}
            dataKey={'uuid'}
            hiddenColumns={['uuid']}
            width={ '40rem' }
        />
  );
};

export default PartyTable;
