import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTheme } from 'capitalroadkit';
import React from 'react';

const Loading = ({ size }) => {
  const theme = useTheme();

  return (
    <div style={{ marginTop: '1rem', textAlign: 'center', width: '100%' }}>
      <FontAwesomeIcon
        className={'loader-animation'}
        color={theme.palette.primary.main}
        icon={faSpinnerThird}
        size={size}
      />
    </div>
  );
};

export default Loading;
