import React from 'react';
import * as Yup from 'yup';

import { IndividualFormInitialValues, IndividualFormSchema } from './IndividualForm';
import { PartyAttribuesSchema, PartyAttributesFields, PartyAttributesValues } from './PartyAttributesForm';

const fields = {
  ...PartyAttributesFields,
  LINKED_PARTIES: 'linkedParties',
};

const initialValues = {
  ...PartyAttributesValues,
  [fields.LINKED_PARTIES]: [
    {
      party: IndividualFormInitialValues,
      role: 'JOINT_OWNER',
    }, {
      party: IndividualFormInitialValues,
      role: 'JOINT_OWNER',
    },
  ],
};

const schema = Yup.object({
  ...PartyAttribuesSchema,
  [fields.LINKED_PARTIES]: Yup.array()
    .of(IndividualFormSchema)
    .min(2, 'A minimum of 2 individuals').max(2, 'A maximum of 2 individuals'),
});

export { initialValues as JointIndividualFormInitialValues, schema as JointIndividualFormSchema };
