import { useState } from 'react';

const useCookies = (key, initialValue) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const name = `${key}=`;
      const decodedCookie = decodeURIComponent(document.cookie);
      const ca = decodedCookie.split(';');
      for (const cookie of ca) {
        let c = cookie;
        while (c.charAt(0) === ' ') c = c.substring(1);
        if (c.indexOf(name) === 0) return c.substring(name.length, c.length);
      }
      return initialValue;
    } catch (error) {
      return initialValue;
    }
  });

  const setValue = (value) => {
    try {
      setStoredValue(value);
      document.cookie = `${key}=${value};path=/`;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  return [storedValue, setValue];
};

export default useCookies;
