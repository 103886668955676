import {
  Button, Col, Grid, Page, useTheme,
} from 'capitalroadkit';
import { Form, FormikProvider, useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import LoginForm, { LoginFormInitialValues, LoginFormSchema } from './LoginForm';

import { authenticateAxios } from '../../api/AuthResource';
import Error from '../../components/Error/Error';
import useAPI from '../../hooks/useAPI';
import useCookies from '../../hooks/useCookies';
import useSessionStorage from '../../hooks/useSessionStorage';
import { buildNumber } from '../../version';

const Login = () => {
  const history = useHistory();
  const theme = useTheme();

  const [, setAuthToken] = useCookies('authToken', null);
  const [, setUser] = useSessionStorage('user', null);

  const authenticateAPI = useAPI(authenticateAxios(), false);

  useEffect(() => {
    if (!authenticateAPI.loading && authenticateAPI.response) {
      setUser(null);
      setAuthToken(authenticateAPI.response.data.Authorization);
      history.push(history.location.query?.forward || '/');
    }
  }, [authenticateAPI.loading, authenticateAPI.response]);

  const loginForm = useFormik({
    initialValues: LoginFormInitialValues,
    onSubmit: (values) => authenticateAPI.fetch({}, values),
    validationSchema: LoginFormSchema,
  });

  return (
    <Page id={'login'}>
      <Grid>
        <Col sm={12} style={{ textAlign: 'center' }}>
          <img
            alt={theme.skin}
            src={theme.images.logo[theme.mode].large}
            style={{ height: '6rem' }}
          />
        </Col>
        <Col sm={12} style={{ textAlign: 'center' }}>
          <h4>Login</h4>
        </Col>
        <Col sm={12}>
          <FormikProvider value={loginForm}>
            <Form>
              <Grid nested>
                <LoginForm/>
                <Col sm={12} style={{ textAlign: 'right' }}>
                  <Button
                    color={'primary'}
                    loading={authenticateAPI.loading}
                    type={'submit'}
                  >
                    Login
                  </Button>
                  {authenticateAPI.error
                  && <Error center error={authenticateAPI.error.response.data}/>
                  }
                </Col>
              </Grid>
            </Form>
          </FormikProvider>
        </Col>
        <Col sm={12} style={{ textAlign: 'center' }}>
          {/* <Link to={'/forgot-username'}>Forgot username</Link> / */}
          <Link to={'/reset-password'}>Forgot password</Link>
        </Col>
        <Col style={{ textAlign: 'center' }}><small>React: {buildNumber}</small></Col>
      </Grid>
    </Page>
  );
};

export default Login;
