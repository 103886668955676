import {
  Card, CardBody, useTheme,
} from 'capitalroadkit';
import React from 'react';

const LandingLayout = ({ children }) => {
  const theme = useTheme();

  return (
    <div
      style={{
        backgroundImage: `url(${theme.images.splash})`,
        backgroundSize: 'cover',
        alignItems: 'start',
        justifyContent: 'center',
        display: 'flex',
        height: '100vh',
      }}
    >
      <Card
        style={{
          height: 'auto', marginTop: '124px', position: 'relative', width: '400px',
        }}
      >
        <CardBody>
          {children}
        </CardBody>
      </Card>
    </div>
  );
};

export default LandingLayout;
