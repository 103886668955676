export const getTitles = () => ([
  {
    value: 'Dr',
    label: 'Dr',
  },
  {
    value: 'Miss',
    label: 'Miss',
  },
  {
    value: 'Ms',
    label: 'Ms',
  },
  {
    value: 'Mr',
    label: 'Mr',
  },
  {
    value: 'Mrs',
    label: 'Mrs',
  },
  {
    value: 'Sir',
    label: 'Sir',
  },
]);

export class PartyFormatter {
  static investmentAccountOwnerRole = 'INVESTOR';

  static investmentAccountLinkedPartyRole = {
    businessRole: 'INVESTOR',
    authRole: {
      uuid: 'INVESTOR',
    },
  };

  static TFNExemptionTypes = [
    {
      value: '333333333',
      label: 'Under 16',
    },
    {
      value: '444444441',
      label: 'Pensioner receiving Centrelink or Service Pension',
    },
    {
      value: '444444442',
      label: 'Not required to lodge tax return',
    },
    {
      value: '555555555',
      label: 'In the business of providing consumer or business finance',
    },
    {
      value: '666666666',
      label: 'Norfolk Island resident',
    },
    {
      value: '777777777',
      label: 'Non resident',
    },
    {
      value: '888888888',
      label: 'Invalid TFN recorded',
    },
    {
      value: '000000000',
      label: 'Not provided',
    },
  ];

  static TINExemptionTypes = [
    {
      value: 'NOT_PROVIDED',
      label: 'Not provided',
    },
    {
      value: 'NOT_ISSUED',
      label: 'Has not been issued a tax identifier',
    },
    {
      value: 'NOT_ISSUED_BY_COUNTRY',
      label: 'Country of tax residency does not issue a tax identifier',
    },
    {
      value: 'NOT_REQUIRED_BY_COUNTRY',
      label: 'Country of tax residency does not require a tax identifier',
    },
  ];

  static settlorExemptionReasons = [
    {
      value: 'ASSET_CONTRIBUTION_LESS_THAN_10000',
      label: 'The material asset contribution to the trust by the settlor at the time the trust is established is less than $10,000',
    },
    {
      value: 'SETTLOR_IS_DECEASED',
      label: 'The settlor is deceased',
    },
  ];

  static employmentStatusTypes = [
    {
      value: 'UNKNOWN',
      label: 'Unknown',
    },
    {
      value: 'EMPLOYED',
      label: 'Employed',
    },
    {
      value: 'RETIRED',
      label: 'Retired',
    },
    {
      value: 'SELF_EMPLOYED',
      label: 'Self Employed',
    },
    {
      value: 'STUDENT',
      label: 'Student',
    },
    {
      value: 'NOT_EMPLOYED',
      label: 'Not Employed',
    },
  ];

  static genderTypes = [
    {
      value: 'M',
      label: 'Male',
    },
    {
      value: 'F',
      label: 'Female',
    },
  ];

  static partyTypes = [
    {
      value: 'INDIVIDUAL',
      label: 'Individual',
    },
    {
      value: 'JOINT_INDIVIDUAL',
      label: 'Joint individual',
    },
    {
      value: 'TRUST',
      label: 'Trust',
    },
    {
      value: 'COMPANY',
      label: 'Company',
    },
  ];

  static companyRoles = [
    {
      value: 'DIRECTOR',
      label: 'Director',
    },
    {
      value: 'SECRETARY',
      label: 'Secretary',
    },
  ];

  static companyTypes = [
    {
      value: 'PRIVATE',
      label: 'Private',
    },
    {
      value: 'PROPRIETARY',
      label: 'Proprietary',
    },
  ];

  static titleTypes = [
    {
      value: 'Dr',
      label: 'Dr',
    },
    {
      value: 'Miss',
      label: 'Miss',
    },
    {
      value: 'Ms',
      label: 'Ms',
    },
    {
      value: 'Mr',
      label: 'Mr',
    },
    {
      value: 'Mrs',
      label: 'Mrs',
    },
    {
      value: 'Sir',
      label: 'Sir',
    },
  ];

  static orgTypes = [
    {
      label: 'Company',
      value: 'company',
    },
    {
      label: 'Trust',
      value: 'trust',
    },
    {
      label: 'Other',
      value: 'other',
    },
    {
      label: 'SMSF',
      value: 'smsf',
    },
    {
      label: 'Sole Trader',
      value: 'sole-trader',
    },
    {
      label: 'GSE',
      value: 'gse',
    },
    {
      label: 'SMSF Pension',
      value: 'smsf-pension',
    },
    {
      label: 'Uknown',
      value: 'unknown',
    },
    {
      label: 'Charity',
      value: 'charity',
    },
  ];

  static getCompanyRoles() {
    return this.companyRoles;
  }

  static getTFNExemptionTypes() {
    return this.TFNExemptionTypes;
  }

  static getTINExemptionTypes() {
    return this.TINExemptionTypes;
  }

  static getOrgTypes() {
    return this.orgTypes;
  }

  static getSettlorExemptionReasons() {
    return this.settlorExemptionReasons;
  }

  static getEmploymentStatusTypes() {
    return this.employmentStatusTypes;
  }

  static getGenderTypes() {
    return this.genderTypes;
  }

  static getTitleTypes() {
    return this.titleTypes;
  }

  static getPartyTypes() {
    return this.partyTypes;
  }

  static getForeignTaxCountries() {
    return [
      {
        value: 'UK',
        label: 'United Kingdom',
      },
      {
        value: 'US',
        label: 'United States',
      },
    ];
  }

  static isLinkedPartyOwner(roles) {
    // return true if role is correct for view
    // view based off prop: relatedPartiesView
    let isOwner = false;
    roles.forEach((role) => {
      if (role.businessRole === this.investmentAccountOwnerRole) {
        isOwner = true;
      }
    });
    return isOwner;
  }
}
