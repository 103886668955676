import {
  Button, Card, CardBody, CardHeader, CardTitle, Col, Grid, Page, PageHeader, Radio, RadioGroup,
} from 'capitalroadkit';
import { Form, FormikProvider, useFormik } from 'formik';
import * as _ from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';

import PartialRolloverForm, { PartialRolloverFormInitialValues, PartialRolloverFormSchema } from './PartialRolloverForm';
import RedeemForm, { RedeemFormInitialValues, RedeemFormSchema } from './RedeemForm';
import RolloverForm, { RolloverFormInitialValues, RolloverFormSchema } from './RolloverForm';

import { getNoteByUuidAxios, processRedeemAxios, processRollOverAxios } from '../../../../api/NoteResource';
import { getPartyNotesAxios } from '../../../../api/PartyResource';
import Error from '../../../../components/Error/Error';
import Loading from '../../../../components/Loading/Loading';
import { toAPIDate } from '../../../../formatters';
import useAPI from '../../../../hooks/useAPI';
import NotesTable from '../../NotesTable';
import NoteOverview from '../NoteOverview';

export const Type = Object.freeze({
  Rollover: 'ROLLOVER',
  Redeem: 'REDEEM',
  'Partial Rollover': 'PARTIAL',
});

const Instructions = () => {
  const history = useHistory();
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    getNoteAPI.fetch();
  }, [params.uuid]);

  const [type, setType] = useState(history.location.query?.type || Type.Rollover);

  const rolloverForm = useFormik({
    initialValues: RolloverFormInitialValues,
    onSubmit: (values) => {
      const apiValues = values;
      apiValues.rolloverOfPrincipal = true;
      apiValues.principalAmountToRollover = null;
      apiValues.rolloverOfInterest = true;
      apiValues.interestAmountToRollover = null;
      apiValues.date = toAPIDate(apiValues.date);
      delete apiValues.noteDefinition.principalAtInception;
      processRolloverAPI.fetch({}, apiValues);
    },
    validationSchema: RolloverFormSchema,
  });

  const redeemForm = useFormik({
    initialValues: RedeemFormInitialValues,
    onSubmit: (values) => {
      const apiValues = _.clone(values);
      apiValues.rolloverOfPrincipal = false;
      apiValues.rolloverOfInterest = false;
      apiValues.date = toAPIDate(apiValues.date);
      apiValues.settlementDate = toAPIDate(apiValues.settlementDate);
      processRedeemAPI.fetch({}, apiValues);
    },
    validationSchema: RedeemFormSchema,
  });

  const partialRolloverForm = useFormik({
    initialValues: PartialRolloverFormInitialValues,
    onSubmit: (values) => {
      const apiValues = {};

      let { amountToRollover } = values;
      amountToRollover = getNoteAPI.response.data.capitalisedInterest.value - amountToRollover;
      if (amountToRollover < 0) {
        apiValues.interestAmountToRollover = { currency: 'AUD', value: getNoteAPI.response.data.capitalisedInterest.value };
        if (getNoteAPI.response.data.capitalisedInterest.value === 0) {
          apiValues.interestAmountToRollover = null;
        }
        amountToRollover *= -1;
        apiValues.principalAmountToRollover = { currency: 'AUD', value: amountToRollover };
      } else {
        apiValues.interestAmountToRollover = { currency: 'AUD', value: values.amountToRollover };
        apiValues.principalAmountToRollover = { currency: 'AUD', value: 0 };
      }

      apiValues.date = toAPIDate(values.date);
      apiValues.noteDefinition = {};
      apiValues.noteDefinition.product = { uuid: values.noteDefinition.product };
      apiValues.noteDefinition.duration = values.noteDefinition.duration;
      apiValues.noteDefinition.interestRate = values.noteDefinition.interestRate;

      processPartialRolloverAPI.fetch({}, apiValues);
    },
    validationSchema: PartialRolloverFormSchema,
  });

  const getNoteAPI = useAPI(getNoteByUuidAxios(params.uuid), false);

  useEffect(() => {
    if (getNoteAPI.response && !getNoteAPI.loading) {
      getPartyNotesAPI.fetch();
    }
  }, [getNoteAPI.response, getNoteAPI.loading]);

  const getPartyNotesAPI = useAPI(getPartyNotesAxios(getNoteAPI.response?.data.party.uuid), false);

  const processRolloverAPI = useAPI(processRedeemAxios(getNoteAPI.response?.data.uuid), false);

  useEffect(() => {
    if (!processRolloverAPI.loading && processRolloverAPI.response) {
      enqueueSnackbar('Rollover sent for processing');
      history.push(`/secure/notes/${getNoteAPI.response.data.uuid}`);
    }
  }, [processRolloverAPI.loading, processRolloverAPI.response]);

  const processRedeemAPI = useAPI(processRedeemAxios(getNoteAPI.response?.data.uuid), false);

  useEffect(() => {
    if (!processRedeemAPI.loading && processRedeemAPI.response) {
      enqueueSnackbar('Redemption sent for processing');
      history.push(`/secure/notes/${getNoteAPI.response.data.uuid}`);
    }
  }, [processRedeemAPI.loading, processRedeemAPI.response]);

  const processPartialRolloverAPI = useAPI(
    processRollOverAxios(getNoteAPI.response?.data.uuid),
    false,
  );

  useEffect(() => {
    if (!processPartialRolloverAPI.loading && processPartialRolloverAPI.response) {
      enqueueSnackbar('Partial rollover sent for processing');
      processRedeemAPI.fetch({}, {
        rolloverOfPrincipal: false,
        rolloverOfInterest: false,
        date: toAPIDate(partialRolloverForm.values.date),
        settlementDate: toAPIDate(partialRolloverForm.values.settlementDate),
      });
      history.push(`/secure/notes/${getNoteAPI.response.data.uuid}`);
    }
  }, [processPartialRolloverAPI.loading, processPartialRolloverAPI.response]);

  if (getNoteAPI.response) {
    return (
      <Page id={'instructions'}>
        <Grid>
          <PageHeader
            heading={getNoteAPI.response.data.noteReferenceId}
            subHeading={'Instructions'}
          >
            {getNoteAPI.response?.data.status === 'ACTIVE'
            && <Col sm={12} style={{ textAlign: 'right', flex: 'space-evenly' }}>
              <Link to={{ pathname: `/secure/notes/${getNoteAPI.response.data.uuid}` }}>
                <Button color={'primary'}>Details</Button>
              </Link>
              <Link style={{ marginLeft: '.5rem' }} to={{ pathname: `/secure/notes/${getNoteAPI.response.data.uuid}/maturity-instruction` }}>
                <Button color={'primary'}>Update Maturity Instruction</Button>
              </Link>
              <Link style={{ marginLeft: '.5rem' }} to={{ pathname: `/secure/notes/${getNoteAPI.response.data.uuid}/payments` }}>
                <Button color={'primary'}>Payments</Button>
              </Link>
            </Col>
            }
          </PageHeader>
          {getPartyNotesAPI.response
          && <Col sm={12}>
            <Card>
              <NotesTable
                data={getPartyNotesAPI.response.data.filter((note) => note.status === 'ACTIVE')}
                party
                rowClick={(row) => history.push(`/secure/notes/${row.values.uuid}/instructions`)}
                selected={getNoteAPI.response.data.uuid}
              />
            </Card>
          </Col>
          }
          <Col lg={8} md={12} sm={12}>
            <Card>
              <CardHeader><CardTitle>Instruction</CardTitle></CardHeader>
              <CardBody>
                <Grid nested>
                  <Col sm={12}>
                    <div>
                      <RadioGroup field={{ name: 'type', onChange: (e, value) => setType(value), value: type }} row>
                        <Radio value={Type.Rollover}>Rollover</Radio>
                        <Radio value={Type.Redeem}>Redeem</Radio>
                        <Radio value={Type['Partial Rollover']}>Partial Rollover/Redeem</Radio>
                      </RadioGroup>
                    </div>
                  </Col>
                  <Col sm={12}>
                    {type === Type.Rollover
                    && <FormikProvider value={rolloverForm}>
                      <Form>
                        <Grid nested>
                          <RolloverForm
                            form={rolloverForm}
                            investmentValue={getNoteAPI.response.data.balance.value}
                          />
                          <Col sm={12} style={{ textAlign: 'right' }}>
                            <Button
                              color={'success'}
                              loading={processRolloverAPI.loading}
                              type={'submit'}
                            >
                              Submit
                            </Button>
                            {processRolloverAPI.error
                            && <Error error={processRolloverAPI.error.response.data}/>
                            }
                          </Col>
                        </Grid>
                      </Form>
                    </FormikProvider>
                    }
                    {type === Type.Redeem
                    && <FormikProvider value={redeemForm}>
                      <Form>
                        <Grid nested>
                          <RedeemForm form={redeemForm}/>
                          <Col sm={12} style={{ textAlign: 'right' }}>
                            <Button
                              color={'success'}
                              loading={processRedeemAPI.loading}
                              type={'submit'}
                            >
                              Submit
                            </Button>
                            {processRedeemAPI.error
                            && <Error error={processRedeemAPI.error.response.data}/>
                            }
                          </Col>
                        </Grid>
                      </Form>
                    </FormikProvider>
                    }
                    {type === Type['Partial Rollover']
                    && <FormikProvider value={partialRolloverForm}>
                      <Form>
                        <Grid nested>
                          <PartialRolloverForm
                            form={partialRolloverForm}
                            note={getNoteAPI.response.data}
                          />
                          <Col sm={12} style={{ textAlign: 'right' }}>
                            <Button
                              color={'success'}
                              loading={processPartialRolloverAPI.loading}
                              type={'submit'}
                            >
                              Submit
                            </Button>
                            {processPartialRolloverAPI.error
                            && <Error error={processPartialRolloverAPI.error.response.data}/>
                            }
                          </Col>
                        </Grid>
                      </Form>
                    </FormikProvider>
                    }
                  </Col>
                </Grid>
              </CardBody>
            </Card>
          </Col>
          <Col lg={4} md={12} sm={12}>
            <Card>
              <CardHeader><CardTitle>Note</CardTitle></CardHeader>
              <CardBody>
                <NoteOverview getNote={getNoteAPI.fetch} note={getNoteAPI.response.data} small/>
              </CardBody>
            </Card>
          </Col>
        </Grid>
      </Page>
    );
  }
  if (getNoteAPI.loading) return <Loading size={'3x'}/>;
  return null;
};

export default Instructions;
