import axios from 'axios';

import { host } from './utilities';

export const createParty = () => (config, data = {}) => axios.post(`${host()}/party`, data, config);

export const updateIndividualPartyAxios = (uuid) => (config, data = {}) => axios.put(`${host()}/party/${uuid}/individual`, data, config);

export const updateOrganisationPartyAxios = (uuid) => (config, data = {}) => axios.put(`${host()}/party/${uuid}/organisation`, data, config);

export const updateContactDetailsPartyAxios = (uuid) => (config, data = {}) => axios.put(`${host()}/party/${uuid}/contact-details`, data, config);

export const updateBankDetailsPartyAxios = (uuid) => (config, data = {}) => axios.put(`${host()}/party/${uuid}/bank-account-details`, data, config);

export const getPartyNotesAxios = (uuid) => (config) => axios.get(`${host()}/party/${uuid}/notes`, config);

export const getPartyByUuidAxios = (uuid) => (config) => axios.get(`${host()}/party/${uuid}`, config);

export const createNewFixedInterestNoteAxios = (uuid) => (config, data = {}) => axios.post(`${host()}/party/${uuid}/fixed-interest-note`, data, config);

export const searchPartiesAxios = () => (config) => axios.get(`${host()}/party/search`, config);

export const listPartiesAxios = () => (config) => axios.get(`${host()}/party/list`, config);

export const getPartyDocumentsAxios = (uuid) => (config) => axios.get(`${host()}/party/${uuid}/notes/documents`, config);

export const generateABABankFileAxios = (uuid) => (config) => axios.get(`${host()}/product/${uuid}/aba-bank-file`, config);
