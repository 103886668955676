import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button, Col, Grid, Page, useTheme,
} from 'capitalroadkit';
import { Form, FormikProvider, useFormik } from 'formik';
import React from 'react';
import { Link } from 'react-router-dom';

import ResetPasswordInitForm, { ResetPasswordInitFormInitialValues, ResetPasswordInitFormSchema } from './ResetPasswordInitForm';

import { initiateResetPasswordAxios } from '../../api/AuthResource';
import Error from '../../components/Error/Error';
import useAPI from '../../hooks/useAPI';

const ResetPasswordInit = () => {
  const theme = useTheme();

  const initiateResetPasswordAPI = useAPI(initiateResetPasswordAxios(), false);

  const resetPasswordInitForm = useFormik({
    initialValues: ResetPasswordInitFormInitialValues,
    onSubmit: (values) => initiateResetPasswordAPI.fetch({}, values),
    validationSchema: ResetPasswordInitFormSchema,
  });

  return (
    <Page id={'reset-password-init'}>
      <Grid>
        <Col sm={12} style={{ textAlign: 'center' }}>
          <img
            alt={theme.skin}
            src={theme.images.logo[theme.mode].large}
            style={{ height: '6rem' }}
          />
        </Col>
        <Col sm={12} style={{ textAlign: 'center' }}>
          <h4>Reset password</h4>
        </Col>
        <Col sm={12}>
          <FormikProvider value={resetPasswordInitForm}>
            <Form>
              <Grid nested>
                <ResetPasswordInitForm/>
                <Col sm={12} style={{ textAlign: 'right' }}>
                  <Link to={'/login'}>
                    <Button color={'secondary'} loading={resetPasswordInitForm.loading}>
                      Back
                    </Button>
                  </Link>
                  <Button color={'primary'} loading={resetPasswordInitForm.loading}
                          style={{ marginLeft: '.5rem' }} type={'submit'}>
                    Reset password
                  </Button>
                  {initiateResetPasswordAPI.error
                  && <Error error={initiateResetPasswordAPI.error.response.data}/>}
                </Col>
                {initiateResetPasswordAPI.response
                && <Col sm={12}>
                  <FontAwesomeIcon icon={faCheckCircle} style={{ color: theme.palette.success.main }}/>
                  <small> A link has been set to your registered email address</small>
                </Col>}
              </Grid>
            </Form>
          </FormikProvider>
        </Col>
      </Grid>
    </Page>
  );
};

export default ResetPasswordInit;
