import { Col, TextField } from 'capitalroadkit';
import { Field } from 'formik';
import React from 'react';
import * as Yup from 'yup';

const fields = {
  DATE: 'date',
  SETTLEMENT_DATE: 'settlementDate',
};

const initialValues = {
  [fields.DATE]: '',
  [fields.SETTLEMENT_DATE]: '',
};

const schema = Yup.object({
});

const RedeemForm = ({ form }) => (
  <>
    <Col sm={12}>
      <Field
        component={TextField}
        label={'Date'}
        mask={'99/99/9999'}
        name={fields.DATE}
        style={{ width: '15rem' }}
      />
    </Col>
    <Col sm={12}>
      <Field
        component={TextField}
        label={'Settlement Date'}
        mask={'99/99/9999'}
        name={fields.SETTLEMENT_DATE}
        style={{ width: '15rem' }}
      />
    </Col>
  </>
);

export default RedeemForm;
export { initialValues as RedeemFormInitialValues, schema as RedeemFormSchema };
