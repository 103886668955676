import { Table } from 'capitalroadkit';
import React, { useEffect, useMemo, useState } from 'react';

import { getNoteDocumentURLAxios } from '../../../api/NoteDocumentResource';
import { openLink } from '../../../components/DownloadLink/DownloadLink';
import { toDateString } from '../../../formatters';
import useAPI from '../../../hooks/useAPI';

const NoteDocumentsTable = ({ data }) => {
  const [selectedDocumentUuid, setSelectedDocumentUuid] = useState(null);

  const getNoteDownloadURLAPI = useAPI(getNoteDocumentURLAxios(selectedDocumentUuid), false);

  useEffect(() => {
    if (selectedDocumentUuid) {
      getNoteDownloadURLAPI.fetch({});
    }
  }, [selectedDocumentUuid]);

  useEffect(() => {
    if (getNoteDownloadURLAPI.response) {
      openLink(getNoteDownloadURLAPI.response.data);
      getNoteDownloadURLAPI.reset();
      setSelectedDocumentUuid(null);
    }
  }, [getNoteDownloadURLAPI.response]);

  const columns = useMemo(() => [
    {
      id: 'documents',
      Header: '',
      Footer: '',
      columns: [
        {
          Header: 'uuid',
          accessor: 'uuid',
        },
        {
          Header: 'Name',
          accessor: 'name',
        },
        {
          Header: 'Date uploaded',
          accessor: 'dateUploaded',
          Cell: ({ cell: { value } }) => toDateString(value),
        },
      ],
    },
  ], []);

  return (
    <Table
      columns={columns}
      data={data}
      dataKey={'uuid'}
      hiddenColumns={['uuid']}
      rowClick={(row) => setSelectedDocumentUuid(row.values.uuid)}
      sortBy={[
        {
          id: 'dateCreated',
          desc: true,
        },
      ]}
    />
  );
};

export default NoteDocumentsTable;
