import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import useCookies from './useCookies';

const useAPI = (api, immediate = true, initialParams) => {
  const history = useHistory();

  const [authToken] = useCookies('authToken', null);

  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);

  const [lastParams, setLastParams] = useState(null);

  const getHeaders = () => ({
    Authorization: authToken,
  });

  const fetch = useCallback((params = {}, data = null, headers = {}) => {
    setLoading(true);
    setError(null);

    if (data) {
      return api({ headers: { ...getHeaders(), ...headers }, params }, data)
        .then((resp) => setResponse(resp))
        .catch((err) => { setError(err); handleError(err); })
        .finally(() => {
          setLoading(false);
          setLastParams(params);
        });
    }

    return api({ headers: { ...getHeaders(), ...headers }, params })
      .then((resp) => setResponse(resp))
      .catch((err) => { setError(err); handleError(err); })
      .finally(() => {
        setLoading(false);
        setLastParams(params);
      });
  }, [api]);

  const handleError = (err) => {
    if (err.message === 'Network Error') history.push('/error/network-error');
    else {
      switch (err.response?.data.errorCode) {
        case 40100:
          history.push('/error/40100');
          break;

        default:
          break;
      }
    }
  };

  const reset = () => {
    setLoading(false);
    setResponse(null);
    setError(null);
  };

  useEffect(() => {
    if (immediate) fetch(initialParams);
  }, [immediate]);

  return {
    error, fetch, immediate, lastParams, loading, response, reset,
  };
};

export default useAPI;
