import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { Route as RouterRoute } from 'react-router-dom';

import { getUserAxios } from '../../api/AuthUserResource';
import useAPI from '../../hooks/useAPI';
import useCookies from '../../hooks/useCookies';
import useSessionStorage from '../../hooks/useSessionStorage';
import Loading from '../Loading/Loading';

const AuthFilter = ({
  authorized, children, setUser, user,
}) => {
  const history = useHistory();

  const [authToken, setAuthToken] = useCookies('authToken', null);

  const getUserAPI = useAPI(getUserAxios(), false);

  useEffect(() => {
    if (!getUserAPI.loading && getUserAPI.response) setUser(getUserAPI.response.data);
    if (!getUserAPI.loading && getUserAPI.error) {
      setAuthToken(null);
      history.push({ pathname: '/login', query: { forward: history.location.pathname } });
    }
  }, [getUserAPI.loading, getUserAPI.response, getUserAPI.error]);

  useEffect(() => {
    if (authorized) {
      if (authToken && authToken !== 'null') {
        if (!user) getUserAPI.fetch();
      } else {
        history.push({ pathname: '/login', query: { forward: history.location.pathname } });
      }
    }
  }, []);

  if ((authorized && (authToken && authToken !== 'null') && user) || !authorized) return children;
  return <Loading size={'3x'}/>;
};

const Route = ({
  authorized, component: Component, layout: Layout, setMode, setSkin, ...props
}) => {
  const [user, setUser] = useSessionStorage('user', null);

  return (
        <RouterRoute exact {...props}>
            <AuthFilter authorized={authorized} setUser={setUser} user={user}>
                <Layout user={user}>
                    <Component setMode={setMode} setSkin={setSkin} user={user}/>
                </Layout>
            </AuthFilter>
        </RouterRoute>
  );
};

export default Route;
