import Radio from '@material-ui/core/Radio';
import { Table } from 'capitalroadkit';
import React, { useMemo } from 'react';

import { toCurrency, toPercent } from '../../../formatters';

const ProductRateTable = ({
  data, product, rowClick, selected,
}) => {
  const columns = useMemo(() => [
    {
      id: 'productRate',
      Header: '',
      Footer: '',
      columns: [
        {
          id: 'selected',
          Cell: ({ row }) => (
            <Radio
              checked={row.values.duration === selected}
              color={'primary'}
              style={{ height: '1rem', padding: 0 }}
              value={row.values.uuid}
            />
          ),
          headerStyle: {
            width: '3rem',
          },
        },
        {
          Header: 'Term',
          accessor: 'duration',
          Cell: ({ cell: { value } }) => `${value} months`,
        },
        {
          Header: 'Interest Rate',
          accessor: 'interestRate',
          Cell: ({ cell: { value } }) => toPercent(value),
        },
        {
          Header: 'Min. Investment Amount',
          Cell: () => toCurrency(product.valueOfMinimumInvestment.value),
        },
        {
          Header: 'Issuer',
          Cell: () => product.issuer,
        },
      ],
    },
  ], [selected]);

  return (
    <Table
      columns={columns}
      data={data}
      dataKey={'duration'}
      rowClick={rowClick}
      selected={selected}
      sortBy={[
        {
          id: 'duration',
          desc: false,
        },
      ]}
    />
  );
};

export default ProductRateTable;
