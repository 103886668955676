import {
  Button, Col, Grid, TextField,
} from 'capitalroadkit';
import { Field } from 'formik';
import React from 'react';
import * as Yup from 'yup';

const fields = {
  FILTER: 'filter',
};

const initialValues = {
  [fields.FILTER]: '',
};

const schema = Yup.object({
});

const PartySearchForm = () => (
  <>
    <Col lg={2} sm={8}>
      <Field component={TextField} fullWidth label={'Filter'} name={fields.FILTER}/>
    </Col>
  </>
);

export default PartySearchForm;
export { initialValues as PartySearchFormInitialValues, schema as PartySearchFormSchema };
