import {
  faBars,
  faParking,
  faPowerOff,
  faReceipt,
  faTachometerAlt,
  faTools,
  faUserPlus,
} from '@fortawesome/free-solid-svg-icons';
import {
  faFileChartPie, faPiggyBank, faPlusCircle, faUsers,
} from '@fortawesome/pro-solid-svg-icons';
import {
  dark, IconButton, Tree, TreeItem, useTheme,
} from 'capitalroadkit';
import React, { useEffect, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';

import { logoutAxios } from '../api/AuthResource';
import useAPI from '../hooks/useAPI';
import useCookies from '../hooks/useCookies';

const DefaultLayout = ({ children, user }) => {
  const history = useHistory();
  const theme = useTheme();

  const [, setAuthToken] = useCookies('authToken', null);
  const [sideBarOpen, setSideBarOpen] = useState(false);

  const logoutAPI = useAPI(logoutAxios(), false);

  useEffect(() => {
    if (!logoutAPI.loading && logoutAPI.response) {
      setAuthToken(null);
      history.push('/login');
    }
  }, [logoutAPI.loading, logoutAPI.response]);

  return (
    <div
      style={{
        backgroundColor: theme.palette.background.main,
        minHeight: '100vh',
        minWidth: '100%',
      }}
    >
      <div
        style={{
          position: 'fixed',
          top: '0',
          left: '0',
          zIndex: '1030',

          width: '20rem',
          height: '100%',
          overflow: 'auto',

          background: theme.palette.sidebar.main,
          backgroundImage: 'url("data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'28\' height=\'49\' viewBox=\'0 0 28 49\'%3E%3Cg fill-rule=\'evenodd\'%3E%3Cg id=\'hexagons\' fill=\'%23000000\' fill-opacity=\'0.1\' fill-rule=\'nonzero\'%3E%3Cpath d=\'M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z\'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")',
          transform: sideBarOpen ? 'translate3d(0, 0, 0)' : 'translate3d(-100%, 0, 0)',
          transition: 'all 195ms cubic-bezier(.4, 0, .6, 1) 0ms',

          color: dark.palette.text.base,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: '1rem',
          }}
        >
          <img
            alt={theme.skin}
            src={theme.images.logo.dark.large}
            style={{
              height: '5rem',
              marginBottom: '.9375rem',
            }}
          />
          <h4
            style={{
              color: dark.palette.text.header,
              textAlign: 'center',
              marginBottom: '.9375rem',
            }}
          >
            {user.name}
          </h4>
          <Tree>
            <TreeItem icon={faTachometerAlt} title={'Dashboard'} to={'/secure/admin'}/>
            <TreeItem icon={faUsers} title={'Clients'} to={'/secure/clients'}/>
            <TreeItem icon={faReceipt} title={'Notes'}>
              <TreeItem icon={faReceipt} title={'All Notes'} to={'/secure/notes'}/>
              <TreeItem icon={faPlusCircle} title={'New Note'} to={'/secure/notes/new'}/>
            </TreeItem>
            <TreeItem icon={faParking} title={'Products'} to={'/secure/products'}/>
            <TreeItem icon={faUserPlus} title={'New Client'} to={'/secure/new-party'}/>
            <TreeItem icon={faFileChartPie} title={'Reports'} to={'/secure/reports'}/>
            <TreeItem icon={faPiggyBank} title={'Banking'} to={'/secure/banking'}/>
            <TreeItem icon={faTools} title={'Settings'} to={'/secure/settings'}/>
          </Tree>
        </div>
      </div>
      <div
        onClick={sideBarOpen ? () => setSideBarOpen(false) : null}
        style={{
          position: 'fixed',
          top: '0',
          zIndex: '1000',

          width: sideBarOpen ? '100%' : '0',
          height: sideBarOpen ? '100%' : '0',

          background: 'rgba(0, 0, 0, .5)',
          opacity: sideBarOpen ? '1' : '0',

          transition: 'opacity 195ms cubic-bezier(.4, 0, .6, 1) 0ms',

          content: '',
        }}
      />
      <div>
        <div
          style={{
            position: 'sticky',
            top: '0',
            zIndex: '900',

            display: 'flex',
            alignItems: 'center',
            boxSizing: 'border-box',
            width: '100%',
            height: '3.75rem',
            padding: '0 .75rem',

            background: theme.palette.primary.main,
            color: '#ffffff',
          }}
        >
          <IconButton icon={faBars} onClick={() => setSideBarOpen(true)} size={'lg'}/>
          <NavLink style={{ color: 'rgb(255, 255, 255)', marginLeft: 'auto' }} to={'/secure/settings'}>
            <IconButton icon={faTools} size={'lg'}/>
          </NavLink>
          <IconButton
            icon={faPowerOff}
            onClick={() => logoutAPI.fetch()}
            size={'lg'}
            style={{ marginLeft: '.5rem' }}
          />
        </div>
      </div>
      {children}
    </div>
  );
};

export default DefaultLayout;
