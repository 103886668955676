import { faPencil, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Card,
  CardActions,
  CardBody,
  CardHeader,
  CardTitle,
  Checkbox,
  Col,
  Grid,
  IconButton, Modal,
} from 'capitalroadkit';
import React, { useEffect, useState } from 'react';

import { interestToBePaidAxios, interestToBeReinvestedAxios } from '../../../api/NoteResource';
import useAPI from '../../../hooks/useAPI';

const ReinvestModal = ({ getNote, note }) => {
  const [open, setOpen] = useState(false);

  const interestToBeReinvestedAPI = useAPI(interestToBeReinvestedAxios(note.uuid), false);

  useEffect(() => {
    if (!interestToBeReinvestedAPI.loading && interestToBeReinvestedAPI.response) {
      getNote();
    }
  }, [interestToBeReinvestedAPI.response, interestToBeReinvestedAPI.loading]);

  const interestToBePaidAPI = useAPI(interestToBePaidAxios(note.uuid), false);

  useEffect(() => {
    if (!interestToBePaidAPI.loading && interestToBePaidAPI.response) {
      getNote();
    }
  }, [interestToBePaidAPI.response, interestToBePaidAPI.loading]);

  const suspensionsHandler = (e, value) => {
    if (value) interestToBeReinvestedAPI.fetch();
    else interestToBePaidAPI.fetch();
  };

  return (
    <>
      <FontAwesomeIcon
        icon={faPencil}
        onClick={() => setOpen(true)}
        style={{ cursor: 'pointer', fontSize: '1rem', marginLeft: '.75rem' }}
      />
      <Modal open={open}>
        <Card style={{ textAlign: 'left' }}>
          <CardHeader>
            <CardTitle>Interest Distribution</CardTitle>
            <CardActions>
              <IconButton icon={faTimes} onClick={() => setOpen(false)}/>
            </CardActions>
          </CardHeader>
          <CardBody>
            <Grid nested>
              <Col sm={12}>
                <Checkbox
                  field={{
                    name: 'interestToBeReinvested',
                    onChange: suspensionsHandler,
                    value: note.interestToBeReinvested,
                  }}
                  label={'Reinvest interest'}
                />
              </Col>
            </Grid>
          </CardBody>
        </Card>
      </Modal>
    </>
  );
};

export default ReinvestModal;
