import MenuItem from '@material-ui/core/MenuItem';
import {CardBody, Checkbox, Col, Grid, TextField,} from 'capitalroadkit';
import {FastField, FieldArray} from 'formik';
import moment from 'moment';
import React, {useEffect, useRef} from 'react';
import * as Yup from 'yup';

import {AustralianLicenseFormInitialValues} from './SubForm/AustralianDriversLicenseForm';
import ContactDetailsForm, {
  ContactDetailsFormInitialValues,
  ContactDetailsFormSchema,
} from './SubForm/ContactDetailsForm';
import {IdentityDocumentForm} from './SubForm/IdentityDocumentForm';
import {PassportDocumentFormInitialValues} from './SubForm/PassportDocumentForm';
import TaxInformationForm, {TaxInformationFormInitialValues} from './SubForm/TaxInformationForm';

import {getCountriesAxios} from '../../api/ReferenceDataResource';
import Loading from '../../components/Loading/Loading';
import {getTitles, PartyFormatter} from '../../formatters/PartyFormatter';
import {resolveProperty, withNameSpace} from '../../functions';
import useAPIWithSessionStorage from '../../hooks/useAPIWithSessionStorage';

const fields = {
  TITLE: 'title',
  FIRST_NAME: 'firstName',
  MIDDLE_NAME: 'middleName',
  LAST_NAME: 'lastName',
  DATE_OF_BIRTH: 'dateOfBirth',
  GENDER: 'gender',
  POLITICALLY_EXPOSED: 'politicallyExposedPerson',
  TAX_INFORMATION_DETAILS: 'taxInformationDetails',
  CONTACT_DETAILS: 'contactDetails',
  EMPLOYMENT_STATUS: 'employmentStatus',
  COUNTRY_OF_ORIGIN: 'countryOfOrigin',
  PRIMARY_DOCUMENT_TYPE: 'primaryDocumentType',
  IDENTITY_DOCUMENTS: 'identityDocuments',
};

const initialValues = {
  [fields.TITLE]: '',
  [fields.FIRST_NAME]: '',
  [fields.MIDDLE_NAME]: '',
  [fields.LAST_NAME]: '',
  [fields.DATE_OF_BIRTH]: '',
  [fields.GENDER]: '',
  [fields.COUNTRY_OF_ORIGIN]: '',
  [fields.EMPLOYMENT_STATUS]: 'UNKNOWN',
  [fields.POLITICALLY_EXPOSED]: false,
  [fields.TAX_INFORMATION_DETAILS]: TaxInformationFormInitialValues,
  [fields.CONTACT_DETAILS]: ContactDetailsFormInitialValues,
  [fields.IDENTITY_DOCUMENTS]: [],
  [fields.PRIMARY_DOCUMENT_TYPE]: '',
  type: 'INDIVIDUAL',
};

const schema = Yup.object({
  [fields.TITLE]: Yup.mixed()
    .oneOf(PartyFormatter.getTitleTypes().map((title) => title.value))
    .required('Title is required'),
  [fields.FIRST_NAME]: Yup.string()
    .required('First name is required'),
  [fields.MIDDLE_NAME]: Yup.string().notRequired(),
  [fields.LAST_NAME]: Yup.string()
    .required('Last name is required'),
  [fields.COUNTRY_OF_ORIGIN]: Yup.string()
    .required('Country of origin is required'),
  [fields.EMPLOYMENT_STATUS]: Yup.string()
    .oneOf(PartyFormatter.getEmploymentStatusTypes().map((e) => e.value))
    .required('Employment status is required'),
  [fields.DATE_OF_BIRTH]: Yup.date()
    .typeError('Date must be a valid date')
    .transform((value, originalValue) => {
      value = moment(originalValue, 'DD/MM/YYYY');
      return value.isValid() ? value.toDate() : null;
    })
    .max(new Date())
    .required('Date of birth is required'),
  [fields.GENDER]: Yup.mixed()
    .oneOf(PartyFormatter.getGenderTypes()
      .map((gender) => gender.value))
    .required('Gender is required'),
  [fields.POLITICALLY_EXPOSED]: Yup.boolean(),
  // [fields.TAX_INFORMATION_DETAILS]: TaxInformationFormSchema,
  [fields.CONTACT_DETAILS]: ContactDetailsFormSchema,
  [fields.IDENTITY_DOCUMENTS]: Yup.array()
    .min(1, 'A minimum of 1 KYC document is required'),
});

const IndividualForm = ({
  form, nameSpace, readOnly,
}) => {
  const countriesReferenceData = useAPIWithSessionStorage(getCountriesAxios());

  const documentRef = useRef(null);

  useEffect(() => {
    if (resolveProperty(withNameSpace(nameSpace, fields.PRIMARY_DOCUMENT_TYPE), form.values)) {
      const currentType = resolveProperty(withNameSpace(nameSpace, `${fields.IDENTITY_DOCUMENTS}.0.type`), form.values);
      switch (resolveProperty(withNameSpace(nameSpace, fields.PRIMARY_DOCUMENT_TYPE), form.values)) {
        case 'PASSPORT':
          if (currentType !== 'PASSPORT') {
            form.setFieldValue(withNameSpace(nameSpace, fields.IDENTITY_DOCUMENTS),
              [PassportDocumentFormInitialValues]);
          }
          return;
        case 'AUSTRALIAN_DRIVERS_LICENSE':
          if (currentType !== 'AUSTRALIAN_DRIVERS_LICENSE') {
            form.setFieldValue(withNameSpace(nameSpace, fields.IDENTITY_DOCUMENTS),
              [AustralianLicenseFormInitialValues]);
          }
        default:
      }
    }
  }, [form.values]);

  return (
  <>
    <CardBody nested>
      <Grid nested>
        <Col sm={12}>
          <h5>General</h5>
        </Col>
        <Col sm={3}>
          <FastField
            component={TextField}
            fullWidth
            label={'Title'}
            name={withNameSpace(nameSpace, fields.TITLE)}
            readOnly={readOnly}
            required
            select
          >
            {getTitles()
              .map((title) => (
                <MenuItem key={title.value} value={title.value}>{title.label}</MenuItem>
              ))}
          </FastField>
        </Col>
        <Col sm={9}>
          <FastField component={TextField} fullWidth label={'First name'}
                     name={withNameSpace(nameSpace, fields.FIRST_NAME)}
                     readOnly={readOnly} required/>
        </Col>
        <Col sm={12}>
          <FastField component={TextField} fullWidth label={'Middle name'}
                     name={withNameSpace(nameSpace, fields.MIDDLE_NAME)} readOnly={readOnly}/>
        </Col>
        <Col sm={12}>
          <FastField component={TextField} fullWidth label={'Last name'}
                     name={withNameSpace(nameSpace, fields.LAST_NAME)}
                     readOnly={readOnly} required/>
        </Col>
      </Grid>
    </CardBody>
    <CardBody nested>
      <Grid nested>
        <Col sm={12}>
          <h5>Personal</h5>
        </Col>
        <Col sm={4}>
          <FastField component={TextField} fullWidth label={'Date of birth'}
                     mask={'99/99/9999'}
                     name={withNameSpace(nameSpace, fields.DATE_OF_BIRTH)} readOnly={readOnly}
                     required/>
        </Col>
        <Col sm={4}>
          <FastField
            component={TextField}
            fullWidth
            label={'Gender'}
            name={withNameSpace(nameSpace, fields.GENDER)}
            readOnly={readOnly}
            required
            select
          >
            {PartyFormatter.getGenderTypes()
              .map((gender) => (
                <MenuItem key={gender.value} value={gender.value}>{gender.label}</MenuItem>
              ))}
          </FastField>
        </Col>
        <Col sm={4}>
          <FastField
            component={TextField}
            fullWidth
            label={'Country of origin'}
            name={withNameSpace(nameSpace, fields.COUNTRY_OF_ORIGIN)}
            readOnly={readOnly}
            required
            select
          >
            {countriesReferenceData.loading
              ? <MenuItem key={'loadingMenuItem'} value={''}><Loading/></MenuItem>
              : countriesReferenceData.response?.data
              .map((country) => (
                <MenuItem key={country.value} value={country.value}>{country.name}</MenuItem>
              ))}
          </FastField>
        </Col>
        <Col sm={4}>
          <FastField
            component={TextField}
            fullWidth
            label={'Employment status'}
            name={withNameSpace(nameSpace, fields.EMPLOYMENT_STATUS)}
            readOnly={readOnly}
            required
            select
          >
            {PartyFormatter.getEmploymentStatusTypes()
              .map((employment) => (
                <MenuItem key={employment.value} value={employment.value}>
                  {employment.label}</MenuItem>
              ))}
          </FastField>
        </Col>
        <Col sm={4}>
          <FastField component={Checkbox} label={'Politically exposed?'}
                     name={withNameSpace(nameSpace, fields.POLITICALLY_EXPOSED)}
                     readOnly={readOnly}/>
        </Col>
      </Grid>
    </CardBody>
     <CardBody nested>
      <Grid nested>
        <Col sm={12}>
          <h5>Tax Information</h5>
        </Col>
        <Col sm={12}>
          <TaxInformationForm form={form}
                              nameSpace={withNameSpace(nameSpace, fields.TAX_INFORMATION_DETAILS)}
                              readOnly={readOnly}/>
        </Col>
      </Grid>
     </CardBody>
    <ContactDetailsForm
      form={form}
      key={`${nameSpace}contactDetails`} nameSpace={withNameSpace(nameSpace, fields.CONTACT_DETAILS)}
      readOnly={readOnly}/>

    <CardBody nested>
      <Grid nested>
        <Col sm={12}>
          <h5>Verification Documents</h5>
        </Col>
        <Col sm={12}>
          <p>Primary Document</p>
            <FastField
              component={TextField}
              fullWidth
              label={'Document Type'}
              name={withNameSpace(nameSpace, fields.PRIMARY_DOCUMENT_TYPE)}
              readOnly={readOnly}
              required
              select
            >
              <MenuItem key={'passport'} value={'PASSPORT'}>Passport</MenuItem>
              <MenuItem key={'drivers_license'} value={'AUSTRALIAN_DRIVERS_LICENSE'}>Australian drivers license</MenuItem>
            </FastField>
        </Col>
      </Grid>
    </CardBody>
    <FieldArray
      name={fields.IDENTITY_DOCUMENTS}
      render={(arrayHelpers) => (
        <>

          {(resolveProperty(withNameSpace(nameSpace, fields.IDENTITY_DOCUMENTS), form.values)
            && resolveProperty(withNameSpace(nameSpace, fields.IDENTITY_DOCUMENTS), form.values).length > 0)
          && (resolveProperty(withNameSpace(nameSpace, fields.IDENTITY_DOCUMENTS), form.values).map((document, index) => (
            <IdentityDocumentForm
              document={document} documentType={document.type}
              form={form}
              nameSpace={withNameSpace(nameSpace, `${fields.IDENTITY_DOCUMENTS}.${index}`)}
              readOnly={readOnly}/>
          )))
          }
        </>
      )}
    />
  </>
  // linked parties
  );
};

export default IndividualForm;
export { initialValues as IndividualFormInitialValues, schema as IndividualFormSchema };
