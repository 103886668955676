import {
  Button, Card, CardBody, CardHeader, CardTitle, Col, Grid, Page, PageHeader,
} from 'capitalroadkit';
import React from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

import { Type } from './Instructions';
import NoteDocumentsTable from './NoteDocumentsTable';

import { getNoteByUuidAxios, getNoteDocumentsAxios, getNoteTransactionsAxios } from '../../../api/NoteResource';
import { findProductByNoteReferenceIdAxios } from '../../../api/ProductResource';

import Loading from '../../../components/Loading/Loading';
import useAPI from '../../../hooks/useAPI';
import NoteOverview from './NoteOverview';
import NoteTransactionsTable from "./NoteTransactionsTable";

const Note = () => {
  const params = useParams();

  const getNoteAPI = useAPI(getNoteByUuidAxios(params.uuid));
  const getNoteTransactionsAPI = useAPI(getNoteTransactionsAxios(params.uuid));
  const getNoteDocumentsAPI = useAPI(getNoteDocumentsAxios(params.uuid));
  const findProductByNoteReferenceIdAPI = useAPI(findProductByNoteReferenceIdAxios(params.uuid));


  const loading = getNoteAPI.loading
    || getNoteTransactionsAPI.loading
    || getNoteDocumentsAPI.loading
    || findProductByNoteReferenceIdAPI.loading;
  const response = getNoteAPI.response
    && getNoteTransactionsAPI.response
    && getNoteDocumentsAPI.response
    && findProductByNoteReferenceIdAPI.response;

  if (response) {
    return (
      <Page id={'note'}>
        <Grid>
          <PageHeader
            heading={getNoteAPI.response.data.noteReferenceId}
            subHeading={findProductByNoteReferenceIdAPI.response.data.name}
          >
            {getNoteAPI.response?.data.status === 'ACTIVE'
            && <Col sm={12} style={{ textAlign: 'right' }}>
              <Link to={{ pathname: `/secure/notes/${getNoteAPI.response.data.uuid}/maturity-instruction` }}>
                <Button color={'primary'}>Update Maturity Instruction</Button>
              </Link>
              <Link style={{ marginLeft: '.5rem' }} to={{ pathname: `/secure/notes/${getNoteAPI.response.data.uuid}/payments` }}>
                <Button color={'primary'}>Payments</Button>
              </Link>
              <Link style={{ marginLeft: '.5rem' }} to={{ pathname: `/secure/notes/${getNoteAPI.response.data.uuid}/instructions`, query: { type: Type.Rollover } }}>
                <Button color={'primary'}>Rollover</Button>
              </Link>
              <Link style={{ marginLeft: '.5rem' }} to={{ pathname: `/secure/notes/${getNoteAPI.response.data.uuid}/instructions`, query: { type: Type.Redeem } }}>
                <Button color={'primary'}>Redeem</Button>
              </Link>
            </Col>
            }
            {getNoteAPI.response?.data.status === 'PENDING'
            && <Col sm={12} style={{ textAlign: 'right' }}>
              <Link to={{ pathname: `/secure/notes/${getNoteAPI.response.data.uuid}/maturity-instruction` }}>
                <Button color={'primary'}>Update Maturity Instruction</Button>
              </Link>
              <Link style={{ marginLeft: '.5rem' }} to={{ pathname: `/secure/notes/${getNoteAPI.response.data.uuid}/deposits` }}>
                <Button color={'primary'}>Process Deposit</Button>
              </Link>
            </Col>
            }
          </PageHeader>
          <Col lg={12} md={12} sm={12}>
            <Card>
              <CardHeader><CardTitle>Details</CardTitle></CardHeader>
              <CardBody>
                <NoteOverview getNote={getNoteAPI.fetch} note={getNoteAPI.response.data}/>
              </CardBody>
            </Card>
          </Col>
          <Col lg={6} md={6} sm={12}>
            <Card>
              <CardHeader><CardTitle>Documents</CardTitle></CardHeader>
              <NoteDocumentsTable data={getNoteDocumentsAPI.response.data}/>
            </Card>
          </Col>
          <Col lg={6} md={6} sm={12}>
            <Card>
              <CardHeader>
                <CardTitle>Transactions</CardTitle>
              </CardHeader>
              <NoteTransactionsTable data={getNoteTransactionsAPI.response.data}/>
            </Card>
          </Col>
        </Grid>
      </Page>
    );
  }
  if (loading) return <Loading size={'3x'}/>;
  return null;
};

export default Note;
