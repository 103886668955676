import axios from 'axios';

import { host } from './utilities';

export const authenticateAxios = () => (config, data = {}) => axios.post(`${host()}/auth`, data, config);

export const logoutAxios = () => (config) => axios.delete(`${host()}/auth`, config);

export const initiateResetPasswordAxios = () => (config, data = {}) => axios.post(`${host()}/auth/reset-init`, data, config);

export const resetPasswordAxios = () => (config, data = {}) => axios.post(`${host()}/auth/reset`, data, config);
