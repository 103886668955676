import {Col, TextField} from 'capitalroadkit';
import {FastField} from 'formik';
import moment from 'moment';
import React from 'react';
import * as Yup from 'yup';

import {withNameSpace} from '../../../functions';

const fields = {
  LICENSE_NUMBER: 'licenseNumber',
  DATE_OF_ISSUE: 'dateOfIssue',
  DATE_OF_EXPIRY: 'dateOfExpiry',
  STATE_OF_ISSUE: 'stateOfIssue',
};

const initialValues = {
  [fields.LICENSE_NUMBER]: '',
  [fields.DATE_OF_ISSUE]: '',
  [fields.DATE_OF_EXPIRY]: '',
  [fields.STATE_OF_ISSUE]: '',
  type: 'AUSTRALIAN_DRIVERS_LICENSE',
};

const schema = Yup.object({
  [fields.LICENSE_NUMBER]: Yup.string()
    .required('License number is required'),
  [fields.DATE_OF_ISSUE]: Yup.date()
    .typeError('Date of issue must be a valid date')
    .transform((value, originalValue) => {
      value = moment(originalValue, 'DD/MM/YYYY');
      return value.isValid() ? value.toDate() : null;
    })
    .max(new Date())
    .required('Date of issue is required'),
  [fields.DATE_OF_EXPIRY]: Yup.date()
    .typeError('Date of expiry must be a valid date')
    .transform((value, originalValue) => {
      value = moment(originalValue, 'DD/MM/YYYY');
      return value.isValid() ? value.toDate() : null;
    })
    .min(new Date())
    .required('Date of expiry is required'),
  [fields.STATE_OF_ISSUE]: Yup.string()
    .required('Country of issue is required'),
});

const AustralianLicenseForm = ({ nameSpace, readOnly }) => (
  <>
    <Col sm={12}>
      <h5>Australian Drivers license</h5>
    </Col>
    <Col sm={12}>
      <FastField component={TextField} fullWidth key={readOnly} label={'License number'}
                 name={withNameSpace(nameSpace, fields.LICENSE_NUMBER)}
                 readOnly={readOnly} required/>
    </Col>
    <Col lg={6} sm={12}>
      <FastField component={TextField} fullWidth label={'Date of issue'}
                 mask={'99/99/9999'}
                 name={withNameSpace(nameSpace, fields.DATE_OF_ISSUE)} readOnly={readOnly}
                 required/>
    </Col>
    <Col lg={6} sm={12}>
      <FastField component={TextField} fullWidth label={'Date of expiry'}
                 mask={'99/99/9999'}
                 name={withNameSpace(nameSpace, fields.DATE_OF_EXPIRY)} readOnly={readOnly}
                 required/>
    </Col>
    <Col lg={6} sm={12}>
      <FastField component={TextField} fullWidth key={readOnly} label={'Country of issue'}
                 name={withNameSpace(nameSpace, fields.STATE_OF_ISSUE)}
                 readOnly={readOnly} required/>
    </Col>
  </>
);

export default AustralianLicenseForm;
export {
  initialValues as AustralianLicenseFormInitialValues,
  schema as AustralianLicenseFormSchema,
  fields as AustralianLicenseFormFields,
};
