import {
  Button,
  Card, CardBody, CardHeader, CardTitle, Col, Grid, Page, PageHeader,
} from 'capitalroadkit';
import { Form, FormikProvider, useFormik } from 'formik';
import * as _ from 'lodash';
import React from 'react';

import NotesSearchForm, { NotesSearchFormInitialValues, NotesSearchFormSchema } from './NotesSearchForm';
import NotesTable from './NotesTable';

import { searchNotesAxios } from '../../api/NoteResource';
import Loading from '../../components/Loading/Loading';
import useAPI from '../../hooks/useAPI';

const Notes = ({ user }) => {
  const getNotesByStatusAPI = useAPI(searchNotesAxios());

  const notesSearchForm = useFormik({
    initialValues: NotesSearchFormInitialValues,
    onSubmit: (values) => {
      const apiValues = _.clone(values);
      if (!apiValues.filter) delete apiValues.filter;
      getNotesByStatusAPI.fetch(apiValues);
    },
    validationSchema: NotesSearchFormSchema,
  });

  return (
    <Page id={'notes'}>
      <Grid>
        <PageHeader
          heading={'Notes'}
          subHeading={user.name}
        />
        <Col sm={12}>
          <Card>
            <CardBody>
              <FormikProvider value={notesSearchForm}>
                <Form>
                  <Grid nested>
                    <NotesSearchForm form={notesSearchForm}/>
                    <Col sm={4} style={{ alignItems: 'end', display: 'flex' }}>
                      <Button color={'primary'} type={'submit'}>Search</Button>
                    </Col>
                  </Grid>
                </Form>
              </FormikProvider>
            </CardBody>
          </Card>
        </Col>
        <Col sm={12}>
          <Card height={'auto'}>
            <CardHeader><CardTitle>Notes</CardTitle></CardHeader>
            {getNotesByStatusAPI.loading
              ? <Loading size={'2x'}/>
              : getNotesByStatusAPI.response
              && <NotesTable
                api={{
                  limit: getNotesByStatusAPI.response.data.limit,
                  offset: getNotesByStatusAPI.response.data.offset,
                  totalNumberOfRecords: getNotesByStatusAPI.response.data.totalNumberOfRecords,
                  fetch: getNotesByStatusAPI.fetch,
                  params: getNotesByStatusAPI.lastParams,
                }}
                data={getNotesByStatusAPI.response.data.list}
              />
            }
          </Card>
        </Col>
      </Grid>
    </Page>
  );
};

export default Notes;
