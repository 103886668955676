import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  tableInput: ({ disabled, theme }) => ({
    fontFamily: 'inherit',
    fontSize: '1rem',
    lineHeight: '24px',
    padding: '.875rem',
    border: '2px solid transparent',
    background: 'transparent',
    color: disabled ? 'rgba(255, 255, 255, 0.5)' : theme.palette.text.base,
    width: '100%',
    transition: 'background-color .1s ease-out 0s, border-color .1s ease-out 0s, box-shadow .15s cubic-bezier(.47, .03, .49, 1.38)',
    position: 'relative',
    zIndex: '1000',

    '&:hover': {
      backgroundColor: disabled ? 'transparent' : theme.mode === 'dark' ? 'rgba(255, 255, 255, 0.08)' : 'rgba(0, 0, 0, 0.04)',
    },

    '&:focus': {
      backgroundColor: 'transparent',
      borderColor: theme.palette.primary.main,
    },
  }),
});

export default useStyles;
