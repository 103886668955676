import { Table } from 'capitalroadkit';
import React, { useMemo } from 'react';

import { parseEnum, toCurrency, toDateString } from '../../../formatters';

const NoteTransactionsTable = ({ data }) => {
  const columns = useMemo(() => [
    {
      id: 'transactions',
      Header: '',
      Footer: '',
      columns: [
        {
          Header: 'uuid',
          accessor: 'uuid',
        },
        {
          Header: 'Date',
          accessor: 'transactionDate',
          Cell: ({ cell: { value } }) => toDateString(value),
        },
        {
          Header: 'Type',
          accessor: 'transactionType',
          Cell: ({ cell: { value } }) => parseEnum(value),
        },
        {
          Header: 'Amount',
          accessor: 'transactionAmount.value',
          Cell: ({ cell: { value } }) => toCurrency(value),
        },
      ],
    },
  ], []);

  return (
    <Table
      columns={columns}
      data={data}
      dataKey={'uuid'}
      hiddenColumns={['uuid']}
      sortBy={[
        {
          id: 'transactionDate',
          desc: true,
        },
      ]}
    />
  );
};

export default NoteTransactionsTable;
