import {
  Card, CardBody, Col, Grid, Page,
} from 'capitalroadkit';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';

import { findProductByUuidAxios } from '../../../api/ProductResource';
import Loading from '../../../components/Loading/Loading';
import useAPI from '../../../hooks/useAPI';
import CreateProduct from '../NewProduct/CreateProduct';

const UpdateProduct = ({ user }) => {
  const history = useHistory();
  const params = useParams();

  // PRODUCT
  const [product, setProduct] = useState(null);

  const findProductByUuidAPI = useAPI(findProductByUuidAxios(params.uuid));

  useEffect(() => {
    if (!findProductByUuidAPI.loading && findProductByUuidAPI.response) {
      setProduct(findProductByUuidAPI.response.data);
    }
  }, [findProductByUuidAPI.response, findProductByUuidAPI.loading]);

  const productCreated = (res) => {
    console.log('product created called', res);
    history.push(`/secure/products/${res.uuid}/update`);
  };

  const productUpdated = (res) => {
    console.log('product update called', res);
    history.push(`/secure/products/${res.uuid}/update`);
  };

  const userIsProductManager = () => {
    for (const role of user.businessRoles) {
      if (role === 'PRODUCT_MANAGER') {
        return true;
      }
    }
    return false;
  };

  if (findProductByUuidAPI.loading) return <Loading size={'3x'}/>;
  if (product) {
    return (
      <Page id={'new-product'}>
        <Grid>
          <Col sm={12}>
            <Card>
              <CardBody>
                <h4>
                  {userIsProductManager() ? 'Update Product' : 'Product'}<br/>
                  <small>{user.name}</small>
                </h4>
              </CardBody>
            </Card>
          </Col>
          <CreateProduct
            product={product}
            productCreated={productCreated}
            productUpdated={productUpdated}
            readOnly={!userIsProductManager()}
          />
        </Grid>
      </Page>
    );
  }
  return null;
};

export default UpdateProduct;
