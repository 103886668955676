import {
  Button,
  Card, CardBody, CardHeader, CardTitle,
  Col, Grid, Page, PageHeader,
} from 'capitalroadkit';
import { Form, FormikProvider, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';

import FixedInterestNoteForm, { FixedInterestNoteFormInitialValues } from './FixedInterestNoteForm';

import { createNewFixedInterestNoteAxios } from '../../../api/PartyResource';
import Error from '../../../components/Error/Error';
import useAPI from '../../../hooks/useAPI';

const NewNote = () => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const fixedInterestNoteForm = useFormik({
    initialValues: FixedInterestNoteFormInitialValues,
    onSubmit: (values) => {
      const apiValues = {};
      apiValues.product = { uuid: values.product };
      apiValues.principalAtInception = { currency: 'AUD', value: values.investmentAmount };
      apiValues.duration = values.duration;
      apiValues.interestRate = values.interestRate;
      apiValues.interestToBeReinvested = values.interestDistribution === 'REINVEST';
      createNewFixedInterestNoteAPI.fetch({}, apiValues);
    },
  });

  const createNewFixedInterestNoteAPI = useAPI(
    createNewFixedInterestNoteAxios(fixedInterestNoteForm.values.party.uuid),
    false,
  );

  useEffect(() => {
    if (!createNewFixedInterestNoteAPI.loading && createNewFixedInterestNoteAPI.response) {
      enqueueSnackbar(`${createNewFixedInterestNoteAPI.response.data.noteReferenceId} has been created`);
      history.push(`/secure/notes/${createNewFixedInterestNoteAPI.response.data.uuid}`);
    }
  }, [createNewFixedInterestNoteAPI.response, createNewFixedInterestNoteAPI.loading]);

  return (
    <Page id={'new-note'}>
      <Grid>
        <PageHeader
          heading={'New Note'}
        />
        <Col sm={12}>
          <Card>
            <CardHeader><CardTitle>Form</CardTitle></CardHeader>
            <CardBody>
              <FormikProvider value={fixedInterestNoteForm}>
                <Form>
                  <Grid nested>
                    <FixedInterestNoteForm form={fixedInterestNoteForm}/>
                    <Col sm={12} style={{ textAlign: 'right' }}>
                      <Button color={'success'} type={'submit'}>Submit</Button>
                      {createNewFixedInterestNoteAPI.error
                      && <Error error={createNewFixedInterestNoteAPI.error.response.data}/>
                      }
                    </Col>
                  </Grid>
                </Form>
              </FormikProvider>
            </CardBody>
          </Card>
        </Col>
      </Grid>
    </Page>
  );
};

export default NewNote;
