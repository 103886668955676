import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MenuItem from '@material-ui/core/MenuItem';
import { Table, Dropdown } from 'capitalroadkit';
import React, { useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';

import {
  parseEnum, toCurrency, toDateString, toPercent,
} from '../../formatters';

const NotesTable = ({
  api, data, party, rowClick, selected,
}) => {
  const history = useHistory();
  const columns = useMemo(() => [
    {
      id: 'notes',
      Header: '',
      Footer: '',
      columns: [
        {
          Header: 'uuid',
          accessor: 'uuid',
        },
        {
          Header: 'partyUuid',
          accessor: 'partyUuid',
        },
        {
          Header: 'Reference ID',
          accessor: 'noteReferenceId',
          Cell: ({ row: { values }, cell: { value } }) => <Link to={`/secure/notes/${values.uuid}`}>{value}</Link>,
        },
        {
          Header: 'Client',
          accessor: 'partyName',
          Cell: ({ row: { values }, cell: { value } }) => (
            <Link to={`/secure/clients/${values.partyUuid}`}>{value}</Link>
          ),
        },
        {
          Header: 'Term',
          accessor: 'duration',
          Cell: ({ cell: { value } }) => `${value} months`,
        },
        {
          Header: 'Interest Rate',
          accessor: 'interestRate',
          Cell: ({ cell: { value } }) => toPercent(value),
        },
        {
          Header: 'Principal Value',
          accessor: 'principal.value',
          Cell: ({ cell: { value } }) => toCurrency(value),
        },
        {
          Header: 'Capitalised Interest',
          accessor: 'capitalisedInterest.value',
          Cell: ({ cell: { value } }) => toCurrency(value),
        },
        {
          Header: 'Balance',
          accessor: 'balance.value',
          Cell: ({ cell: { value } }) => toCurrency(value),
        },
        {
          Header: 'Maturity Date',
          accessor: 'dateOfMaturity',
          Cell: ({ cell: { value } }) => toDateString(value),
        },
        {
          Header: 'Status',
          accessor: 'status',
          Cell: ({ cell: { value } }) => parseEnum(value),
        },
        {
          id: 'actions',
          Cell: ({ row }) => <Dropdown
            button={
              <FontAwesomeIcon icon={faEllipsisV} style={{ cursor: 'pointer', fontSize: '1rem' }}/>
            }
          >
            <MenuItem onClick={() => history.push(`/secure/notes/${row.values.uuid}`)}>
              View
            </MenuItem>
            <MenuItem onClick={() => history.push(`/secure/notes/${row.values.uuid}/maturity-instruction`)}>
              Update maturity instruction
            </MenuItem>
            <MenuItem onClick={() => history.push(`/secure/notes/${row.values.uuid}/payments`)}>
              Payments
            </MenuItem>
            <MenuItem
              onClick={() => history.push({
                pathname: `/secure/notes/${row.values.uuid}/instructions`,
                query: { type: 'ROLLOVER' },
              })}
            >
              Rollover
            </MenuItem>
            <MenuItem
              onClick={() => history.push({
                pathname: `/secure/notes/${row.values.uuid}/instructions`,
                query: { type: 'REDEEM' },
              })}
            >
              Redeem
            </MenuItem>
          </Dropdown>,
          headerStyle: {
            width: '2.375rem',
          },
        },
      ],
    },
  ], []);

  return (
    <Table
      api={api}
      columns={columns}
      data={data}
      dataKey={'uuid'}
      hiddenColumns={party ? ['uuid', 'partyUuid', 'partyName'] : ['uuid', 'partyUuid']}
      rowClick={rowClick}
      selected={selected}
      sortBy={[
        {
          id: 'noteReferenceId',
          desc: true,
        },
      ]}
      width={'106.4375rem'}
    />
  );
};

export default NotesTable;
