import {
  Card, CardBody, Col, Grid, Page,
} from 'capitalroadkit';
import React from 'react';
import { useHistory } from 'react-router';

import CreateProduct from './CreateProduct';

const NewProduct = ({ user }) => {
  const history = useHistory();

  const productCreated = (res) => {
    console.log('product created called', res);
    history.push(`/secure/products/${res.uuid}/update`);
  };

  const userIsProductManager = () => {
    for (const role of user.businessRoles) {
      if (role === 'PRODUCT_MANAGER') {
        return true;
      }
    }
    return false;
  };

  return (
    <Page id={'new-product'}>
      <Grid>
        <Col sm={12}>
          <Card>
            <CardBody>
              <h4>
                New product<br/>
                <small>{user.name}</small>
              </h4>
            </CardBody>
          </Card>
        </Col>
        <CreateProduct
          productCreated={productCreated}
          readOnly={!userIsProductManager()}
        />
      </Grid>
    </Page>
  );
};

export default NewProduct;
